import axios, { AxiosRequestConfig } from 'axios'
import { createStore, Commit } from 'vuex'

// 用户信息
export interface UserProps {
  // 是否登录
  isLogin: boolean;
  areaId?: string;// 地区Id,
  areaName?: string;// 地区名称,
  avatar?: string;// 头像,
  id?: string;// 用户id,
  isSuper?: number;// 是否是超级用户 1是,
  isVip?: number;// 是否是大v用户 1是,
  jobId?: string;// 职务id,
  jobName?: string;// 职务名称,
  mobile?: string;// 手机号,
  nickname?: string;// 昵称,
  nimId?: string;// 云信id,
  nimToken?: string;// 云信Token,
  password?: string;
  reg?: string;// 是否是第一次登录，有值是第一次登录,
  sex?: number;// 性别0保密, 1男，2女,
  sign?: string;// 签名,
  token?: string;// token,
  tradeId?: string;// 行业id,
  tradeName?: string;// 行业名称,
  wxName?: string;// 微信名称
}

// 作品的图集
export interface AtlasNewsDto {
  // 图集图片描述
  describe: string;
  // 图集记录id（修改图集内容时需要传这个值，才能修改成功）
  id?: string;
  imageUrl: string;
}

// 上传组件用到的 作品的图集格式
export interface UploadAtlasNewsDto {
  data: AtlasNewsDto;
  key: number;
}

// 显示列表样式（1视频，2三个小图，3一张小图，4无图）
export type ListType = 1 | 2 | 3 | 4
// 资讯类型（1图文，2图集，3视频，4直播）
export type newsType = 1 | 2 | 3 | 4

// 发布作品
export interface NewsOneDto {
  // 传2代表是大v发布的
  isAuthorNews: number;

  // 1:发布, 2:草稿
  isPublish: number;
  // 资讯栏目id
  columnId: number;
  // 资讯内容
  content: string;
  // 资讯标题
  title: string;
  // 资讯类型（1图文，2图集，3视频，4直播）
  type: newsType;
  // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
  listType: ListType;
  // 资讯发布者id
  publisherId: string;
  // 资讯来源id
  sourceId: number;
  // 展现量
  showNum?: number;
  // 浏览量
  viewsNum?: number;
  // 评论量
  commentNum?: number;
  // 图集资讯专用列表对象
  atlasNewsDtos?: AtlasNewsDto[];
  // 直播简介或图集
  describe?: string;
  // 直播结束时间
  endTime?: string;
  gmtCreate?: string;
  // 文章当前审核状态
  examineStatusOne?: number;
  // 资讯id (添加资讯时不用传id)
  id?: string;
  // 资讯封面url
  imageUrl?: string|Array<string>;
  // 直播开始时间
  startTime?: string;
}

// 返回的数据格式
export interface ResponseType<P = {}> {
  code: string;
  message: string;
  data: P;
}

// OSS返回的数据格式
interface OssRes {
  status: number;
  statusCode: number;
}

// 上传文件后,OSS返回的数据格式
export interface OssResponse {
  imageKey?: number;
  name: string;
  res: OssRes;
  url: string;
}

// 图片的属性
export interface ImageProps {
  name?: string;
  url?: string;
  // 缩略图
  fitUrl?: string;
}

// 栏目信息
export interface ColumnProps {
  id: string;
  name: string;
}

// 文章来源
export interface NewsSourceProps {
  id: number;
  source: string;
  gmtCreate: string;
  alias: string;
  isDel: number;
}

// 列表数据格式
interface ListProps<P> {
  [id: string]: P;
}

// 错误信息
export interface GlobalErrorProps {
  status: boolean;
  message?: string;
}

export interface NewsInteraction {
  favourNum: number;
  commentNum: number;
  collectNum: number;
  series: Array<{ name: string; type: string; srack: string; data: Array<number> }>;
}

// 评论列表请求参数
export interface CommentSearchAdminDto {
  content: string;// (string, optional): 评论内容搜索的关键字 ,
  infoId: string;// (string, optional): 新闻id/动态id/讨论id ,
  length: string;// (integer, optional): 每页的个数 ,
  page: number;// (integer, optional): 第几页 ,
  title: string;// (string, optional): 资讯标题搜索的关键字 ,
  userId: string;// (string, optional): 用户id
}

// 评论中的新闻信息
export interface NewsAssociationDto {
  columnId: string;// (string, optional): 资讯栏目id ,
  gmtCreate: string;// (string, optional): 资讯创建时间 ,
  id: string;// (string, optional): 资讯id ,
  imageUrl: object;// (object, optional): 资讯封面url ,
  isDel: string;// (string, optional): 是否删除 ,
  listType: string;// (string, optional): 资讯显示列表样式（1，2，3，4） ,
  publisherId: string;// (string, optional): 资讯发布者id ,
  sourceId: string;// (string, optional): 资讯来源id ,
  title: string;// (string, optional): 资讯标题 ,
  type: string;// (string, optional): 资讯类型（1图文，2图集，3视频，4直播）
}

// 评论列表中的用户信息
export interface UserInfoDto {
  avatarUrl: string;// (string, optional): 头像 ,
  isVip: string;// (string, optional),
  nickname: string;// (string, optional): 昵称 ,
  userId: string;// (string, optional): id
}

// 评论列表返回的数据
export interface CommentMyDto {
  content: string;// (string, optional): 评论内容 ,
  coverImage: string;// (string, optional): 动态的才有 封面 ,
  favourCount: string;// (string, optional): 点赞数量 ,
  id: string;// (string, optional): 评论的id ,
  images: string;// (string, optional): 动态的才有 所有图片 ,
  isDel: string;// (string, optional): 是否被删除 ,
  newsAssociationDto: NewsAssociationDto;// (NewsAssociationDto, optional): 评论对应的文章信息 ,
  publishTime: string;// (string, optional): 时间 ,
  upUserId: string;// (string, optional): 被回复用户的id ,
  upUserInfoDto: UserInfoDto;// (UserInfoDto, optional): 被回复用户的信息 ,
  userInfoDto: UserInfoDto;// (UserInfoDto, optional): 用户信息 ,
  videoPath: string;// (string, optional): 动态的才有 视频地址
}

export interface UserLabel{
  id: number;
  name: string;
  type: number;
}

export interface ListLabel{
  trade: Array<UserLabel>;
  area: Array<UserLabel>;
  job: Array<ColumnProps>;
}

export interface GetFansSexData{
  legendData: Array<string>;
  data: [{ value: number; name: string }];
}

// store中存储的数据格式
export interface GlobalDataProps {
  showMenu: boolean;
  getOneShotNewsData: { list: ListProps<NewsDataTwoVo> };
  getNewsDataYesterday: NewsYesterdayDataVo;
  // 用户标签
  listLabel: ListLabel;
  getFansSexData: GetFansSexData;
  getAuthorNewsHomepageVo: GetAuthorNewsHomepageVo;
  // 当前正在编辑的文章
  currentEditNews: NewsOneDto;
  // 栏目列表
  columns: { data: ListProps<ColumnProps> };

  // 粉丝地域数据列表接口
  getFansAddressData: Array<[number, number, string]>;
  // 粉丝数据
  getFansData: FansData;
  // 文章来源列表
  listNewsSource: { data: ListProps<NewsSourceProps> };
  // 粉丝列表
  listVipFans: { data: ListProps<UserInfoDto> };

  // 作品管理-作品列表
  authorNewsList: { list: ListProps<NewsOneDto>; total: number };
  token: string;
  user: UserProps;
  loading: boolean;
  error: GlobalErrorProps;
  getNewsInteraction: NewsInteraction;
  commentAdminListNews: { data: ListProps<CommentMyDto> };
  authorListAuthorComment: { list: ListProps<AuthorCommentDto> };
  // 项目流量分析数据接口
  getProjectFlowData: ProjectDataVo;
  // 项目互动分析数据接口
  getProjectInteractionData: ProjectDataVo;
  // 项目昨日数据面板
  getProjectYesterdayData: ProjectYesterdayDataVo;
  // 文章流量分析数据接口
  listNewsDataFlow: NewsDataVo;
  // 项目列表
  authorProjectList: { list: ListProps<ProjectDto> };
  commentListTwoNews: { list: ListProps<CommentListDto> };
}

// 项目
export interface ProjectDto {
  // id
  id?: number;
  //  代理商
  agent: string;

  //  代理商联系人
  agentContact: string;

  //  代理商电话
  agentPhone: string;

  //  地点
  area: string;

  //  地区id
  areaId: number;

  //  投标截止时间
  bidTime: string;

  //  投标保证金账号
  bondAccount: string;

  //  投标保证金银行
  bondBank: string;

  //  投标保证金金额
  bondMoney: string;

  //  投标保证金备注
  bondRemark: string;

  //  投标保证金单位
  bondUnit: string;

  //  正副本数量
  bookNum: string;

  //  采购商
  buyer: string;

  //  采购商联系人
  buyerContact: string;

  //  采购商电话
  buyerPhone: string;

  //  现金购买标书
  cashPurchase: string;

  //  公社id
  communeId: string;

  //  内容
  content: string;

  //  创建时间
  createTime: string;

  //  附件地址
  fileJson: string;

  //  行业id
  industryId: number;

  //  意向人用户id
  intentId: string;

  //  是否收藏（1收藏 0未收藏）
  isCollect: number;

  //  是否是意向项目的字段（0: 不是意向 1: 是意向）
  isOk: number;

  //  项目示范级别/批次
  level: number;

  //  准确的金额
  moneyDecimal: number;

  //  省份
  nationalArea: string;

  //  项目编号
  number: string;

  //  开标日期
  openTime: string;

  //  项目发布者(程序内部获取，不用传)
  personId: string;

  //  项目id
  projectId: number;

  //  项目封面url
  projectImageUrl: string;

  //  项目金额
  projectMoney: string;

  //  项目名称
  projectName: string;

  //  网址
  projectWebsite: string;

  //  购买标书金额
  purchaseMoney: string;

  //  购买标书起止时间
  purchaseTime: string;

  //  发布日期
  releaseTime: string;

  //  电汇账号
  remitAccount: string;

  //  电汇银行
  remitBank: string;

  //  电汇备注
  remitRemark: string;

  //  电汇单位
  remitUnit: string;

  //  回报机制id
  rewardId: number;

  //  废标人员要求
  scrapPersonRequest: string;

  //  废标资质要求
  scrapQuaRequest: string;

  //  废标封标要求
  scrapSealedRequest: string;

  //  标书当前阶段的字段（1: 买标阶段 2: 写标阶段 3: 封标阶段 4: 投标阶段 5已完成）
  step: number;

  //  投标地点
  tenderArea: string;

  //  投标文件电子标份数
  tenderNum: string;

  //  第三方网站
  thirdWebsite: string;

}

// 项目昨日数据面板
export interface ProjectYesterdayDataVo {
  // series (Array[DataPanelVo], optional): 图形数据 ,
  // showNum (integer, optional): 昨日展现量 ,
  // viewNum (integer, optional): 昨日阅读量
  series: Array<DataPanelVo>;
  showNum: number;
  viewNum: number;
}

// 文章互动分析数据接口
export interface NewsInteractionReq {
  endTime: string;
  newsId: string;
  startTime: string;
}

// 项目流量分析
export interface ProjectDataVo{
  // 总点击率
  allClickNum: number;
  // 总展现量
  allShowNum: number;
  // 总阅读量
  allViewNum: number;
  // 点击率
  clickNum: number;
  // 粉丝展现量
  fanceShowNum: number;
  // 粉丝阅读量
  fanceViewNum: number;
  // 图形数据
  series: Array<DataPanelVo>;
  // 展现量
  showNum: number;
  // 阅读量
  viewNum: number;
}

// 文章流量分析
export interface NewsDataVo{
  // 总点击率
  allClickNum: number;
  // 总展现量
  allShowNum: number;
  // 总阅读量
  allViewNum: number;
  // 点击率
  clickNum: number;
  // 粉丝展现量
  fanceShowNum: number;
  // 粉丝阅读量
  fanceViewNum: number;
  // 图形数据
  series: Array<DataPanelVo>;
  // 展现量
  showNum: number;
  // 阅读量
  viewNum: number;
}

export interface DataPanelVo {
  data: Array<number>;
  name: string;
  stack: string;
  type: string;
}

export interface GetAuthorNewsHomepageVo {
  commentNum: number;
  fansNum: number;
  fansYesterdayChangeNum: number;
  favourNum: number;
  viewNum: number;
  yesterdayCommentNum: number;
  yesterdayFavourNum: number;
  yesterdayViewNum: number;
  series: [DataPanelVo];
}

export interface ProjectDataOneVo {
  // endTime (string, optional): 结束时间 ,
  // projectId (string, optional): 项目id（数据模块的项目接口不用传这个id！！！） ,
  // startTime (string, optional): 开始时间
  startTime: string; endTime: string; projectId: string;
}

// 请求方法,可以用config来修改请求方案 config: AxiosRequestConfig = { method: 'get' }
const asyncAndCommit = async (url: string, mutationName: string,
  commit: Commit, config: AxiosRequestConfig = { method: 'get' }, extraData?: any) => {
  const { data } = await axios(url, config)
  if (data.code !== '200') {
    commit('setError', { status: true, message: data.message })
    return data
  }
  if (extraData) {
    commit(mutationName, { data, extraData })
  } else {
    commit(mutationName, data)
  }
  return data
}
export interface AuthorCommentDto {
  // avatarUrl (string, optional): 评论者头像 ,
  // content (string, optional): 评论内容 ,
  // gmtCreate (string, optional): 评论时间 ,
  // id (string, optional): 评论id ,
  // isFans (integer, optional): 是否是粉丝 ,
  // isFavour (integer, optional): 是否点赞
  // newsId (string, optional): 新闻id ,
  // nickname (string, optional): 用户昵称 ,
  // title (string, optional): 新闻标题 ,
  // userId (string, optional): 用户id
  avatarUrl: string;
  content: string;
  gmtCreate: string;
  isFavour: number;
  id: string;
  isFans: number;
  newsId: string;
  nickname: string;
  title: string;
  userId: string;
}

// 回复评论
export interface CommentSendDto {
  // content (string): 评论的内容 ,
  // isAuthor (string): 是否是作者 ,
  // isOwner (string): 是否是楼主 ,
  // targetId (string): 回复的目标id ,
  // userId (string): 用户id
  content: string;
  targetId: string;
  userId?: string;
  isAuthor?: string;
  isOwner?: string;
}

export interface FansData {
  actFansRatio: number;
  allFansNum: number;
  fansActNum: number;
  fansAddNum: number;
  fansChangeNum: number;
  fansReduceNum: number;
  series: Array<DataPanelVo>;
}

export interface NewsDataTwoVo {
  // clickNum (number, optional): 文章点击率 ,
  clickNum: number;
  // commentNum (integer, optional): 文章评论量 ,
  commentNum: number;
  // favourNum (integer, optional): 文章点赞量 ,
  favourNum: number;
  // gmtCreate (string, optional),
  gmtCreate: string;
  // id (string, optional): 文章id ,
  id: string;
  // showNum (integer, optional): 文章展现量 ,
  showNum: number;
  // title (string, optional): 文章标题 ,
  title: string;
  // viewNum (integer, optional): 文章浏览量
  viewNum: number;
}

export interface CommentListDto {
    // commentId (string, optional): 评论id(用于点赞,删除,踩,跳转到详情) ,
    commentId: string;
    // commentListDtos (Array[CommentListDto], optional): 二级评论 ,
    commentListDtos: Array<CommentListDto>;
    // content (string, optional): 评论内容 ,
    content: string;
    // fans (string, optional): 粉丝情况:相互关注,关注了登录者,登录者已关注 ,
    fans: string;
    // favourCount (string, optional): 点赞数量 ,
    favourCount: string;
    // isAuthor (string, optional): 是否是作者 ,
    isAuthor: string;
    // isFavour (string, optional): 是否点赞 ,
    isFavour: string;
    // isOwner (string, optional): 是否是楼主 ,
    isOwner: string;
    // length (integer, optional): 每页的个数 ,
    length: number;
    // page (integer, optional): 第几页 ,
    page: number;
    // publishTime (string, optional): 发布时间 ,
    publishTime: string;
    // upUserId (string, optional): 被回复用户的id ,
    upUserId: string;
    // upUserInfoDto (UserInfoDto, optional): 被回复用户的信息 ,
    upUserInfoDto: UserInfoDto;
    // userId (string, optional): 评论的用户id ,
    userId: string;
    // userInfoDto (UserInfoDto, optional): 发布者信息
    userInfoDto: UserInfoDto;
  }

export interface NewsYesterdayDataVo {
  // commentNum (integer, optional): 昨日评论量 ,
  commentNum: number;
  // favourNum (integer, optional): 昨日点赞量 ,
  favourNum: number;
  // series (Array[DataPanelVo], optional): 图形数据 ,
  series: Array<DataPanelVo>;
  // showNum (integer, optional): 昨日展现量 ,
  showNum: number;
  // viewNum (integer, optional): 昨日阅读量
  viewNum: number;
}

const store = createStore<GlobalDataProps>({
  state: {
    showMenu: false,
    getFansData: {
      actFansRatio: 0,
      allFansNum: 26,
      fansActNum: 0,
      fansAddNum: 0,
      fansChangeNum: 0,
      fansReduceNum: 0,
      series: [{
        data: [],
        name: '粉丝变化量',
        stack: '总量',
        type: 'line'
      }]
    },
    getOneShotNewsData: { list: {} },
    listLabel: { trade: [], area: [], job: [] },
    getProjectYesterdayData: { showNum: 0, viewNum: 0, series: [{ name: '收藏量', type: 'line', stack: '总量', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] }] },
    getFansAddressData: [],
    getAuthorNewsHomepageVo: {
      commentNum: 0,
      fansNum: 0,
      fansYesterdayChangeNum: 0,
      favourNum: 0,
      series: [{ name: '', stack: '', type: '', data: [] }],
      viewNum: 0,
      yesterdayCommentNum: 0,
      yesterdayFavourNum: 0,
      yesterdayViewNum: 0
    },
    getFansSexData: { data: [{ value: 0, name: '' }], legendData: [] },
    currentEditNews: {
      title: '',
      type: 1,
      examineStatusOne: 0,
      imageUrl: [],
      columnId: 1,
      content: '',
      listType: 3,
      publisherId: '',
      sourceId: 102,
      isAuthorNews: 2,
      isPublish: 1,
      gmtCreate: '',
      showNum: 0,
      commentNum: 0,
      viewsNum: 0
    },
    columns: { data: {} },
    listNewsSource: { data: {} },
    authorProjectList: { list: {} },
    authorNewsList: { list: {}, total: 0 },
    authorListAuthorComment: { list: {} },
    commentListTwoNews: { list: {} },
    listVipFans: { data: {} },
    getNewsDataYesterday: { commentNum: 0, favourNum: 0, series: [{ name: '收藏量', type: 'line', stack: '总量', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] }], showNum: 0, viewNum: 0 },
    token: '',
    loading: false,
    user: { isLogin: false },
    error: { status: false, message: '' },
    getNewsInteraction: {
      favourNum: 1,
      commentNum: 1,
      collectNum: 1,
      series: [
        { name: '收藏量', type: 'line', srack: '总量', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] },
        { name: '评论量', type: 'line', srack: '总量', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] },
        { name: '点赞量', type: 'line', srack: '总量', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] }
      ]
    },
    commentAdminListNews: { data: {} },
    listNewsDataFlow: {
      // 总点击率
      allClickNum: 0,
      // 总展现量
      allShowNum: 0,
      // 总阅读量
      allViewNum: 0,
      // 点击率
      clickNum: 0,
      // 粉丝展现量
      fanceShowNum: 0,
      // 粉丝阅读量
      fanceViewNum: 0,
      // 图形数据
      series: [],
      // 展现量
      showNum: 0,
      // 阅读量
      viewNum: 0
    },
    getProjectInteractionData: {
      // 总点击率
      allClickNum: 0,
      // 总展现量
      allShowNum: 0,
      // 总阅读量
      allViewNum: 0,
      // 点击率
      clickNum: 0,
      // 粉丝展现量
      fanceShowNum: 0,
      // 粉丝阅读量
      fanceViewNum: 0,
      // 图形数据
      series: [],
      // 展现量
      showNum: 0,
      // 阅读量
      viewNum: 0
    },
    getProjectFlowData: {
      // 总点击率
      allClickNum: 0,
      // 总展现量
      allShowNum: 0,
      // 总阅读量
      allViewNum: 0,
      // 点击率
      clickNum: 0,
      // 粉丝展现量
      fanceShowNum: 0,
      // 粉丝阅读量
      fanceViewNum: 0,
      // 图形数据
      series: [],
      // 展现量
      showNum: 0,
      // 阅读量
      viewNum: 0
    }
  },
  mutations: {
    setError (state, e: GlobalErrorProps) {
      state.error = e
    },
    // 栏目列表
    fetchColumns (state, rawData) {
      state.columns = rawData.data.list
    },

    setLoading (state, status) {
      state.loading = status
    },
    // 来源列表
    listNewsSource (state, rawData) {
      state.listNewsSource = rawData.data
    },
    insertProject (state, rawData) {
      console.log('发布了项目', state, rawData)
    },
    insertNews (state, rawData) {
      console.log('发布了新闻', state, rawData)
    },

    login (state, rawData) {
      console.log('rawData', rawData)

      const { token, nickname, id } = rawData.data
      state.token = token
      state.user = { isLogin: true, ...rawData.data }
      localStorage.setItem('token', token)
      localStorage.setItem('userInfo', JSON.stringify(rawData.data))
      console.log('muitation', token)

      axios.defaults.headers.token = token
    },

    pcTokenLogin (state, rawData) {
      console.log('pcTokenLogin', state, rawData)
    },

    // 作品管理-作品列表
    authorNewsList (state, rawData) {
      state.authorNewsList = rawData.data
    },

    // 单个文章数据面板接口
    getNewsInteraction (state, rawData) {
      state.getNewsInteraction = rawData.data
    },

    // 获取用户信息
    getUserInfo (state, rawData) {
      // 获取用户信息,返回里面没有token,会把有效的token覆盖掉, 所以不能更新token
      state.user = { ...state.user, ...rawData.data, token: state.user.token }
      console.log('state.user', state.user)
      localStorage.setItem('userInfo', JSON.stringify(state.user))
    },

    // 更新用户信息
    userUpdate () {
      console.log('更新了用户信息,userUpdate')
    },
    // 根据id获取文章信息
    getNewsContentById (state, rowData) {
      console.log('getNewsContentById rowData', rowData)

      state.currentEditNews = rowData.data
    },
    // 查询标签 城市、行业、职位
    listLabel (state, rowData) {
      console.log('查询标签 城市、行业、职位', rowData)
      state.listLabel = rowData.data
    },
    // 修改文章
    updateNews (state, rowData) {
      console.log('修改了文章', rowData, 'state:', state)
    },
    // 文章流量分析数据接口
    listNewsDataFlow (state, rowData) {
      console.log('listNewsDataFlow rowData', rowData)

      state.listNewsDataFlow = rowData.data
    },
    // 项目列表接
    authorProjectList (state, rowData) {
      console.log('authorProjectList rowData', rowData)

      state.authorProjectList = rowData.data
    },
    // 粉丝列表
    listVipFans (state, rowData) {
      console.log('listVipFans rowData', rowData)

      state.listVipFans = rowData.data.list
    },
    // 粉丝地域数据列表接口
    getFansAddressData (state, rowData: any) {
      for (const i in rowData.data) {
        state.getFansAddressData.push([0, rowData.data[i].data[0], rowData.data[i].name])
      }
    },
    // 粉丝性别数据列表接口
    getFansSexData (state, rowData) {
      // 去掉第一个空的值
      state.getFansSexData.data.pop()
      for (const i in rowData.data) {
        state.getFansSexData.legendData.push(rowData.data[i].name)
        state.getFansSexData.data.push({ value: rowData.data[i].data[0], name: rowData.data[i].name })
      }
      console.log('getFansSexData', state, rowData)
    },
    // 粉丝性别数据列表接口
    getAuthorNewsHomepageVo (state, rowData) {
      state.getAuthorNewsHomepageVo = rowData.data
    },
    // 项目流量分析
    getProjectFlowData (state, rowData) {
      state.getProjectFlowData = rowData.data
    },
    // 项目互动分析
    getProjectInteractionData (state, rowData) {
      state.getProjectInteractionData = rowData.data
    },
    // 项目昨日数据面板
    getProjectYesterdayData (state, rowData) {
      state.getProjectYesterdayData = rowData.data
    },
    // 作者后台-评论管理
    authorListAuthorComment (state, rowData) {
      state.authorListAuthorComment = rowData.data
    },
    // 回复评论
    commentSendNews (state, rowData) {
      console.log('回复了评论', state, rowData)
    },
    // 获得回复评论的列表
    commentListTwoNews (state, rowData) {
      state.commentListTwoNews = rowData.data
    },
    // 删除评论
    adminCommentDelNews (state, rowData) {
      console.log('delete this comment', state, rowData)
    },
    // 新闻评论点赞
    newsCommentFavourInsert (state, rowData) {
      console.log('新闻评论点赞', state, rowData)
    },
    // 新闻评论取消点赞
    newsCommentFavourCancel (state, rowData) {
      console.log('cancel favour', state, rowData)
    },
    // getFansData
    getFansData (state, rowData) {
      console.log('cancel favour', state, rowData)
      state.getFansData = rowData.data
    },
    // getOneShotNewsData
    getOneShotNewsData (state, rowData) {
      console.log('getOneShotNewsData getOneShotNewsData', state, rowData)
      state.getOneShotNewsData = rowData.data
    },
    // 昨天文章数据接口
    getNewsDataYesterday (state, rowData) {
      console.log('昨天文章数据接口 昨天文章数据接口', state, rowData)
      state.getNewsDataYesterday = rowData.data
    },
    // 修改项目
    updateProject (state, rowData) {
      console.log('修改了项目', rowData, state)
    },
    // 删除文章
    deleteNews (state, rowData) {
      console.log('删除了文章', rowData, state)
    },
    // 显示菜单
    showMenu (state) {
      state.showMenu = !state.showMenu
    }
  },
  actions: {
    // 删除文章
    deleteNews ({ commit }, payload) {
      return asyncAndCommit('deleteNews',
        'deleteNews',
        commit,
        { method: 'get', params: payload }
      )
    },
    // 昨天文章数据接口
    getNewsDataYesterday ({ commit }, payload) {
      return asyncAndCommit('/getNewsDataYesterday',
        'getNewsDataYesterday',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 新闻评论取消点赞
    getOneShotNewsData ({ commit }, payload) {
      return asyncAndCommit('/getOneShotNewsData',
        'getOneShotNewsData',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 新闻评论取消点赞
    getFansData ({ commit }, payload) {
      return asyncAndCommit('/getFansData',
        'getFansData',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 新闻评论取消点赞
    newsCommentFavourCancel ({ commit }, payload) {
      return asyncAndCommit('/news/comment/favour/cancel',
        'newsCommentFavourCancel',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 新闻评论点赞
    newsCommentFavourInsert ({ commit }, payload) {
      return asyncAndCommit('/news/comment/favour/insert',
        'newsCommentFavourInsert',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 获得回复评论的列表
    commentListTwoNews ({ commit }, payload) {
      return asyncAndCommit('/comment/list/two/news',
        'commentListTwoNews',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 删除评论
    adminCommentDelNews ({ commit }, payload) {
      return asyncAndCommit('/admin/comment/del/news',
        'adminCommentDelNews',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 回复评论
    commentSendNews ({ commit }, payload) {
      return asyncAndCommit('/comment/send/news',
        'commentSendNews',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 作者后台-评论管理
    authorListAuthorComment ({ commit }, payload) {
      return asyncAndCommit('/author/listAuthorComment',
        'authorListAuthorComment',
        commit,
        { method: 'get', params: payload }
      )
    },
    // 项目昨日数据面板
    getProjectYesterdayData ({ commit }, payload: ProjectDataOneVo) {
      return asyncAndCommit('/getProjectYesterdayData',
        'getProjectYesterdayData',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 项目互动分析
    getProjectInteractionData ({ commit }, payload: ProjectDataOneVo) {
      return asyncAndCommit('/getProjectInteractionData',
        'getProjectInteractionData',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 项目流量分析
    getProjectFlowData ({ commit }, payload: ProjectDataOneVo) {
      return asyncAndCommit('/getProjectFlowData',
        'getProjectFlowData',
        commit,
        { method: 'post', data: payload }
      )
    },
    // 作者后台首页接口
    getAuthorNewsHomepageVo ({ commit }, payload) {
      return asyncAndCommit('/getAuthorNewsHomepageVo', 'getAuthorNewsHomepageVo', commit, { method: 'post', data: payload })
    },
    // 粉丝性别数据列表接口
    getFansSexData ({ commit }) {
      return asyncAndCommit('/getFansSexData', 'getFansSexData', commit, { method: 'post' })
    },
    // 粉丝地域数据列表接口
    getFansAddressData ({ commit }) {
      return asyncAndCommit('/getFansAddressData', 'getFansAddressData', commit, { method: 'post' })
    },
    // 粉丝列表
    listVipFans ({ commit }, payload: { page: number; limit: number }) {
      return asyncAndCommit('/list/vip/fans', 'listVipFans', commit, { method: 'post', data: payload })
    },
    // 修改项目
    updateProject ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/updateProject', 'updateProject', commit, { method: 'post', data: payload })
    },
    // 获取项目详情信息
    getWebOneProjectByProjecyId ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/getWebOneProjectByProjecyId', 'authorProjectList', commit, { method: 'get', params: payload })
    },
    // 项目列表
    authorProjectList ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/authorProjectList', 'authorProjectList', commit,
        { method: 'get', params: { ...payload, personId: JSON.parse(localStorage.getItem('userInfo') as string).id } })
    },
    // 文章流量分析数据接口
    listNewsDataFlow ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/listNewsDataFlow', 'listNewsDataFlow', commit, { method: 'post', data: payload })
    },
    // 修改文章(视频,图文,图集)
    updateNews ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/updateNews', 'updateNews', commit, { method: 'post', data: payload })
    },
    // 修改文章(视频,图文,图集)
    updateAtlasNewsContent ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/updateAtlasNewsContent', 'updateNews', commit, { method: 'post', data: payload })
    },

    // 根据id获取文章信息
    getNewsContentById ({ commit }, payload) {
      return asyncAndCommit('/getNewsContentById', 'getNewsContentById', commit, { method: 'get', params: payload })
    },
    // 获取用户信息
    listLabel ({ commit }, payload) {
      return asyncAndCommit('/listLabel', 'listLabel', commit, { method: 'post', data: payload })
    },
    // 获取用户信息
    userUpdate ({ commit }, payload) {
      return asyncAndCommit('/user/update', 'userUpdate', commit, { method: 'post', data: payload })
    },
    // 单个文章数据面板接口
    getNewsInteraction ({ commit }, payload: NewsInteractionReq) {
      return asyncAndCommit('/getNewsInteraction', 'getNewsInteraction', commit, { method: 'post', data: payload })
    },
    // 获取用户信息
    getUserInfo ({ commit }) {
      return asyncAndCommit('/user/info', 'getUserInfo', commit, { method: 'get' })
    },
    setUserInfo (context) {
      if (localStorage.getItem('userInfo') !== null) {
        context.commit('login', { data: JSON.parse(localStorage.getItem('userInfo') as string) })
      }
    },
    // pc token login
    pcTokenLogin ({ commit }, token) {
      console.log('action token', token)
      return asyncAndCommit('/user/info', 'pcTokenLogin', commit, { method: 'get', headers: { token: token } }, { token: token })
    },
    // 账号登录
    loginAccount ({ commit }, payload) {
      return asyncAndCommit('/login/account', 'login', commit, { method: 'post', data: payload })
    },
    // 获取文章栏目
    fetchColumns (context) {
      axios.post('/listReceptionColumn').then(resp => {
        if (resp === undefined) {
          return false
        } else {
          context.commit('fetchColumns', resp.data)
        }
      })
    },
    // 作品管理-作品列表(已发布, 草稿)
    authorNewsList ({ commit }, payload) {
      return asyncAndCommit('/authorNewsList', 'authorNewsList', commit, { method: 'get', params: payload })
    },

    // 作品管理-作品列表(审核中)
    authorNewsListOne ({ commit }, payload) {
      return asyncAndCommit('/authorNewsListOne', 'authorNewsList', commit, { method: 'get', params: payload })
    },

    // 作品管理-作品列表(未通过)
    authorNewsListTwo ({ commit }, payload) {
      return asyncAndCommit('/authorNewsListTwo', 'authorNewsList', commit, { method: 'get', params: payload })
    },

    // 获取文章来源
    listNewsSource (context) {
      axios.post('/listNewsSource').then(resp => {
        if (resp === undefined) {
          return false
        } else {
          console.log('resp:', resp)

          context.commit('listNewsSource', resp.data)
        }
      })
    },

    // 发布文章(视频,图文,图集)
    createPostNews ({ commit }, payload: NewsOneDto) {
      return asyncAndCommit('/insertNews', 'insertNews', commit, { method: 'post', data: payload })
    },
    // 项目
    insertProject ({ commit }, payload: ProjectDto) {
      return asyncAndCommit('/insertProject', 'insertProject', commit, { method: 'post', data: payload })
    }
  },
  getters: {
  }
})

export default store
