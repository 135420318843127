<template>
<!-- 作品数据-单篇 -->
<div>
  <tab-nav>
    <tab-nav-item active>图文</tab-nav-item>
    <tab-nav-item>图集</tab-nav-item>
    <tab-nav-item>视频</tab-nav-item>
    <tab-nav-item>项目</tab-nav-item>
  </tab-nav>
  <media-list>
    <template #header>
      <li class="row pt-2 pb-2" style="background-color:#FAFAFA">
        <div class="col-4 row text-center">作品</div>
        <div class="col">展现量</div>
        <div class="col">阅读量</div>
        <div class="col">点击率</div>
        <div class="col">阅读时长</div>
        <div class="col">点击量</div>
        <div class="col">评论量</div>
        <div class="col">操作</div>
      </li>
    </template>
    <media-item-simple  coverPath="https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=1812517192,1264801167&fm=85&app=92&f=PNG?w=121&h=75&s=BFFAEF16210A554D00ECF57E03005070"
        title="测试标题很长很长的测试标题"  dateTime="08-01 10:22" ></media-item-simple>

    <media-item-simple  coverPath="https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=1812517192,1264801167&fm=85&app=92&f=PNG?w=121&h=75&s=BFFAEF16210A554D00ECF57E03005070"
        title="测试标题很长很长的测试标题"  dateTime="08-01 10:22" ></media-item-simple>

  </media-list>
</div>
</template>
<style scoped>
  /* li:last-child{
    border-bottom: none !important;
  } */
</style>
<script lang="ts">
import { defineComponent } from 'vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
import MediaList from '../components/MediaList.vue'
import MediaItemSimple from '../components/MediaItemSimple.vue'
export default defineComponent({
  name: 'DataFaceOne',
  components: {
    TabNav,
    MediaList,
    MediaItemSimple,
    TabNavItem
  }
})
</script>
