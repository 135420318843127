<template>
  <div class="container-ppping row">
    <loader v-if="isLoading"></loader>
    <glboal-header :has3="has3" :nickname="user.nickname" :id="user.id" :avatar="user.avatar" v-if="user.isLogin"></glboal-header>
    <global-menu  v-if="showMenu || !has3" class="col-2"></global-menu>
    <div @click="store.state.showMenu=false" :class="user.isLogin?'m-2 p-4 col-9 content':''">
        <div class="content1" :class="isRow ? 'content2':''">
      <router-view></router-view>
      </div>
    </div>
    <!-- <column-list :list="list"></column-list> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed, watch, ref, onMounted } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import { ColumnProps } from './components/CoumnList.vue'
import GlboalHeader from './components/GlobalHeader.vue'
import GlobalMenu from './components/GlobalMenu.vue'
import store from './store'
import Loader from './components/Loader.vue'
import createMessage from './components/createMessage'

const testData: ColumnProps[] = [
  {
    // id
    id: '188907889541235622',

    // 标题
    title: '宁波市海曙国有资本经营集团有限公司下属公司基本账户竞争性存放项目',

    // 文章审核状态
    status: 12,

    // 封面
    // cover: 'http://jrcm.oss-cn-beijing.aliyuncs.com/4417234765343625217.jpg',

    // 日期
    date: '08-01 10:22',

    // 展现数量
    displayCount: 1564,

    // 阅读数量
    viewCount: 12
  },
  {
    // id
    id: '188907889541235623',

    // 标题
    title: '边境口岸超短波监测站新建及升级改造项目招标公告',

    // 文章审核状态
    status: 12,

    // 封面
    cover: 'http://jrcm.oss-cn-beijing.aliyuncs.com/4417234888408698881.jpg',

    // 日期
    date: '07-22 10:22',

    // 展现数量
    displayCount: 332,

    // 阅读数量
    viewCount: 1
  }
]
export default defineComponent({
  name: 'App',
  components: {
    // ColumnList,
    GlobalMenu,
    Loader,
    GlboalHeader
  },
  setup () {
    const screenWidth = document.body.clientWidth
    // 点击控制显示
    const showMenu = computed(() => store.state.showMenu)
    // 三道杠是否显示
    const has3 = ref(false)
    if (screenWidth < 1280) {
      has3.value = true
      store.state.showMenu = false
    } else {
      store.state.showMenu = true
      has3.value = false
    }
    const widthVar = ref(0)
    const heightVar = ref(0)
    const header = ref(null)
    window.onresize = () => {
      store.state.showMenu = (document.body.clientWidth > 1280)
      has3.value = !(document.body.clientWidth > 1280)
    }
    const isLoading = computed(() => store.state.loading)
    const error = computed(() => store.state.error)

    const { user } = toRefs(store.state)
    watch(() => error.value.status, () => {
      const {
        status,
        message
      } = error.value
      if (status && message) {
        createMessage(message, 'error')
      }
    })
    return {
      store,
      header,
      has3,
      showMenu,
      widthVar,
      heightVar,
      user,
      isLoading,
      list: testData
    }
  }
})
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
    }
body, .container-ppping{
    background-color: #f0f4fb;
    }
.content{
    background-color:#f0f4fb;
    position: relative;
    left:240px;
    top:0px;
    }
ul {
    list-style-type: none;
    padding: 0;
    }
.content .content2{
    background-color: #ffffff;
    margin:80px 0px 40px 0px;
    padding: 20px 60px 20px 60px;
    min-height: 800px;
    }
.content .content1{
    background-color: #ffffff;
    margin:60px 0px 40px 0px;
    padding: 20px 60px 20px 60px;
    min-height: 720px;
    }
@media screen and (max-width: 1400px){
    body, .container-ppping{
    background-color: #f0f4fb;
    width: 1400px;
    }
.content1{
    width: 1080px;
    }
    }
@media screen  and (min-width:960px) and (max-width: 1280px){
    body, .container-ppping{
    background-color: #f0f4fb;
    width: 100%;
    margin: 0 !important;
    }
.content1{
    width:calc(100% + 240px);
    }
.content{
    left:0px;
    }
    }
@media screen  and (max-width: 960px){
body, .container-ppping{
    background-color: #f0f4fb;
    width: 100%;
    margin: 0 !important;
    }
.content1{
    width:920px;
    }
.content{
    left:0px;
    }
    }
@media screen and (max-width: 414px){
    body, .container-ppping{
    background-color: #fff;
    }
.content{
    background-color:#fff;
    position: relative;
    left:0px;
    top:0px;
    }
 .content .content1{
    width: 100%;
    margin:0px 0px ;
    padding:120px 24px 0px;
    min-height: 720px;
    }
.m-2,.p-4,.col-9,.content{
    margin: 0!important;
    padding: 0!important;
    width: 100%;
    }
    }
</style>
