import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
// axios.defaults.baseURL = 'http://39.97.227.192:8881/'
// axios.defaults.baseURL = 'http://117.78.39.240:8881/'
axios.defaults.baseURL = 'https://www.jrfycm.com/api/'
axios.defaults.headers.source = 'pcAuthor'
axios.interceptors.request.use(config => {
  // config.params = { ...config.params, icode: 'icode test' }
  return config
})
axios.interceptors.response.use(config => {
  setTimeout(() => {
    store.commit('setLoading', false)
  }, 1000)
  return config
}, e => {
  console.log('e.response', e.response)
  if (e.response) {
    const { error } = e.response.data
    store.commit('setError', { status: true, message: error })
    store.commit('setLoading', false)
    return Promise.reject(e.response.data)
  } else {
    return Promise.reject(new Error('e.response is undefined hzhz'))
  }
})
const app = createApp(App)
app.use(router)
app.use(store)
app.use(Antd)
app.mount('#app')
