
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormgOption',
  props: {
    selected: {
      type: Boolean,
      required: false
    },
    value: {
      type: String,
      required: true
    }
  }
})
