
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CountItem',
  props: {
    name: {
      type: String,
      require: true
    },
    count: {
      type: Number,
      require: true
    }
  }
})
