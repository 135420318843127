
import { defineComponent } from 'vue'
import MenuParent from './MenuParent.vue'
import MenuItem from './MenuItem.vue'

export default defineComponent({
  name: 'GlobalMenu',
  components: {
    MenuParent,
    MenuItem
  },
  setup () {
    const menuParentId = 'accordionExample'
    return {
      menuParentId
    }
  }
})
