<template>
  <li class="nav-item text-left">
    <slot></slot>
  </li>
</template>
<style scoped>
.text-left {
  text-align: center !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MenuItem'
})
</script>
