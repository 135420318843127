<template>
  <form-global :label="label" :id="id" tips="编辑">
    <div class="form-group mb-4">
      <select @change.prevent="updateValue" :value="inputRef.val" class="form-control" :id="id">
        <slot></slot>
      </select>
    </div>
  </form-global>
</template>
<style scoped>
.form-control {
  appearance: auto !important;
  -webkit-appearance: auto !important;
}
</style>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import FormGlobal from './FormGlobal.vue'
export default defineComponent({
  name: 'FormSelect',
  components: {
    FormGlobal
  },
  props: {
    modelValue: String,
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const inputRef = reactive({
      val: props.modelValue || 1,
      error: false,
      message: ''
    })
    const updateValue = (e: { target: HTMLSelectElement }) => {
      const targetValue = (e.target as HTMLSelectElement).value
      inputRef.val = targetValue
      context.emit('update:modelValue', targetValue)
    }
    return {
      inputRef,
      updateValue
    }
  }
})
</script>
