<!-- 评论管理全部 -->
<template>
  <div class="row row1">
    <div class="col-6">
      <tab-nav>
        <!-- 全部 -->
        <tab-nav-item @click="reqParam.type=0;reqParam.page=1" :active="reqParam.type===0">全部</tab-nav-item>
        <!-- 图文 -->
        <tab-nav-item @click="reqParam.type=1;reqParam.page=1" :active="reqParam.type===1">图文</tab-nav-item>
        <!-- 视频 -->
        <tab-nav-item @click="reqParam.type=3;reqParam.page=1" :active="reqParam.type===3">视频</tab-nav-item>
        <!-- 图集 -->
        <tab-nav-item @click="reqParam.type=2;reqParam.page=1" :active="reqParam.type===2">图集</tab-nav-item>
      </tab-nav>
      <search-bar @go-search="goSearch" class="col" :hasDateBar="true"></search-bar>
      <div v-if="reqParam.type!==0">
        <media-list :total="total">
          <media-item
            @click="setCurrent(news)"
            :active="reqParam2.newsId===news.id"
            v-for="news in authorNewsList.list" :key="news.id"
            :coverPath="news.imageUrl===undefined?'':news.imageUrl[0]+'?x-oss-process=image/quality,q_60'"
            :id = "news.id"
            :title="news.title" :status="news.examineStatusOne" :dateTime="news.gmtCreate" :isRow="true">
            <template #countGroup>
              <count-item name="展现" :count="news.showNum"></count-item>
              <count-item name="阅读" :count="news.viewsNum"></count-item>
              <count-item name="评论" :count="news.commentNum"></count-item>
            </template>
            <template #buttonGroup>
              <button-item data-toggle="modal" :newsData="JSON.stringify(news)"
              data-target="#exampleModal"></button-item>
              <button-item v-if="news.examineStatusOne===0"></button-item>
              <button-item></button-item>
            </template>
          </media-item>
        </media-list>
      </div>
      <!-- 这个是左边的 -->
      <div v-if="reqParam.type===0">
        <media-list :total="total" :hasBar="false" :hasDateBar="true">
          <comment-item
            v-for="(comment,key) in authorListAuthorComment.list"
            :avatar="comment.avatarUrl"
            :sendData="comment"
            :key="key"
            :nickname="comment.nickname"
            :active="id===comment.id"
            :isFans="comment.isFans"
            :dateTime="comment.gmtCreate"
            :content="comment.content"
            :newsTitle="comment.title"
            :isFavour="comment.isFavour"
            @update-right-list="updateRighList"
            @list-review="clickReview"
          >
          </comment-item>
          <p v-if="total===0" class="text-primary">暂无数据</p>
        </media-list>
      </div>
      <a-pagination v-model:current="reqParam.page" v-model:pageSize="reqParam.limit" :total="total" show-less-items style="margin:20px 0"/>
    </div>

    <!-- 这是右边的 -->
    <div class="col-5 ml-4">
      <comment-filter-bar class="mb-3"></comment-filter-bar>
      <comment-item
        v-if="id!=='string' && reqParam2.id!=='0'"
        :isFans = "isFans"
        :dateTime="gmtCreate"
        :content="content"
        :isFavour="isFavour"
        :newsTitle="title"
        :avatar="avatarUrl"
        :nickname="nickname"
        :sendData="activeComment"
        @update-right-list="updateRighList"
        :isDetail="true"
      >
      </comment-item>
      <media-list v-if="reqParam2.id!=='0'" :total="total2">
        <comment-content
            v-for="two in commentListTwoNews.list"
            :id="two.commentId"
            @send-after="updateRighList"
            :avatar="two.userInfoDto.avatarUrl"
            :nickname="two.userInfoDto.nickname"
            :key="two.commentId"
            :isFans = "two.fans"
            :dateTime="two.publishTime"
            :content="two.content"
            :newsTitle="''"
            :isDetail="true"
        >
        </comment-content>
      </media-list>
      <media-list v-if="reqParam2.id==='0'" :total="total2" :hasBar="false">
        <comment-content
            v-for="two in authorListAuthorComment.list"
            :id="two.id"
            @send-after="updateRighList"
            :avatar="two.avatarUrl"
            :nickname="two.nickname"
            :key="two.id"
            :isFans = "two.isFans"
            :dateTime="two.gmtCreate"
            :content="two.content"
            :newsTitle="''"
            :isDetail="true"
        >
        </comment-content>
      </media-list>
      <p v-if="total2===0" class="text-primary">暂无数据</p>
      <a-pagination v-model:current="reqParam2.page" v-model:pageSize="reqParam2.length" :total="total2" show-less-items class="page"/>
    </div>
  </div>

<!-- 手机端 -->
<div class="phone row">
    <div class="col-6">
      <tab-nav>
        <!-- 全部 -->
        <tab-nav-item @click="reqParam.type=0;reqParam.page=1" :active="reqParam.type===0">全部</tab-nav-item>
        <!-- 图文 -->
        <tab-nav-item @click="reqParam.type=1;reqParam.page=1" :active="reqParam.type===1">图文</tab-nav-item>
        <!-- 视频 -->
        <tab-nav-item @click="reqParam.type=3;reqParam.page=1" :active="reqParam.type===3">视频</tab-nav-item>
        <!-- 图集 -->
        <tab-nav-item @click="reqParam.type=2;reqParam.page=1" :active="reqParam.type===2">图集</tab-nav-item>
      </tab-nav>
      <search-bar @go-search="goSearch" class="col" :hasDateBar="true"></search-bar>
      <div v-if="reqParam.type!==0">
        <media-list :total="total">
          <media-item
            @click="setCurrent(news)"
            :active="reqParam2.newsId===news.id"
            v-for="news in authorNewsList.list" :key="news.id"
            :coverPath="news.imageUrl===undefined?'':news.imageUrl[0]+'?x-oss-process=image/quality,q_60'"
            :id = "news.id"
            :title="news.title" :status="news.examineStatusOne" :dateTime="news.gmtCreate" :isRow="true">
            <template #countGroup>
              <count-item name="展现" :count="news.showNum"></count-item>
              <count-item name="阅读" :count="news.viewsNum"></count-item>
              <count-item name="评论" :count="news.commentNum"></count-item>
            </template>
            <template #buttonGroup>
              <button-item data-toggle="modal" :newsData="JSON.stringify(news)"
              data-target="#exampleModal"></button-item>
              <button-item v-if="news.examineStatusOne===0"></button-item>
              <button-item></button-item>
            </template>
          </media-item>
        </media-list>
      </div>
      <!-- 这个是左边的 -->
      <div v-if="reqParam.type===0">
        <media-list :total="total" :hasBar="false" :hasDateBar="true">
          <comment-item
            v-for="(comment,key) in authorListAuthorComment.list"
            :avatar="comment.avatarUrl"
            :sendData="activeComment"
            :key="key"
            :nickname="comment.nickname"
            :active="id===comment.id"
            :isFans="comment.isFans"
            :dateTime="comment.gmtCreate"
            :content="comment.content"
            :newsTitle="comment.title"
            :isFavour="comment.isFavour"
            @update-right-list="updateRighList"
            @list-review="clickReview"
            :isDetail="true"
          >
          </comment-item>
          <p v-if="total===0" class="text-primary">暂无数据</p>
        </media-list>
      </div>
      <a-pagination v-model:current="reqParam.page" v-model:pageSize="reqParam.limit" :total="total" show-less-items style="margin:20px 0"/>
    </div>
  </div>
</template>
<style scoped>
.text-primary{
  color: #CF000D !important;
}
.col-6{
    margin:20px 0;
    font-size: 16px;
    height: 100%;
    border-right: 1px solid rgb(241, 241, 241);
    min-height: 600px;
    }
.col{
    margin: 12px;
    }
.page{
    margin-top: 20px;
    }
.ml-4{
    margin-top: 80px;
    }
.col-5{
    width: 47%;
    }
    /* 手机端,只有在屏幕大于415的时候才显示,其他时候隐藏 */
@media screen and (min-width:415px){
    .phone {
      display: none;
    }
}
@media screen and (max-width:414px){
.row1{
    display: none;
    }
.ml-4{
        display: none;
    }
.row:nth-child(2){
    margin-top: -60px;
    }
.col-6{
    width: 100%;
    border-right:none;
    }
.col{
    margin-left: 0!important;
    margin-right: 0!important;
    }
    }
</style>
<script lang="ts">
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import MediaList from '../components/MediaList.vue'
import MediaItem from '../components/MediaItem.vue'
import CommentItem from '../components/CommentItem.vue'
import CommentContent from '../components/CommentContent.vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
import store, { AuthorCommentDto } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import SearchBar from '../components/SearchBar.vue'
import CountItem from '../components/CountItem.vue'

export default defineComponent({
  name: 'CommentListAll',
  components: {
    CommentItem,
    MediaItem,
    SearchBar,
    CommentContent,
    TabNav,
    CountItem,
    TabNavItem,
    MediaList
  },
  setup () {
    const authorListAuthorComment = computed(() => store.state.authorListAuthorComment)
    const authorNewsList = computed(() => store.state.authorNewsList)

    // 二级评论
    const commentListTwoNews = computed(() => store.state.commentListTwoNews)
    const route = useRoute()

    const activeComment = toRefs(reactive({
      avatarUrl: 'string',
      content: 'string',
      gmtCreate: 'string',
      id: 'string',
      isFans: 1,
      isFavour: 1,
      newsId: 'string',
      nickname: 'string',
      title: 'string',
      userId: 'string'
    }))

    const reqParam2 = reactive({
      // 二级评论页数
      page: Number(route.query.page2) || 1,
      // 二级评论的id
      id: route.query.id || '',
      isLevel: route.query.isLevel || '1',
      // 二级评论数量
      length: Number(route.query.length) || 5,
      // 当前新闻id
      newsId: route.query.newsId || '1'
    })

    // 点击回复
    const clickReview = (data: AuthorCommentDto) => {
      console.log('sss');
      
      activeComment.avatarUrl.value = data.avatarUrl
      activeComment.content.value = data.content
      activeComment.gmtCreate.value = data.gmtCreate
      activeComment.id.value = data.id
      activeComment.newsId.value = data.newsId
      activeComment.nickname.value = data.nickname
      activeComment.title.value = data.title
      activeComment.userId.value = data.userId
      activeComment.isFavour.value = data.isFavour
      activeComment.isFans.value = data.isFans
      reqParam2.id = data.id
    }

    const reqParam = reactive(
      {
        // 每一页显示的数量
        limit: Number(route.query.limit) || 5,
        // 第几页
        page: 0,
        type: 0,
        // 开始时间
        startTime: route.query.startTime || '2000-11-16 00:00:00',
        // 结束时间
        endTime: route.query.endTime || '3020-11-16 00:00:00',
        // 搜索关键字
        content: route.query.content || ''
      }
    )

    // 设置当前文章
    const setCurrent = (news: any) => {
      reqParam2.newsId = news.id
      reqParam2.id = '0'
    }

    const total = ref(0)
    const total2 = ref(0)

    const router = useRouter()

    // 重新获取二级评论
    const updateRighList = () => {
      store.dispatch('authorListAuthorComment', reqParam).then((data) => {
        if (data.code === '200') {
          // 默认第一个激活
          total.value = data.data.total
          activeComment.avatarUrl.value = data.data.list[0].avatarUrl
          activeComment.content.value = data.data.list[0].content
          activeComment.gmtCreate.value = data.data.list[0].gmtCreate
          activeComment.id.value = data.data.list[0].id
          activeComment.newsId.value = data.data.list[0].newsId
          activeComment.nickname.value = data.data.list[0].nickname
          activeComment.title.value = data.data.list[0].title
          activeComment.userId.value = data.data.list[0].userId
          activeComment.isFavour.value = data.data.list[0].isFavour
          activeComment.isFans.value = data.data.list[0].isFans
          reqParam2.id = data.data.list[0].id
        }
      })
      store.dispatch('commentListTwoNews', reqParam2)
    }

    watch(reqParam2, (data) => {
      if (data.id === '0') {
        store.dispatch('authorListAuthorComment', { ...data, limit: data.length }).then((data) => {
          total2.value = data.data.total
        })
      } else {
        store.dispatch('commentListTwoNews', data).then((data) => {
          total2.value = data.data.total
        })
      }

      router.push({
        path: route.path,
        query: { ...route.query, page2: data.page, id: data.id, isLevel: data.isLevel, length: data.length }
      })
    })

    watch(reqParam, (data) => {
      // 全部
      if (data.type === 0) {
        store.dispatch('authorListAuthorComment', data).then((data) => {
          if (data.code === '200') {
            // 默认第一个激活
            total.value = data.data.total
            activeComment.avatarUrl.value = data.data.list[0].avatarUrl
            activeComment.content.value = data.data.list[0].content
            activeComment.gmtCreate.value = data.data.list[0].gmtCreate
            activeComment.id.value = data.data.list[0].id
            activeComment.newsId.value = data.data.list[0].newsId
            activeComment.nickname.value = data.data.list[0].nickname
            activeComment.title.value = data.data.list[0].title
            activeComment.userId.value = data.data.list[0].userId
            activeComment.isFavour.value = data.data.list[0].isFavour
            activeComment.isFans.value = data.data.list[0].isFans
            reqParam2.id = data.data.list[0].id
          }
        })
      } else {
        // 根据文章
        store.dispatch('authorNewsList', { ...reqParam, examineStatusOne: 12 }).then((data) => {
          if (data.data.total === 0) {
            total.value = data.data.total
            reqParam2.newsId = '0'
            reqParam2.id = '0'
          } else {
            total.value = data.data.total
            reqParam2.newsId = data.data.list[0].id
            reqParam2.id = '0'
          }
        })
      }

      router.push({
        path: route.path,
        query: { ...route.query, ...data }
      })
    })

    reqParam.page = 1

    // 搜索功能
    const goSearch = (timeArr: Array<string>, content: string) => {
      if (timeArr === undefined || timeArr[0] === '') {
        reqParam.startTime = '2000-11-16 00:00:00'
        reqParam.endTime = '3020-11-16 00:00:00'
      } else {
        reqParam.startTime = timeArr[0] + ' 00:00:00'
        reqParam.endTime = timeArr[1] + ' 00:00:00'
      }
      reqParam.content = content
    }
    return {
      authorListAuthorComment,
      commentListTwoNews,
      clickReview,
      ...activeComment,
      activeComment,
      goSearch,
      total,
      setCurrent,
      total2,
      reqParam2,
      reqParam,
      authorNewsList,
      updateRighList
    }
  }
})
</script>
