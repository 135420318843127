
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChartHeaderItem',
  props: {
    title: {
      type: String,
      required: true
    },
    total: {
      type: String,
      required: true
    },
    addString: {
      type: String,
      required: true
    },
    addNum: {
      type: String,
      required: true
    }
  }
})
