
import {
  defineComponent
} from 'vue'
import { useRouter } from 'vue-router'
import store from '../store'

export default defineComponent({
  name: 'GlobalHeader',
  props: {
    // 是否含有三道杠
    has3: Boolean,
    nickname: String,
    avatar: String,
    id: String
  },
  setup (props) {
    // 用户头像如果不存在或者网站使用默认图,则作者后台也是用默认图
    const userAvatar = (props.avatar && props.avatar !== '../img/avatar.jpg') ? props.avatar : require('@/assets/avatar_user@2x.png')

    const router = useRouter()
    const logout = () => {
      store.state.token = ''
      store.state.user.isLogin = false
      localStorage.clear()
      router.push('/login')
    }
    const showMenu = () => {
      store.state.showMenu = !store.state.showMenu
    }
    const toSet = () => {
      console.log('ss');
      
        router.push('/Settings')
    }
    return {
      userAvatar,
      store,
      logout,
      showMenu,
      toSet
    }
  }
})
