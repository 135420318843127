
import { defineComponent } from 'vue'
import DateTime from './DateTime.vue'
export default defineComponent({
  name: 'MediaItemSimple',
  components: {
    DateTime
  },
  props: {
    title: { type: String, require: true },
    dateTime: { type: String, require: true },
    coverPath: { type: String, require: false }
  }
})
