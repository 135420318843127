
import store, { NewsOneDto, ResponseType } from '@/store'
import { message } from 'ant-design-vue'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import DateTime from './DateTime.vue'
export default defineComponent({
  name: 'MediaItem',
  components: {
    DateTime
  },
  props: {
    title: { type: String, require: true },
    id: { type: String, require: true },
    dateTime: { type: String, require: true },
    coverPath: { type: String, require: false },
    active: { type: Boolean, requried: false },
    // 审核状态
    status: { type: Number, require: true },
    isRow: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const visible = ref(false)
    enum ExamineStatus{
      草稿 = 1,
      等待主编审核 =2,
      主编审核中 = 3,
      主编审核未通过=4,
      等待政审=5,
      政审中=6,
      政审未通过=7,
      等待总编审核=8,
      总编审核中=9,
      总编审核未通过=10,
      通过=11,
      发布=12
    }
    const statusText = ref<string>(ExamineStatus[1])
    statusText.value = ExamineStatus[1]
    if (props.status) {
      statusText.value = ExamineStatus[props.status]
    }

    const statusColor = ref('prepare-text')
    if (props.status === 12) {
      statusColor.value = 'published-text'
    } else if (props.status === 3 || props.status === 6 || props.status === 9) {
      statusColor.value = 'loading-text'
    } else if (props.status === 4 || props.status === 7 || props.status === 10) {
      statusColor.value = 'no-text'
    }

    watch(() => props.status, (status) => {
      if (status) {
        statusText.value = ExamineStatus[status]
        if (status === 12) {
          statusColor.value = 'published-text'
        } else if (status === 3 || status === 6 || status === 9) {
          statusColor.value = 'loading-text'
        } else if (status === 4 || status === 7 || status === 10) {
          statusColor.value = 'no-text'
        }
      }
    })
    const newsTitle = ref('')
    // 封面数量
    const imageNum = ref(1)
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const content = ref('')
    const newsType = ref()
    const currentNews = ref<NewsOneDto|null>()

    // e true 显示 false 关闭
    const afterVisibleChange = (e: boolean) => {
      if (!e && currentNews.value) {
        currentNews.value.content = ''
      }
    }
    // 文章封面
    const coverImageArr: string[] = []
    const showDrawer = () => {
      store.dispatch('getNewsContentById', { newsId: props.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          message.error('获取文章详情失败')
          return false
        }
        currentNews.value = rawData.data
        const currentEditNews = rawData.data
        content.value = currentEditNews.content
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        newsType.value = currentEditNews.type
        if (currentEditNews.imageUrl && currentEditNews.imageUrl?.length < 1) {
          imageNum.value = 0
        } else if (currentEditNews.imageUrl) {
          imageNum.value = currentEditNews.imageUrl.length
          Array.prototype.push.apply(coverImageArr, currentEditNews.imageUrl as string[])
          // uploadedData.value = { url: coverImageArr[0] }
        }
        visible.value = true
      })
    }
    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)
    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })
    return {
      currentNews,
      afterVisibleChange,
      newsTitle,
      columns,
      listNewsSource,
      content,
      newsColumn,
      newsSource,
      statusText,
      visible,
      showDrawer,
      statusColor
    }
  }
})
