
import { defineComponent, ref, computed, onMounted } from 'vue'
import store, { OssResponse } from '../store'
import Uploader from '../components/FormUploader.vue'
import { beforeUploadCheck } from '../helper'
import createMessage from '../components/createMessage'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
// import OSS from 'ali-oss'
export default defineComponent({
  name: 'Settings',
  components: {
    Uploader,
    FormInput,
    FormSelect,
    FormOption
  },
  setup () {
    // 获取最新的用户信息
    store.dispatch('getUserInfo')
    store.dispatch('listLabel')

    const userInfo = computed(() => store.state.user)
    const listLabel = computed(() => store.state.listLabel)

    const uploadedData = ref()
    onMounted(() => {
      if (userInfo.value.avatar) {
        console.log('userInfo', userInfo.value)

        uploadedData.value = { url: userInfo.value.avatar }
      }
    })
    // 图片上传到OSS后的地址
    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, { format: ['image/jpeg', 'image/png'], size: 10 })
      const { passed, error } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 10Mb', 'error')
      }
      return passed
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      // 上传后的OSS的地址
      userInfo.value.avatar = rawData.url
    }

    const userUpdate = () => {
      store.dispatch('userUpdate', userInfo.value).then(() => {
        createMessage('保存成功', 'success', 2000)
      })
    }

    return {
      uploadCheck,
      listLabel,
      userUpdate,
      userInfo,
      uploadedData,
      handleFileUploaded
    }
  }
})
