<template>
  <div>
    <tab-nav>
      <!-- 全部 -->
      <tab-nav-item @click="reqParam.top='整体'" :active="reqParam.top === '整体'" class="tab-nav"
        >整体</tab-nav-item
      >
      <!-- 图文 -->
      <tab-nav-item @click="reqParam.top='单篇'" :active="reqParam.top === '单篇'" class="tab-nav"
        >单篇</tab-nav-item
      >
    </tab-nav>
    <div v-show="reqParam.top==='整体'">
        <div>
          <p class="text-left">核心数据</p>
          <chart-header class="text-left3">
            <chart-header-item title="昨日评论量" :total="getNewsDataYesterday.commentNum" addNum="" addString=""></chart-header-item>
            <chart-header-item title="昨日点赞量" :total="getNewsDataYesterday.favourNum" addNum="" addString=""></chart-header-item>
            <chart-header-item title="昨日展现量 " :total="getNewsDataYesterday.showNum" addNum="" addString=""></chart-header-item>
            <chart-header-item title="昨日阅读量" :total="getNewsDataYesterday.viewNum" addNum="" addString=""></chart-header-item>
          </chart-header>
        </div>
        <div class="mb-4">
          <p class="text-left">数据趋势</p>
          <p class="text-left pr-3">
            <span class="pr-4">时间</span>
            <!-- <span class="pr-4">7天</span>
            <span class="pr-4">30天</span> -->
            <a-range-picker
                :default-value="[modal[0], modal[1]]"
                :format="dateFormat"
                :mode = "modal"
                :locale="locale"
                @change="changeData"
              />
          </p>
        <div id="main" ref="chartRef"></div>
    </div>
    </div>
    <div v-if="reqParam.top==='单篇'" class="tab-nav1">
      <tab-nav>
        <!-- 全部 -->
        <tab-nav-item @click="reqParam.type = 0" :active="reqParam.type === 0" style="font-size:14px"
          >全部</tab-nav-item
        >
        <!-- 图文 -->
        <tab-nav-item @click="reqParam.type = 1" :active="reqParam.type === 1" style="font-size:14px"
          >图文</tab-nav-item
        >
        <!-- 图集 -->
        <tab-nav-item @click="reqParam.type = 2" :active="reqParam.type === 2" style="font-size:14px"
          >图集</tab-nav-item
        >
        <!-- 视频 -->
        <tab-nav-item @click="reqParam.type = 3" :active="reqParam.type === 3" style="font-size:14px"
          >视频</tab-nav-item
        >
      </tab-nav>
      <table class="table" style="text-align:left;">
        <thead>
          <tr>
            <th scope="col"  :key="col.key" v-for="col in columns">
              {{col.title}}
            </th>
          </tr>
        </thead>
        <tbody class="th">
          <tr :key="dat.id" v-for="dat in getOneShotNewsData.list">
            <td scope="col"  :key="col.key" v-for="col in columns">
              {{dat[col.dataIndex]}}
            </td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-model:current="reqParam.page"
        v-model:pageSize="reqParam.limit"
        :total="total"
        show-less-items
      />
    </div>
  </div>
</template>
<style scoped>
#main{
    width: 100%;
    height: 500px;
    }
#sex-chart, #age-chart,#area-chart{
    width: 100%;
    height: 424px;
    }
.text-left{
    font-size: 16px;
    margin: -16px 0 20px 0 !important;
    }
.mb-4 .text-left:nth-child(1){
    font-size: 16px;
    margin: 20px 0 30px!important;
    }
.mb-4 .pr-4{
    font-size: 14px;
    color: #999;
    margin-bottom:0 ;
    }
.pr-3{
    margin-bottom: 0;
    }
.tab-nav{
    font-size: 16px;
    margin: 20px 30px 40px 0 !important;
    }
.tab-nav1{
    margin-top:-16px !important;
    }
.text-left3{
    margin-bottom: 20px;
    }
.col:first-child{
    width: 120px!important;
    font-size: 6px!important;
    }
@media screen and (max-width: 414px){
.tab-nav{
    margin-top: -20px!important;
    }
thead>tr>th:nth-child(2){
  display: none;
}
tbody>tr>td:nth-child(2){
  display: none;
}
.pr-3{
    display: none;
    }
#main{
    margin-top: -60px;
    }
    }
</style>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import TabNavItem from '../components/TabNavItem.vue'
import TabNav from '../components/TabNav.vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'
import echarts from 'echarts'
import ChartHeaderItem from '../components/ChartHeaderItem.vue'
import ChartHeader from '../components/ChartHeader.vue'
import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default defineComponent({
  name: 'DataFaceAll',
  components: {
    TabNavItem,
    ChartHeaderItem,
    ChartHeader,
    TabNav
  },
  setup () {
    const dateFormat = 'YYYY-MM-DD'
    const chartRef = ref<null | HTMLElement>(null)
    const timeData: any = reactive({
      startTime: moment().subtract(30, 'days').format(dateFormat),
      endTime: moment().format(dateFormat),
      xAxisData: computed(() => {
        const xAxisData = []
        // 图标x轴的数据
        let tempTime: string = timeData.startTime
        while (tempTime !== timeData.endTime) {
          // 从弟凌天开始  一天一天的增加日期,从而动态控制时间轴, 一直到等于最后一天
          xAxisData.push(tempTime)
          tempTime = moment(tempTime).add(1, 'days').format(dateFormat)
        }

        return xAxisData
      })
    })
    const timeDataRef = toRefs(timeData)
    const modal = [timeDataRef.startTime.value, timeDataRef.endTime.value]

    const columns = [
      {
        title: '标题',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate'
      },
      {
        title: '评论量',
        dataIndex: 'commentNum',
        key: 'commentNum'
      },
      {
        title: '点赞量',
        dataIndex: 'favourNum',
        key: 'favourNum'
      },
      {
        title: '展现量',
        dataIndex: 'showNum',
        key: 'showNum'
      },
      {
        title: '浏览量',
        dataIndex: 'viewNum',
        key: 'viewNum'
      },
      {
        title: '点击率',
        dataIndex: 'clickNum',
        key: 'clickNum',
        slots: { customRender: 'clickNum' }
      }
    ]
    const router = useRouter()
    const route = useRoute()
    const reqParam = reactive({
      type: 0,
      // 单篇, 整体
      top: '整体',
      page: 0,
      startTime: timeDataRef.startTime.value,
      endTime: timeDataRef.endTime.value,
      limit: 5
    })

    const getOneShotNewsData = computed(() => store.state.getOneShotNewsData)
    const getNewsDataYesterday = computed(() => store.state.getNewsDataYesterday)
    console.log('getNewsDataYesterday', getNewsDataYesterday)

    const total = ref(0)
    watch(reqParam, (data) => {
      console.log('watch', data.startTime)

      if (data.startTime === ' 00:00:00') {
        data.startTime = ''
      }
      if (data.endTime === ' 00:00:00') {
        data.endTime = ''
      }
      store.dispatch('getOneShotNewsData', data).then((data) => {
        total.value = data.data.total
        console.log('total', data.data.total)
      })
      router.push({
        path: route.path,
        query: data
      })
    })
    reqParam.page = 1
    const changeData = (value: any, dateString: any) => {
      if (dateString[0] === '') {
        timeDataRef.startTime.value = ''
        return false
      }

      if (dateString[1] === '') {
        timeDataRef.endTime.value = ''
        return false
      }

      timeDataRef.startTime.value = dateString[0]
      timeDataRef.endTime.value = dateString[1]
      store.dispatch('getNewsDataYesterday', {
        startTime: dateString[0] + ' 00:00:00',
        endTime: dateString[1] + ' 00:00:00'
      })
    }
    store.dispatch('getNewsDataYesterday', {
      startTime: timeDataRef.startTime.value + ' 00:00:00',
      endTime: timeDataRef.endTime.value + ' 00:00:00'
    })
    onMounted(() => {
      console.log('chartRef.value', chartRef.value)

      const myChart = echarts.init(chartRef.value as unknown as HTMLCanvasElement)
      watch(getNewsDataYesterday, (data: any) => {
        // 绘制图表
        myChart.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: true,
            data: ['昨日评论量', '昨日点赞量', '昨日展现量', '昨日阅读量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeDataRef.xAxisData.value
          },
          yAxis: {
            type: 'value'
          },
          series: data.series
        })
      })
    })

    return {
      reqParam,
      columns,
      timeDataRef,
      modal,
      getNewsDataYesterday,
      getOneShotNewsData,
      locale,
      chartRef,
      dateFormat,
      changeData,
      total
    }
  }
})
</script>
