
import tinymce from 'tinymce'

import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/icons/default/icons'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/media'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/hr'
import '../../public/tinymce/axupimgs'
// import 'tinymce/plugins/powerpaste/plugin.min.js'

import Editor from '@tinymce/tinymce-vue'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import OSS from 'ali-oss'

export default defineComponent({
  name: 'TinymceEditor',
  components: {
    Editor
  },
  props: {
    modelValue: String
  },
  setup (props, context) {
    // 富文本框值
    const inputRef = reactive({
      val: computed({
        get: () => props.modelValue || '',
        set: val => {
          context.emit('update:modelValue', val)
        }
      }),
      error: false,
      message: ''
    })
    // 富文本框init配置
    const init = {
      selector: '#tinymce',
      // eslint-disable-next-line @typescript-eslint/camelcase
      language_url: '/tinymce/langs/zh_CN.js',
      image_dimensions: false,
      language: 'zh_CN',
      contextmenu: "paste | link image ",
      // eslint-disable-next-line @typescript-eslint/camelcase
      skin_url: '/tinymce/skins/ui/oxide',
      height: 350,
      branding: false,
      menubar:false,
      plugins:
      'link lists image axupimgs codesample code table wordcount  media table fullscreen pagebreak insertdatetime hr paste', // 插件
      toolbar:
      'undo redo removeformat | bold italic underline blockquote | bullist numlist | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | image link preview | forecolor backcolor', // 工具条
      // 'undo redo | bold italic underline alignleft aligncenter alignright alignjustify | forecolor backcolor | image | fontselect | fontsizeselect | formatselect |  bullist numlist | outdent indent blockquote | removeformat| undo redo | link unlink media insertdatetime table  hr pagebreak | fullscreen preview | strikethrough', // 工具条
      // eslint-disable-next-line @typescript-eslint/camelcase
      font_formats: 'Arial=arial,helvetica,sans-serif; 宋体=SimSun;  微软雅黑=Microsoft Yahei; Impact=impact,chicago;', // 字体
      // eslint-disable-next-line @typescript-eslint/camelcase
      fontsize_formats: '14px 16px 18px 24px 36px 48px 64px 72px', // 文字大小
      // eslint-disable-next-line @typescript-eslint/camelcase
      paste_data_images: true,
      //添加额外的插件：powerpaste
      // external_plugins: {
      //     'powerpaste': `tinymce/plugins/powerpaste/plugin.min.js`//${this.baseUrl}
      // },
      // powerpaste_word_import: 'merge',// 参数:propmt, merge, clear
      // powerpaste_html_import: 'merge',// propmt, merge, clear
      // powerpaste_allow_local_images: true,//粘贴图片
      // eslint-disable-next-line @typescript-eslint/camelcase
      codesample_languages: [
        { text: 'HTML/XML', value: 'markup' },
        { text: 'JavaScript', value: 'javascript' },
        { text: 'CSS', value: 'css' },
        { text: 'Java', value: 'java' },
        { text: 'C++', value: 'cpp' }
      ],
      // 图片上传回调
      // eslint-disable-next-line @typescript-eslint/camelcase
      images_upload_handler: (blobInfo: any, success: any) => {
        console.log('1111');
        
        const file = blobInfo.blob()
        // console.log('ss+',file)

        const client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: 'LTAIn0x2gz1Wwggx',
          accessKeySecret: 'rCsNfiGkEdGT8ROTL1Qim7UbWTLcuE',
          bucket: 'jrcm'
        })

        const fileName = new Date().getTime() + (Math.random() * 100000) + file.name.substr(-6, 6)
        console.log(file,fileName);
        
        // 'object'表示从OSS下载的object名称，'localfile'表示本地文件或者文件路径。
        client.put(fileName, file).then(function (r1) {
          success(r1.url)
          console.log('success+',r1.url);
          
        }).catch(function (err) {
          console.log('err-',err);
          console.log('file---',file);
          context.emit('file-uploaded-error', { err })
        })
      }
    }

    onMounted(() => {
      tinymce.init({})
      inputRef.val = props.modelValue as string
    })

    return {
      inputRef,
      init
    }
  }// setup
})
