<template>
<div class="form-check text-left float-left d-inline-block form-item">
  <input class="form-check-input mr-0 pr-0" :checked="checked" :value="checkValue" :type="type?type:'checkbox'" :name="checkName" :id="checkId">
  <label class="form-check-label ml-0 pl-0" :for="checkId">
    {{checkLabel}}
  </label>
</div>
</template>
<style scoped>
input:checked {
    background-color: #FF5E5E !important;
    border-color: #FF5E5E !important;
}
.form-check{
    float: left;
    width:80px;
    margin-left: 10px;
    }
@media screen and (max-width:414px){
.form-check{
    float: left;
    width:72px;
    margin-left: 10px;
    }
    }
@media screen and (width:320px){
.form-check{
    float: left;
    width:68px;
    margin-left: 6px;
    }
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    type: {
      type: String,
      required: false
    },
    checked: {
      type: Boolean,
      required: false
    },
    checkValue: {
      type: String,
      required: true
    },
    checkName: {
      type: String,
      required: true
    },
    checkLabel: {
      type: String,
      required: false
    },
    checkId: {
      type: String,
      required: true
    }
  }
})
</script>
