<template>
  <div>
    
    <div class="content">
      <form-input v-model="newsTitle" label="标题" id="news-title"></form-input>
      <button class="btn btn-secondary" @click.prevent="addImage" style="background:#FF5E5E !important;color:white;border-color: white;width: 100px;
    height: 36px;">
        增加图片
      </button>
      <uploader
        v-for="(c, k) in imageArrRef"
        :key="k"
        label="图片"
        :id="'news-image-' + k"
        :defaultImage="c.imageUrl.length>0?c.imageUrl:undefined"
        :beforeUpload="uploadCheck"
        @file-uploaded="handleFileUploadedContent"
        :uploaded="uploadedData"
        :imageKey="k"
        :hasText="true"
        class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
        style="height: 105px; width: 150px;margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;"
      >
        <template #imageText class="imgtext">
          <textarea
            class="text-area border mt-4 ml-3 col-5"
            v-model="c.describe"
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="添加图片描述"
          ></textarea>
          <button
            @click.prevent="delImage(k)"
            class="ml-1 mt-4 mb-4 btn btn-secondary col-3"
          >
            删除
          </button>
        </template>
        <span class="pl-2">+</span>
        <template #loading>
          <div>
            <div class="spinner-border text-secondary" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </template>
        <template #uploaded="dataProps">
          <div class="uploaded-area">
            <img
              :src="dataProps.uploadedData.url"
              style="height: 105px; width: 150px"
              class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
            />
          </div>
        </template>
      </uploader>
      <!-- 隐藏起来 图集封面就是三个 -->
      <FormCheck v-if="false" label="封面个数" id="new-tag">
        <form-check-item
          type="radio"
          :checked="true"
          checkValue="1"
          checkLabel="单图"
          checkId="cover1"
          checkName="type"
          @click="imageNum=1"
        ></form-check-item>
        <form-check-item
          type="radio"
          checkValue="3"
          checkLabel="三图"
          checkId="cover3"
          @click="imageNum=3"
          checkName="type"
        ></form-check-item>
      </FormCheck>
      <!-- 调试图片数量 -->
      <div v-if="imageNum===1">
        <uploader
          label=""
          id="news-cover0"
          tips="点击上传"
          :imageKey="0"
          :beforeUpload="uploadCheck"
          @file-uploaded="handleFileUploaded"
          :defaultImage="coverImageArr[0]"
          :uploaded="uploadedData"
          class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
          style="height: 105px; width: 150px;margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;"
        >
          <span class="pl-2">点击上传</span>
          <template #loading>
            <div>
              <div class="spinner-border text-secondary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </template>
          <template #uploaded="dataProps">
            <div class="uploaded-area">
              <img
                :src="dataProps.uploadedData.url"

                class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
              />
            </div>
          </template>
        </uploader>
      </div>
      <div v-if="imageNum===3" class="imageNum3">
        <uploader
          v-for="(img, key) in [0,1,2]"
          :key="key"
          :imageKey="key"
          :label="''"
          :id="'news-cover'+key"
          tips="点击上传"
          :beforeUpload="uploadCheck"
          :defaultImage="coverImageArr[key]"
          @file-uploaded="handleFileUploaded"
          :uploaded="uploadedData"
          class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
          style="height: 105px;width: 150px;margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;"
        >
          <span class="pl-2">封面 点击上传{{coverImageArr[key]}}</span>
          <template #loading>
            <div>
              <div class="spinner-border text-secondary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </template>
          <template #uploaded="dataProps">
            <div class="uploaded-area">
              <img
                :src="dataProps.uploadedData.url"

                class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
              />
            </div>
          </template>
        </uploader>
      </div>
      <form-select label="类型" v-model="newsColumn" id="news-type">
        <FormOption v-for="col in columns" :key="col.id" :value="col.id + ''">{{
          col.name
        }}</FormOption>
      </form-select>
      <form-select v-model="newsSource" label="来源网址" id="news-source">
        <FormOption
          v-for="col2 in listNewsSource"
          :key="col2.id"
          :value="col2.id + ''"
          >{{ col2.source }}</FormOption
        >
      </form-select>
      <div class=" operate">
        <button class="col-2 btn btn-light preview" @click.prevent="preview">预览</button>
        <button class="col-2 btn btn-light mr-4" @click.prevent="submitDraft">
          存草稿
        </button>
        <button class="col-2 btn btn-primary" @click.prevent="submitRelease">
          发布
        </button>
      </div>
    </div>
    <a-drawer
    title="文章详情"
    placement="right"
    :closable="false"
    v-model:visible="visible"
    :after-visible-change="afterVisibleChange"
  >
    <h3 class="title1">{{previewData.title}}</h3>
    <p>
      来源:
      <span v-if="newsSource=='0'">其他来源</span>
      <span v-for="col2 in listNewsSource"
          :key="col2.id">
        <span v-if="col2.id+''==newsSource">
          {{col2.source}}
        </span>
      </span>
    </p>
    <el-row v-if="previewData!==undefined && previewData.type==2">
      <el-col v-for="(i,index) in previewData.atlasNewsDtos" :key="i">
          <el-card :body-style="{ padding: '0px' }">
              <img style="width:100%" :src="i.imageUrl" class="image">
              <div style="padding: 14px;">
                  <span>{{i.describe}}</span>
                  <div class="bottom clearfix">
                      <time v-if='previewData.atlasNewsDtos.length>1' class="time">{{index+1}}/{{previewData.atlasNewsDtos.length}}</time>
                  </div>
              </div>
          </el-card>
      </el-col>
    </el-row>
  </a-drawer>
  <div v-if="previewVisible" class="preview-container">
    <img class="close" @click="previewVisible=false" src="../assets/X.png" alt="">
    <div class="preview-mobile">
      <!-- <span class="preview-title">标题在哪里</span>   -->
      <div class="con">
        <h3 class="title1">{{previewData.title}}</h3>
        <div class="source">
          来源:
          <span v-if="newsSource=='0'">其他来源</span>
          <span v-for="col2 in listNewsSource"
              :key="col2.id">
            <span v-if="col2.id+''==newsSource">
              {{col2.source}}
            </span>
          </span>
        </div>
        <el-row v-if="previewData!==undefined && previewData.type==2">
          <el-col v-for="(i,index) in previewData.atlasNewsDtos" :key="i">
              <el-card :body-style="{ padding: '0px' }">
                  <img style="width:100%" :src="i.imageUrl" class="image">
                  <div style="padding: 14px;">
                      <span>{{i.describe}}</span>
                      <div class="bottom clearfix">
                          <time v-if='previewData.atlasNewsDtos.length>1' class="time">{{index+1}}/{{previewData.atlasNewsDtos.length}}</time>
                      </div>
                  </div>
              </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <span>标题在哪里</span> -->
  </div>
  </div>
</template>
<style scoped>
.con {
  height: 100%;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: scroll;
}
.con::-webkit-scrollbar { width: 0 !important }
.con { -ms-overflow-style: none; }
.con { overflow: -moz-scrollbars-none; }
.title1{
  text-align: left;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
}
.source {
  text-align: left;
  margin-top: 10px;
}
.btn-primary {
  background-color: #FF5E5E !important;
}
.operate {
  margin-top: 50px;
}
.preview-content{
  line-height: 30px;
  margin-top: 12px;
    /* height: 70%; */
    /* padding-bottom: 100px; */
    overflow: scroll;
    text-align: justify;
    font-size: 18px;
}
.preview-content::-webkit-scrollbar { width: 0 !important }
.preview-mobile {
  /* background: red; */
  /* background-image: url('../assets/mobile.png'); */
  background-image: url('../assets/mobile.png');
  background-size: 100% 100%;
  background-position: center;
  width: 280px;
  height: 568px;
  text-align: inherit;
  margin: 0 auto;
  margin-top: 60px;
  padding: 76px 14px 40px 14px;
}
.close {
  /* text-align: initial; */
  position: absolute;
  right: 40px;
  top: 30px;
  width: auto;
}
.preview-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  background-color: rgba(0,0,0,0.62);
  /* color: white; */
  z-index: 1000;
}
.operate {
  margin-top: 50px;
}
.btn-primary {
    background-color: #FF5E5E;
    border-color: #FF5E5E;
}
.uploaded-area img {
  width: 150px;
  height: 105px;
}
@media screen and (max-width:414px){
.uploaded-area img{
    width:100px!important;
    height: 105px;
  }
}
@media screen and (width:414px){
.uploaded-area img{
    width:110px!important;
    height: 105px;
  }
}
@media screen and (width:320px){
.uploaded-area img{
    width:88px!important;
    height: 105px;
  }
}
.preview{
  margin-right: 59px;
}
.btn-secondary{
    background: #fff;
    color:red;
    border-color: #fff;
    }
.imgtext{
    width: 100% !important;
    }
.text-area {
    width:calc(100% - 100px);
    height: 104px;
    font-size: 14px;
    color: #6c757d;
    padding: 10px;
    }
.iphone6s {
    width: 375px;
    height: 667px;
    }
.content{
    position:static;
    background-color:#ffffff;
    }
.btn-light{
    border: 1px solid rgb(228, 228, 228);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-top: -66px;
    margin-left: -50px;
    }
.btn-light:hover{
    border: 1px solid #999;
    margin-left: -50px;
    background-color: #f8f9fa;
    margin-top: -66px;
    }
.btn-primary{
    border: 1px solid rgba(10, 31, 228, 0);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-left: -10px;
    margin-top: -66px;
    }
.btn-primary:hover{
    border: 1px solid rgba(10, 31, 228, 0);
    margin-left: -10px;
    margin-top: -66px;
    }
.imageNum3{
    display: flex;
    flex-direction:row;
    margin-left:184px
    }
@media screen and (max-width: 1400px){
.text-area{
    width:360px;
    }
.imageNum3{
    margin-left:130px
    }
    }
@media screen  and (min-width:960px) and (max-width: 1280px){
.text-area {
    width:calc(100% - 40px);
    }
    }
@media screen  and (max-width: 960px){
.imageNum3{
    margin-left:104px
    }
.text-area {
    width:calc(100% - 50px);
    }
    }
@media screen and (max-width:414px){
.btn-1{
    padding:40px 0 0 64px!important;
    }
#news-title{
    margin: 0;
    padding: 0;
    }
.imageNum3{
    display: flex;
    flex-direction:row;
    margin-left:0px !important;
    height:auto;
    }
.pl-2{
    width:150px;
    height: 60px;
    margin-top: 32px;
    display: block;
    padding-left:0!important ;
    }
.my-4{
    margin-top: 0!important;
    }
.text-area {
    width: calc(100% + 20px);
    }
.ml-3{
    margin-left: 0!important;
    }
    }
@media screen and (width:414px){
.text-area {
    width: calc(100% + 44px);
    }
    }
@media screen and (width:400px){
.text-area {
    width: calc(100% + 40px);
    }
    }
@media screen and (width:375px){
.text-area {
    width: calc(100% + 30px);
    }
    }
@media screen and (width:320px){
.text-area {
    width: calc(100% + 14px);
    margin-left: -4px!important;
    padding: 6px;
    }
.col-3{
    margin-left: -6px!important;
    }
    }
</style>
<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive } from 'vue'
import { beforeUploadCheck } from '../helper'
import {
  GlobalDataProps,
  OssResponse,
  NewsOneDto,
  AtlasNewsDto,
  ResponseType
} from '../store'
import createMessage from '../components/createMessage'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
import FormCheck from '../components/FormCheck.vue'
import FormCheckItem from '../components/FormCheckItem.vue'
import Uploader from '../components/FormUploader.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    FormInput,
    FormCheck,
    FormCheckItem,
    FormSelect,
    FormOption,
    Uploader
  },
  name: 'PostImageArr',
  setup () {
    const store = useStore<GlobalDataProps>()
    // 文章标题
    const newsTitle = ref('')
    // 封面数量
    const imageNum = ref(3)
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const visible = ref(false)
    const previewVisible = ref(false);
    // 文章封面
    const coverImageArr = ref(reactive(['']))
    const imageArrRef: AtlasNewsDto[] = reactive([
      { describe: '', imageUrl: '' }
    ])
    //预览内容
    const previewData : NewsOneDto = reactive({
      // 资讯栏目id
        columnId: 0,
        // 资讯内容
        content: '',
        //图片内容
        atlasNewsDtos:[],
        // 资讯标题
        title: '',
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 1,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: '',
        // 资讯来源id
        sourceId: 1,
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: 0
    })
    // visible.value = false;

    // 添加图片
    const addImage = () => {
      console.log(imageArrRef);
      imageArrRef.push({ describe: '', imageUrl: '' })
    }

    // 删除图片
    const delImage = (key: number) => {
      console.log(imageArrRef,key);
      console.log('sd d ');
      imageArrRef.splice(key, 1)
      console.log(imageArrRef);
    }

    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })

    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)

    const uploadedData = ref()

    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, {
        format: ['image/jpeg', 'image/png'],
        size: 10
      })
      const { passed, error } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 10Mb', 'error')
      }
      return passed
    }

    const handleFileUploadedContent = (rawData: OssResponse) => {
      imageArrRef[rawData.imageKey as number].imageUrl = rawData.url
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      coverImageArr.value[rawData.imageKey as number] = rawData.url
    }

    const router = useRouter()
    const route = useRoute()

    const isEditMode = !!route.query.id
    // 是修改还是添加
    const actionName = isEditMode ? 'updateAtlasNewsContent' : 'createPostNews'

    const sendData = (isPublish: number) => {
      if (newsTitle.value.trim() === '') {
        message.error('标题不能为空')
        return false
      }
      // if (coverImageArr.value.length !== imageNum.value) {
      //   message.error('封面数量不对')
      //   return false
      // }
      let covers = coverImageArr.value;
      if (covers.length != 3) {
        message.error('封面数量不对')
        return false;
      }
      if (covers[0].length == 0 || covers[1].length == 0 || covers[2].length == 0) {
        message.error('封面数量不对')
        return false;
      }
      
      const data: NewsOneDto = {
        // 资讯栏目id
        columnId: parseInt(newsColumn.value),
        // 资讯内容
        content: '',
        atlasNewsDtos: imageArrRef,
        // 资讯标题
        title: newsTitle.value,
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 2,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: coverImageArr.value.join(','),
        // 资讯来源id
        sourceId: parseInt(newsSource.value),
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: isPublish
      }
      if (isPublish == 3) {
        previewVisible.value = true;
        console.log(data);
        previewData.title = data.title
        previewData.content = data.content
        previewData.type = data.type
        previewData.listType = data.listType
        previewData.atlasNewsDtos = data.atlasNewsDtos
        return;
      }
      let infos = imageArrRef;
      console.log(infos.length,infos[0].imageUrl);
      
      if (infos.length == 0) {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      
      if (infos.length == 1 && infos[0].imageUrl == '') {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      if (infos.length == 2 && (infos[0].imageUrl == '' || infos[1].imageUrl == '')) {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      if (infos.length == 3 && ((infos[0] && infos[0].imageUrl == '') || (infos[1] && infos[1].imageUrl == '') || (infos[2] && infos[2].imageUrl == ''))) {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      if (coverImageArr.value.length < 1) {
        createMessage('请上传封面', 'error', 2000)
        return false
      } else {
        const sendData = isEditMode
          ? {
            id: route.query.id,
            ...data
          }
          : data
        console.log(sendData);
        store.dispatch(actionName, sendData).then((data: ResponseType) => {
          if (data.code === '200') {
            createMessage('添加成功，2秒后跳转到作品管理', 'success', 2000)
            setTimeout(() => {
              router.push({ path: '/DataList', query: { type: '2' } })
            }, 2000)
          } else {
            message.error(data.message)
          }
        })
      }
    }

    // 如果是编辑,就设置初始值
    if (route.query.id) {
      store.dispatch('getNewsContentById', { newsId: route.query.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          createMessage('获取文章详情失败', 'error')
          return false
        }

        const currentEditNews = rawData.data
        console.log('currentEditNews', currentEditNews)
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        if (currentEditNews.atlasNewsDtos !== undefined) {
          // 去掉默认的空值
          imageArrRef.pop()
          Array.prototype.push.apply(imageArrRef, currentEditNews.atlasNewsDtos)
        }
        console.log('newsTitle.value-------', currentEditNews, 'newsTitle.value')

        if (currentEditNews.imageUrl && currentEditNews.imageUrl?.length < 1) {
          imageNum.value = 0
        } else if (currentEditNews.imageUrl) {
          imageNum.value = currentEditNews.imageUrl.length
          coverImageArr.value = currentEditNews.imageUrl as string[]
        }
      })
    }
    //预览
    const preview = () => {
      console.log('预览')
      sendData(3)
      console.log(uploadedData);
    }
    // 保存草稿
    const submitDraft = () => {
      sendData(2)
    }

    // 发布
    const submitRelease = () => {
      sendData(1)
    }

    return {
      imageArrRef,
      coverImageArr,
      uploadCheck,
      preview,
      visible,
      previewVisible,
      previewData,
      newsTitle,
      newsColumn,
      newsSource,
      imageNum,
      // 栏目信息
      columns,
      submitDraft,
      submitRelease,
      // 来源
      listNewsSource,
      uploadedData,
      handleFileUploaded,
      addImage,
      delImage,
      handleFileUploadedContent
    }
  }
})
</script>
