<template>
<div>
  <div v-if="hasBar">
    <search-bar :hasDateBar="hasDateBar"></search-bar>
  </div>
  <p v-if="total" class="text-left count">共 <span class="text-primary ">{{total}}</span> 条</p>
  <ul class="list-unstyled row d-flex justify-content-center">
    <slot name="header"></slot>
    <slot></slot>
  </ul>
</div>

</template>
<style scoped>
.text-primary{
  color: black !important;
}
.count{
    font-size: 14px;
    }
.list-unstyled{
    /* margin: 40px 0 0; */
    }
.text-left{
    margin: 0;
    }
.justify-content-center{
    justify-content: left!important;
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
import SearchBar from './SearchBar.vue'
export default defineComponent({
  name: 'MediaList',
  components: {
    SearchBar
  },
  props: {
    total: {
      type: Number,
      require: false
    },
    hasBar: {
      type: Boolean,
      require: false
    },
    hasDateBar: {
      type: Boolean,
      require: false
    }
  }
})
</script>
