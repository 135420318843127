
import { defineComponent } from 'vue'
import createMessage from '../components/createMessage'
export default defineComponent({
  name: 'FansItem',
  props: {
    nickname: { type: String, require: true },
    coverPath: { type: String, require: false },
    status: {type: Boolean, require: true}
  },
  setup () {
    const alert = (msg: string) => {
      createMessage(msg, 'success')
    }
    return {
      alert
    }
  }
})
