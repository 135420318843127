<template>
  <form action="">
<!-- 设置 -->
    <form-input label="用户名" v-model="userInfo.nickname" id="user-name" tips="编辑"></form-input>
    <form-input label="用户签名" v-model="userInfo.sign" id="user-sign" tips="编辑"></form-input>

    <uploader
      label="上传头像"
      tips="编辑"
      id="setting-avatar"
      action="/upload"
      :beforeUpload="uploadCheck"
      @file-uploaded="handleFileUploaded"
      :uploaded="uploadedData"
      class="avatar bg-light text-secondary my-4 rounded-circle d-flex align-items-center justify-content-center"
      style="height:80px;width:80px"
    >
      <!-- <img class="rounded-circle"  style="height:80px;width:80px" v-if="userInfo.avatar" :src="userInfo.avatar" alt=""> -->
      <span v-if="!userInfo.avatar" class="pl-0 ml-0">点击上传</span>
      <template #loading>
        <div>
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </template>
      <template #uploaded="dataProps">
        <div class="uploaded-area">
          <img :src="dataProps.uploadedData.url" style="height:80px;width:80px" class="avatar bg-light text-secondary my-4 rounded-circle d-flex align-items-center justify-content-center">
        </div>
      </template>
    </uploader>

    <form-select v-model="userInfo.sex" label="性别" id="sex">
      <form-option :selected="userInfo.sex===0" value="0" >保密</form-option>
      <form-option :selected="userInfo.sex===1" value="1">男</form-option>
      <form-option :selected="userInfo.sex===2" value="2">女</form-option>
    </form-select>
    <div>
    </div>
    <form-input v-model="userInfo.mobile" label="手机号" id="user-tel" disabled></form-input>

    <form-select label="城市" v-model="userInfo.areaId" id="user-area">
      <form-option :key="area.id" v-for="area in  listLabel.area" :selected="userInfo.areaId===area.id+''" :value="area.id+''" >{{area.name}}</form-option>
    </form-select>
    <form-select label="行业" v-model="userInfo.tradeId" id="user-trade">
      <form-option :key="trade.id" v-for="trade in  listLabel.trade" :selected="userInfo.tradeId===trade.id+''" :value="trade.id+''" >{{trade.name}}</form-option>
    </form-select>
    <form-select label="职位" v-model="userInfo.jobId" id="user-job">
      <form-option :key="job.id" v-for="job in  listLabel.job" :selected="userInfo.jobId===job.id+''" :value="job.id+''">{{job.name}}</form-option>
    </form-select>
    <button type="button" @click="userUpdate" class="btn btn-save">保存</button>
  </form>
</template>
<style scoped>
.btn-save {
  color: #fff;
  background-color: #FF0000;
  border-color: #FF0000;
  width: 100px;
}
  .avatar{
    height: 80px;
  }
  .edit-btn{
    color: #5090FF;
    font-size: 14px;
  }
</style>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import store, { OssResponse } from '../store'
import Uploader from '../components/FormUploader.vue'
import { beforeUploadCheck } from '../helper'
import createMessage from '../components/createMessage'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
// import OSS from 'ali-oss'
export default defineComponent({
  name: 'Settings',
  components: {
    Uploader,
    FormInput,
    FormSelect,
    FormOption
  },
  setup () {
    // 获取最新的用户信息
    store.dispatch('getUserInfo')
    store.dispatch('listLabel')

    const userInfo = computed(() => store.state.user)
    const listLabel = computed(() => store.state.listLabel)

    const uploadedData = ref()
    onMounted(() => {
      if (userInfo.value.avatar) {
        console.log('userInfo', userInfo.value)

        uploadedData.value = { url: userInfo.value.avatar }
      }
    })
    // 图片上传到OSS后的地址
    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, { format: ['image/jpeg', 'image/png'], size: 10 })
      const { passed, error } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 10Mb', 'error')
      }
      return passed
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      // 上传后的OSS的地址
      userInfo.value.avatar = rawData.url
    }

    const userUpdate = () => {
      store.dispatch('userUpdate', userInfo.value).then(() => {
        createMessage('保存成功', 'success', 2000)
      })
    }

    return {
      uploadCheck,
      listLabel,
      userUpdate,
      userInfo,
      uploadedData,
      handleFileUploaded
    }
  }
})
</script>
