
import { computed, defineComponent, ref, watch } from 'vue'
// 粉丝数据概况
import DataFaceHome from './DataFaceHome.vue'
// 粉丝列表
import FansList from './FansList.vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
import { useRoute, useRouter } from 'vue-router'
import store from '../store'
export default defineComponent({
  name: 'NewsData',
  components: {
    DataFaceHome,
    TabNav,
    TabNavItem,
    FansList
  },
  setup () {
   enum TabText {
      DataFaceHome = '概况',
      FansList = '粉丝列表'
    }
   const route = useRoute()
   const router = useRouter()
   const current = route.query.currentTab ? route.query.currentTab : 'DataFaceHome'
   const currentTab = ref(current)
   const tabs = ['DataFaceHome', 'FansList']
   const currentTabComponent = computed(() => currentTab.value)

   watch(currentTab, (current) => {
     console.log('current', current)

     router.push({
       path: route.path,
       query: { ...route.query, currentTab: current }
     })
   })
   return {
     currentTabComponent,
     tabs,
     TabText,
     currentTab
   }
  }
})
