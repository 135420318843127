<template>
  <div class="border">
    <span>排序:</span>
    <select class="border-0">
      <option v-for="item in optionArr" :key="item.value" :value="item.value">{{item.text}}</option>
    </select>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
interface OptionValue {
  text: string;
  value: string;
}
export default defineComponent({
  name: 'SelectItem',
  props: {
    optionArr: {
      type: Array as PropType<OptionValue[]>,
      required: true
    }
  }
})
</script>
