<template>
<nav class="navbar navbar-default content row">
  <div class="col-1 navbar-brand logo">
    <router-link to="/">
      <img style="width:50px;margin-left:30px" alt="Brand" src="../assets/logo.png" />
    </router-link>
    <svg type="menu" v-if="has3" @click="showMenu" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-justify ml-4" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
    </svg>
  </div>
  <div class="navbar-brand nav-item col-2" to="/">

    <div class="dropdown">
      <span class="nickname" @click="toSet">&nbsp;&nbsp;{{nickname}}</span>
      <img @click="toSet" class="rounded-circle avatar" :alt="nickname" :src="userAvatar" />

      <!-- <button class="btn dropdown-toggle d-inline-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="nickname">{{nickname}}</span>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <router-link to="/Settings" class="dropdown-item" href="#">设置</router-link>
        <a class="dropdown-item" @click.prevent="logout" href="#">退出登陆</a>
      </div> -->
    </div>
  </div>
</nav>
</template>
<style scoped>
.avatar{
    width: 40px;
    height: 40px;
    }
.nickname{
    font-size: 18px;
    color: #333333;
    }
.row{
    position:fixed;
    top:0px;
    left:10px;
    z-index: 99;
    width: 100%;
    border-bottom: 1px  #ECECEC solid;
    background-color: #ffffff;
    height: 70px;
    }
.logo{
    padding-left: -30px;
    }
.col-1{
    width: 80px;
    }
.col-2{
    width:calc(100% - 400px);
    }
.dropdown{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    }
.navbar{
    margin-left:-10px;
    }
@media screen and (max-width:414px){
.row{
    left:0
    }
.logo{
    padding-left: 24px;
    }
.nickname{
    display: none;
    }
    }
</style>

<script lang="ts">
import {
  defineComponent
} from 'vue'
import { useRouter } from 'vue-router'
import store from '../store'

export default defineComponent({
  name: 'GlobalHeader',
  props: {
    // 是否含有三道杠
    has3: Boolean,
    nickname: String,
    avatar: String,
    id: String
  },
  setup (props) {
    // 用户头像如果不存在或者网站使用默认图,则作者后台也是用默认图
    const userAvatar = (props.avatar && props.avatar !== '../img/avatar.jpg') ? props.avatar : require('@/assets/avatar_user@2x.png')

    const router = useRouter()
    const logout = () => {
      store.state.token = ''
      store.state.user.isLogin = false
      localStorage.clear()
      router.push('/login')
    }
    const showMenu = () => {
      store.state.showMenu = !store.state.showMenu
    }
    const toSet = () => {
      console.log('ss');
      
        router.push('/Settings')
    }
    return {
      userAvatar,
      store,
      logout,
      showMenu,
      toSet
    }
  }
})
</script>
