
import { defineComponent } from 'vue'
import SearchBar from './SearchBar.vue'
export default defineComponent({
  name: 'MediaList',
  components: {
    SearchBar
  },
  props: {
    total: {
      type: Number,
      require: false
    },
    hasBar: {
      type: Boolean,
      require: false
    },
    hasDateBar: {
      type: Boolean,
      require: false
    }
  }
})
