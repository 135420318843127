
import { defineComponent, ref, onMounted, computed, reactive } from 'vue'
import { beforeUploadCheck } from '../helper'
import { GlobalDataProps, OssResponse, NewsOneDto, ResponseType } from '../store'
import createMessage from '../components/createMessage'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
import Uploader from '../components/FormUploader.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'PostVideo',
  components: {
    FormInput,
    FormSelect,
    FormOption,
    Uploader
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    // 文章标题
    const newsTitle = ref('')
    // 文章封面
    const imageUrl = ref('')
    // 视频地址
    const videoUrl = ref('')
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const router = useRouter()
    const route = useRoute()
    const visible = ref(false)
    const previewVisible = ref(false)
    const isEditMode = !!route.query.id
    // 是修改还是添加
    const actionName = isEditMode ? 'updateNews' : 'createPostNews'

    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)
    const uploadedData = ref()
    //预览内容
    const previewData : NewsOneDto = reactive({
      // 资讯栏目id
        columnId: 0,
        // 资讯内容
        content: '',
        // 资讯标题
        title: '',
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 1,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: '',
        // 资讯来源id
        sourceId: 1,
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: 0
    })
    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })

    if (route.query.id) {
      store.dispatch('getNewsContentById', { newsId: route.query.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          createMessage('获取文章详情失败', 'error')
          return false
        }

        const currentEditNews = rawData.data
        console.log('currentEditNews', currentEditNews)
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        videoUrl.value = currentEditNews.content
        console.log('newsTitle.value-------', currentEditNews, 'newsTitle.value')
        if (currentEditNews.imageUrl !== undefined) {
          imageUrl.value = currentEditNews.imageUrl[0]
        }
        // uploadedData.value = { url: imageUrl.value }
      })
    }

    const afterVisibleChange=()=>{
      if (visible.value) {
        console.log('出现');  
      } else{
        // console.log('消失');
        const video = document.getElementById('vvv') as HTMLVideoElement
        if (video) {
          video.pause()
        }
      }
    }

    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, { format: ['image/jpeg', 'image/png'], size: 10 })
      const { passed, error } = result
      if (error === 'format') {
        message.error('上传图片只能是 JPG/PNG 格式!')
      }
      if (error === 'size') {
        message.error('上传图片大小不能超过 10Mb')
      }
      return passed
    }

    const uploadVideoCheck = (file: File) => {
      const result = beforeUploadCheck(file, { format: ['video/mp4', 'video/mov'], size: 1000 })
      const { passed, error } = result
      if (error === 'format') {
        message.error('上传视频只能是 video/mp4 格式!')
      }
      if (error === 'size') {
        message.error('上传视频大小不能超过 1000Mb')
      }
      return passed
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      // 封面 上传后的OSS的地址
      imageUrl.value = rawData.url
    }

    const handleFileUploadedVideo = (rawData: OssResponse) => {
      // 视频 上传后的OSS的地址
      videoUrl.value = rawData.url
    }

    const sendData = (isPublish: number) => {
      if (newsTitle.value.trim() === '') {
        message.error('标题不能为空')
        return false
      }
      if (videoUrl.value.trim() === '') {
        message.error('视频不能为空')
        return false
      }
      if (imageUrl.value.trim() === '') {
        message.error('封面必须上传')
        return false
      }
      const NewsData: NewsOneDto = {
      // 资讯栏目id
        columnId: parseInt(newsColumn.value),
        // 资讯内容
        content: videoUrl.value,
        // 资讯标题
        title: newsTitle.value,
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 3,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: imageUrl.value,
        // 资讯来源id
        sourceId: parseInt(newsSource.value),
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: isPublish
      }
      if (isPublish == 3) {
        previewVisible.value = true;
        console.log(NewsData);
        previewData.title = NewsData.title
        previewData.content = NewsData.content
        previewData.type = NewsData.type
        previewData.listType = NewsData.listType
        return;
      }
      const sendData = isEditMode ? {
        id: route.query.id,
        ...NewsData
      } : NewsData

      store.dispatch(actionName, sendData).then((data: ResponseType) => {
        if (data.code === '200') {
          createMessage('添加成功，2秒后跳转到作品管理', 'success', 2000)
          setTimeout(() => {
            router.push({ path: '/DataList', query: { type: '3' } })
          }, 2000)
        } else {
          message.error(data.message)
        }
      })
    }
    //预览
    const preview = () => {
      console.log('预览')
      sendData(3)
      console.log(uploadedData);
    }
    // 保存草稿
    const submitDraft = () => {
      sendData(2)
    }

    // 发布
    const submitRelease = () => {
      sendData(1)
    }

    return {
      uploadCheck,
      newsTitle,
      newsColumn,
      newsSource,
      imageUrl,
      videoUrl,
      preview,
      previewData,
      visible,
      previewVisible,
      // 栏目信息
      columns,
      submitDraft,
      submitRelease,
      // 来源
      listNewsSource,
      uploadedData,
      uploadVideoCheck,
      handleFileUploaded,
      handleFileUploadedVideo,
      afterVisibleChange
    }
  }
})
