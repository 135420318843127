<template>
  <div class="main1">
      <h6 class="text-left">概览</h6>
    <chart-header>
      <chart-header-item title="粉丝数" :total="getAuthorNewsHomepageVo.fansNum" :addNum="getAuthorNewsHomepageVo.fansYesterdayChangeNum " addString="昨日" class="col"></chart-header-item>
      <chart-header-item title="总阅读(播放)量" :total="getAuthorNewsHomepageVo.viewNum" :addNum="getAuthorNewsHomepageVo.yesterdayViewNum" addString="昨日阅读(播放)量"></chart-header-item>
      <chart-header-item title="总点赞量" :total="getAuthorNewsHomepageVo.favourNum" :addNum="getAuthorNewsHomepageVo.yesterdayFavourNum" addString="昨日点赞量"></chart-header-item>
      <chart-header-item title="总评论量" :total="getAuthorNewsHomepageVo.commentNum" :addNum="getAuthorNewsHomepageVo.yesterdayCommentNum" addString="昨日评论量"></chart-header-item>
    </chart-header>
    <br>
    <br>
    <h6 class="text-left">数据趋势</h6>
    <br>
    <a-range-picker
      :default-value="[modal[0], modal[1]]"
      :format="dateFormat"
      :mode = "modal"
      :locale="locale"
      @change="changeData"
class="overflow"/>
    <div id="main" ref="chartRef"></div>
  </div>
</template>
<style scoped>
.main1{
    width: auto;
    }
#main{
    width: auto;
    height: 500px;
    z-index: 1000;
    }
.text-left{
    margin: 20px;
    }
@media screen and (max-width: 414px){
.text-left{
    margin-left: 0px;
    }
.col{
    width: calc(100% - 40px);
    }
.main1{
    margin-top: -30px;
    }
.overflow{
    overflow: hidden;
    display: none;
    }
#main{
    margin-top: -40px;
}
    }
</style>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive, toRefs, watch } from 'vue'
import echarts from 'echarts'
import ChartHeader from '../components/ChartHeader.vue'
import ChartHeaderItem from '../components/ChartHeaderItem.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import store from '@/store'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { useRoute } from 'vue-router'
moment.locale('zh-cn')
export default defineComponent({
  name: 'Home',
  components: {
    ChartHeader,
    ChartHeaderItem
  },
  setup () {
    // if (localStorage.getItem('userInfo') === null) {
    //   useRouter().push('/login')
    //   return false
    // }
    const isAuthor = useRoute().query.au
    if (isAuthor !== '1') {
      // 跳转到首页
      window.location.href = '/PPPing/html/index.html'
    }
    const dateFormat = 'YYYY-MM-DD'
    const timeData: any = reactive({
      startTime: moment().subtract(30, 'days').format(dateFormat),
      endTime: moment().format(dateFormat),
      xAxisData: computed(() => {
        const xAxisData = []
        // 图标x轴的数据
        let tempTime: string = timeData.startTime
        while (tempTime !== timeData.endTime) {
          // 从弟凌天开始  一天一天的增加日期,从而动态控制时间轴, 一直到等于最后一天
          xAxisData.push(tempTime)
          tempTime = moment(tempTime).add(1, 'days').format(dateFormat)
        }

        return xAxisData
      })
    })
    const timeDataRef = toRefs(timeData)

    const modal = [timeDataRef.startTime.value, timeDataRef.endTime.value]

    const chartRef = ref<null | HTMLElement>(null)

    // 数据面板修改时间的时候展示流程图
    const changeData = (value: any, dateString: any) => {
      timeDataRef.startTime.value = dateString[0]
      timeDataRef.endTime.value = dateString[1]
      const queryData = { newsId: '', startTime: dateString[0] + ' 00:00:00', endTime: dateString[1] + ' 00:00:00' }
      store.dispatch('getAuthorNewsHomepageVo', queryData)
    }
    const getAuthorNewsHomepageVo = computed(() => store.state.getAuthorNewsHomepageVo)
    // 基于准备好的dom，初始化echarts实例
    onMounted(() => {
      const myChart = echarts.init(chartRef.value as unknown as HTMLCanvasElement)
      watch(getAuthorNewsHomepageVo, () => {
        // 绘制图表
        myChart.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: true,
            data: ['粉丝量', '阅读量', '点赞量', '评论量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeData.xAxisData
          },
          yAxis: {
            type: 'value'
          },
          series: getAuthorNewsHomepageVo.value.series
        })
      })
      const queryData = { newsId: '', startTime: timeDataRef.startTime.value + ' 00:00:00', endTime: timeDataRef.endTime.value + ' 00:00:00' }
      store.dispatch('getAuthorNewsHomepageVo', queryData)
    })
    return {
      chartRef,
      getAuthorNewsHomepageVo,
      changeData,
      dateFormat,
      modal,
      locale
    }
  }
})
</script>
