<template>
  <li class="row mt-2 mb-2 pb-2 border-bottom">
    <div class="col-4 row">
      <img
        v-if="coverPath"
        class="col-3 border border-danger"
        :src="coverPath"
        alt="logo"
        width="100"
      />
      <div class="col-8 text-left d-flex flex-column justify-content-between">
        <h5 class="ml-2 mt-0 mb-1 row text-truncate" style="width:150px;display: inline-block;">{{ title }}</h5>
        <date-time class="ml-2 p-0" :dateTime="dateTime"></date-time>
      </div>
    </div>
    <div class="col align-middle">5571</div>
    <div class="col">39</div>
    <div class="col">7%</div>
    <div class="col">01:08</div>
    <div class="col">5</div>
    <div class="col">2</div>
    <div class="col">查看详情</div>
  </li>
</template>
<style scoped>
.date-time {
  font-size: 14px;
  color: #999999;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue'
import DateTime from './DateTime.vue'
export default defineComponent({
  name: 'MediaItemSimple',
  components: {
    DateTime
  },
  props: {
    title: { type: String, require: true },
    dateTime: { type: String, require: true },
    coverPath: { type: String, require: false }
  }
})
</script>
