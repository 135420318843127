<template>
<li :id="id" class="row mb-2 pb-2 border-bottom" :class="{'active':active}">
  <div style="overflow:hidden"  width="100" v-if="coverPath == '' || coverPath == undefined"  class="col-2 border m-cover">
    <img object-fit='fill' src="../assets/holder.png" >
  </div>
  <div style="overflow:hidden"  width="100" v-else class="col-2 border m-cover">
    <img :src="coverPath">
  </div>
  <div class="col text-left d-flex flex-column justify-content-between">
      <div class="row">
        <div class="d-flex justify-content-between">
          <h5 style="cursor:pointer" @click="showDrawer" class="mt-0 mb-1 col-10" :class="isRow ? 'col-11':''">{{title}}</h5>
          <date-time :dateTime="dateTime" class="date1" :class="isRow ? 'date2':''"></date-time>
        </div>
        <span class="fs-4 status-text ml-2 text-center"
        :class="statusColor"
        >{{statusText}}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span><slot name="countGroup"></slot></span>
        <span><slot name="buttonGroup"></slot></span>
      </div>
  </div>
  <a-drawer
    title="文章详情"
    placement="right"
    :closable="false"
    v-model:visible="visible"
    :after-visible-change="afterVisibleChange"
  >
    <h3 class="title1">{{newsTitle}}</h3>
    <p>类型:
      <span v-for="col in columns" :key="col.id">
        <span v-if="col.id+''==newsColumn">
          {{col.name}}
        </span>
      </span>
    </p>
    <p>
      来源:
      <span v-if="newsSource=='0'">其他来源</span>
      <span v-for="col2 in listNewsSource"
          :key="col2.id">
        <span v-if="col2.id+''==newsSource">
          {{col2.source}}
        </span>
      </span>
    </p>
    <div v-if="currentNews!==undefined && currentNews.type==1" v-html="content+'<style>img{ width: 100%; }</style>'" id="content"></div>
    <el-row v-if="currentNews!==undefined && currentNews.type==2">
      <el-col v-for="(i,index) in currentNews.atlasNewsDtos" :key="i">
          <el-card :body-style="{ padding: '0px' }">
              <img style="width:100%" :src="i.imageUrl" class="image">
              <div style="padding: 14px;">
                  <span>{{i.describe}}</span>
                  <div class="bottom clearfix">
                      <time class="time">{{index+1}}/{{currentNews.atlasNewsDtos.length}}</time>
                  </div>
              </div>
          </el-card>
      </el-col>
    </el-row>
    <el-row v-if="currentNews!==undefined && currentNews.type==3">
        <el-col>
            <el-card>
                <div class="imgbox">
                    <video
                            style="width:100%"
                            :src="currentNews.content"
                            autoplay="autoplay"
                            controls="controls"
                            loop
                    ></video>
                </div>
            </el-card>
        </el-col>
    </el-row>
  </a-drawer>
</li>

<!-- 手机端 -->
<li :id="id" class="row mb-2 pb-2 border-bottom2" :class="{'active':active}">
  <div style="overflow:hidden" v-if="coverPath" width="100"  class="col-2 border m-cover">
    <img :src="coverPath" alt="logo">
  </div>
  <div class="col text-left d-flex flex-column justify-content-between">
      <div class="row">
        <div class="d-flex justify-content-between">
          <h5 style="cursor:pointer" @click="showDrawer" class="mt-0 mb-1 col-10" :class="isRow ? 'col-11':''">{{title}}</h5>
        </div>
        <div class="text-right1">
        <span class="fs-4 status-text text-center  display-inline"
        :class="statusColor"
        >{{statusText}}</span>
        <div class="d-flex justify-content-between display-inline1">
        <span><slot name="buttonGroup"></slot></span>
      </div>
      </div>
      </div>
  </div>
  <div class="date0">
  <span><slot name="countGroup"></slot></span>
  <date-time :dateTime="dateTime" class="date1" :class="isRow ? 'date2':''"></date-time>
  </div>
  <a-drawer
    title="文章详情"
    placement="right"
    :closable="false"
    v-model:visible="visible"
    :after-visible-change="afterVisibleChange"
    class="airtve"
  >
    <h3 class="title1">{{newsTitle}}</h3>
    <p>类型:
      <span v-for="col in columns" :key="col.id">
        <span v-if="col.id+''==newsColumn">
          {{col.name}}
        </span>
      </span>
    </p>
    <p>
      来源:
      <span v-if="newsSource=='0'">其他来源</span>
      <span v-for="col2 in listNewsSource"
          :key="col2.id">
        <span v-if="col2.id+''==newsSource">
          {{col2.source}}
        </span>
      </span>
    </p>
    <div v-if="currentNews!==undefined && currentNews.type==1" v-html="content+'<style>img{ width: 100%; }</style>'" id="content"></div>
    <el-row v-if="currentNews!==undefined && currentNews.type==2">
      <el-col v-for="(i,index) in currentNews.atlasNewsDtos" :key="i">
          <el-card :body-style="{ padding: '0px' }">
              <img style="width:100%" :src="i.imageUrl" class="image">
              <div style="padding: 14px;">
                  <span>{{i.describe}}</span>
                  <div class="bottom clearfix">
                      <time class="time">{{index+1}}/{{currentNews.atlasNewsDtos.length}}</time>
                  </div>
              </div>
          </el-card>
      </el-col>
    </el-row>
    <el-row v-if="currentNews!==undefined && currentNews.type==3">
        <el-col>
            <el-card>
                <div class="imgbox">
                    <video
                            style="width:100%"
                            :src="currentNews.content"
                            autoplay="autoplay"
                            controls="controls"
                            loop
                    ></video>
                </div>
            </el-card>
        </el-col>
    </el-row>
  </a-drawer>
</li>

</template>
<style scoped>
.m-cover{
  height: 108px;
  width:192px;
  padding: 0  !important;
  margin-right: 20px;
  border-radius: 4px !important;
  margin-bottom:10px;
  overflow: visible !important;
  }
.active{
  background-color: #FAFAFA;
  border-right: 1px solid #5090FF!important;
  }
.fs-4{
  font-size: 14px;
  width: 100px !important;
  margin-top:4px!important;
  margin-bottom:4px!important;
  }
img{
  width: 100%!important;
  height: 120px!important;
 }
.col-10{
  width: calc(100% - 160px);
  word-break:break-all;
  word-wrap:break-word;
  white-space:pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  }
/* 背景 */
.ml-2{
  margin-left: 12px!important;
  line-height: 24px;
  padding: 0;
  }
/* 已发布 */
.published-text{
  background-color: #bdffd7;
  color: #08e15d;
  }
/* 审核中 */
.loading-text{
  background-color: #f6ffbd;
  color: #e1b008;
  }
/* 未通过 */
.no-text{
  background-color: #ffbdbd;
  color: #e10808;
  }
/* 草稿 */
.prepare-text{
  background-color: #f5f5f5;
  color: #999999;
  }
.date-time{
  font-size: 14px;
  color:#999999
  }
.text-left{
  margin: 0;
  }
.pb-2{
  margin: 0;
  padding: 0;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  }
.justify-content-between{
  margin: 0;
  padding: 0;
  font-size: 16px;
  }
.status-text{
  margin: 0;
  padding: 0;
  }
.mt-0{
  font-size: 16px;
  line-height: 24px;
  margin:0  0 0 12px!important;
  }
.border{
  border: 0 red solid !important;
  }
.mb-2{
  margin-bottom: 0px !important;
  }
.date1{
  width: 160px;
  text-align: right;
  }
.col-11{
  width: calc(100% - 20px) !important;
  padding-right: 10px;
  }
.date2{
  display: none;
  overflow: hidden;
  width: 20px;
  }
.title1{
  font-size: 18px;
  }
@media screen and (max-width: 1400px){
  .mb-2{
  margin-bottom: 0px !important;
  }
  }
@media screen and (max-width: 1280px){
.m-cover{
  height: 108px;
  width:108px;
  }
  }
@media screen and (min-width: 415px){
.border-bottom2{
  display: none;
  }
  }
@media screen and (max-width: 414px){
.border-bottom{
    display: none;
    }
.m-cover img{
    width: 100%;
    }
.text-right1{
    display:flex;
    flex-direction: row;
    margin: 4px 0;
    justify-content: space-between;
    }
.col-10{
    width: calc(100% - 24px);
    }
.display-inline{
    padding-left: 0;
    }
.col-2{
    width: 108px;
    height: 72px;
    }
.date0{
    display:flex;
    flex-direction:row;
    padding: 0;
    justify-content: space-between;
    }
.ml-2{
    margin-left:0!important ;
    }
.date1{
    margin-right: 0!important;
    width: 132px;
    }
.border-bottom2{
    border-bottom:1px solid #dee2e6!important;
    }
.date2{
    display: inherit;
    }
.active{
    background-color: #FAFAFA;
    border-right: none!important;
    }
}
@media screen and (max-width: 360px) {
/* 草稿 */
.prepare-text{
    background-color: #f5f5f5;
    color: #999999;
    /* width: 60px!important; */
    font-size: 12px;
    }
/* 已发布 */
.published-text{
    background-color: #bdffd7;
    color: #08e15d;
    /* width: 60px!important; */
    font-size: 12px;
    }
/* 审核中 */
.loading-text{
    background-color: #f6ffbd;
    color: #e1b008;
    font-size: 12px;
    }
/* 未通过 */
.no-text{
    background-color: #ffbdbd;
    color: #e10808;
    font-size: 12px;
    }
    }
@media screen and (max-width: 350px){
.date1{
    font-size: 12px;
    width: 115px;
    padding-top: 4px!important;
    }
.m-cover{
    margin-right: 10px;
    }
    }
@media screen and (max-width: 320px){
/* 草稿 */
.prepare-text{
    background-color: #f5f5f5;
    color: #999999;
    /* width: 40px!important; */
    font-size: 12px;
    }
/* 已发布 */
.published-text{
    background-color: #bdffd7;
    color: #08e15d;
    width: 40px!important;
    font-size: 12px;
    }
/* 审核中 */
.loading-text{
    background-color: #f6ffbd;
    color: #e1b008;
    font-size: 12px;
    }
/* 未通过 */
.no-text{
    background-color: #ffbdbd;
    color: #e10808;
    font-size: 12px;
    }
    }
</style>
<script lang="ts">
import store, { NewsOneDto, ResponseType } from '@/store'
import { message } from 'ant-design-vue'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import DateTime from './DateTime.vue'
export default defineComponent({
  name: 'MediaItem',
  components: {
    DateTime
  },
  props: {
    title: { type: String, require: true },
    id: { type: String, require: true },
    dateTime: { type: String, require: true },
    coverPath: { type: String, require: false },
    active: { type: Boolean, requried: false },
    // 审核状态
    status: { type: Number, require: true },
    isRow: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const visible = ref(false)
    enum ExamineStatus{
      草稿 = 1,
      等待主编审核 =2,
      主编审核中 = 3,
      主编审核未通过=4,
      等待政审=5,
      政审中=6,
      政审未通过=7,
      等待总编审核=8,
      总编审核中=9,
      总编审核未通过=10,
      通过=11,
      发布=12
    }
    const statusText = ref<string>(ExamineStatus[1])
    statusText.value = ExamineStatus[1]
    if (props.status) {
      statusText.value = ExamineStatus[props.status]
    }

    const statusColor = ref('prepare-text')
    if (props.status === 12) {
      statusColor.value = 'published-text'
    } else if (props.status === 3 || props.status === 6 || props.status === 9) {
      statusColor.value = 'loading-text'
    } else if (props.status === 4 || props.status === 7 || props.status === 10) {
      statusColor.value = 'no-text'
    }

    watch(() => props.status, (status) => {
      if (status) {
        statusText.value = ExamineStatus[status]
        if (status === 12) {
          statusColor.value = 'published-text'
        } else if (status === 3 || status === 6 || status === 9) {
          statusColor.value = 'loading-text'
        } else if (status === 4 || status === 7 || status === 10) {
          statusColor.value = 'no-text'
        }
      }
    })
    const newsTitle = ref('')
    // 封面数量
    const imageNum = ref(1)
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const content = ref('')
    const newsType = ref()
    const currentNews = ref<NewsOneDto|null>()

    // e true 显示 false 关闭
    const afterVisibleChange = (e: boolean) => {
      if (!e && currentNews.value) {
        currentNews.value.content = ''
      }
    }
    // 文章封面
    const coverImageArr: string[] = []
    const showDrawer = () => {
      store.dispatch('getNewsContentById', { newsId: props.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          message.error('获取文章详情失败')
          return false
        }
        currentNews.value = rawData.data
        const currentEditNews = rawData.data
        content.value = currentEditNews.content
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        newsType.value = currentEditNews.type
        if (currentEditNews.imageUrl && currentEditNews.imageUrl?.length < 1) {
          imageNum.value = 0
        } else if (currentEditNews.imageUrl) {
          imageNum.value = currentEditNews.imageUrl.length
          Array.prototype.push.apply(coverImageArr, currentEditNews.imageUrl as string[])
          // uploadedData.value = { url: coverImageArr[0] }
        }
        visible.value = true
      })
    }
    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)
    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })
    return {
      currentNews,
      afterVisibleChange,
      newsTitle,
      columns,
      listNewsSource,
      content,
      newsColumn,
      newsSource,
      statusText,
      visible,
      showDrawer,
      statusColor
    }
  }
})
</script>
