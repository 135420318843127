
import { defineComponent } from 'vue'
import FormGlobal from './FormGlobal.vue'
export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    tips: {
      type: String,
      required: false
    }
  },
  components: {
    FormGlobal
  }
})
