<template>
<div>
  <!-- 预览的地方 -->
  <div v-if="false" class="iphone6s border border-primary">
    <h6>{{newsTitle}}</h6>
    <span>类型:{{newsColumn}}</span>
    <p>来源:{{newsSource}}</p>
  </div>
  <!-- 预览的地方 -->

  <div class="content">
    <form-input v-model="newsTitle"  label="标题" id="news-title"></form-input>
    <uploader
      :defaultImage="videoUrl"
      label="上传视频"
      id="news-video"
      action="/upload"
      tips="点击上传(<10Mb)"
      :beforeUpload="uploadVideoCheck"
      @file-uploaded="handleFileUploadedVideo"
      :uploaded="uploadedData"
      class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
      style="height:105px;width:150px;margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;"
    >
      <span class="pl-2">点击上传</span>
      <template #loading>
        <div>
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </template>
      <template #uploaded="dataProps">
        <div class="uploaded-area">
          <video  style="height:105px;width:150px" class="avatar bg-light text-secondary my-4  d-flex align-items-center justify-content-center" controls>
              <source :src="dataProps.uploadedData.url" type="video/mp4">
              <source :src="dataProps.uploadedData.url" type="video/ogg">
              您的浏览器不支持 video 标签。
          </video>
        </div>
      </template>
    </uploader>
    <div class="tips">（为了获得更高的推荐量和点击量，建议上传720p（1280x720）或更高分辨率的视频，大小不超过8G）</div>
    <uploader
      label="展示封面"
      id="news-cover"
      action="/upload"
      tips="点击上传"
      :defaultImage="imageUrl"
      :beforeUpload="uploadCheck"
      @file-uploaded="handleFileUploaded"
      :uploaded="uploadedData"
      class="avatar bg-light text-secondary my-4  d-flex align-items-center justify-content-center"
       style="height:105px;width:150px;margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;"
    >
      <span class="pl-2">点击上传</span>
      <template #loading>
        <div>
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </template>
      <template #uploaded="dataProps">
        <div class="uploaded-area">
          <img :src="dataProps.uploadedData.url" class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center">
        </div>
      </template>
    </uploader>
    <form-select label="类型" v-model="newsColumn" id="news-type">
      <FormOption v-for="col in columns" :key="col.id" :value="col.id+''">{{col.name}}</FormOption>
    </form-select>
    <form-select v-model="newsSource" label="来源网址" id="news-source">
      <FormOption v-for="col2 in listNewsSource" :key="col2.id" :value="col2.id+''">{{col2.source}}</FormOption>
    </form-select>
    <div class="operate">
    <button class="col-2 btn btn-light preview" @click.prevent="preview">预览</button>
    <button class="col-2 btn btn-light mr-4" @click.prevent="submitDraft">存草稿</button>
    <button class="col-2 btn btn-primary " @click.prevent="submitRelease">发布</button>
  </div>
  </div>
  <a-drawer
    title="文章详情"
    placement="right"
    :closable="false"
    v-model:visible="visible"
    :after-visible-change="afterVisibleChange"
  >
    <h3 class="title1">{{previewData.title}}</h3>
    <p>
      来源:
      <span v-if="newsSource=='0'">其他来源</span>
      <span v-for="col2 in listNewsSource"
          :key="col2.id">
        <span v-if="col2.id+''==newsSource">
          {{col2.source}}
        </span>
      </span>
    </p>
    <el-row v-if="previewData!==undefined && previewData.type==3">
        <el-col>
            <el-card>
                <div class="imgbox">
                    <video id="vvv"
                            style="width:100%"
                            :src="previewData.content"
                            controls="controls"
                    ></video>
                </div>
            </el-card>
        </el-col>
    </el-row>
  </a-drawer>
  <div v-if="previewVisible" class="preview-container">
    <img class="close" @click="previewVisible=false" src="../assets/X.png" alt="">
    <div class="preview-mobile">
      <!-- <span class="preview-title">标题在哪里</span>   -->

      <h3 class="title1">{{previewData.title}}</h3>
      <div class="source">
        来源:
        <span v-if="newsSource=='0'">其他来源</span>
        <span v-for="col2 in listNewsSource"
            :key="col2.id">
          <span v-if="col2.id+''==newsSource">
            {{col2.source}}
          </span>
        </span>
      </div>
      <el-row v-if="previewData!==undefined && previewData.type==3">
        <el-col>
            <el-card>
                <div class="imgbox">
                    <video id="vvv"
                            style="width:100%"
                            :src="previewData.content"
                            controls="controls"
                    ></video>
                </div>
            </el-card>
        </el-col>
    </el-row>
    </div>
    <!-- <span>标题在哪里</span> -->
  </div>
</div>
</template>
<style scoped>
.title1{
  text-align: left;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
}
.source {
  text-align: left;
  margin-top: 10px;
}
.btn-primary {
  background-color: #FF5E5E !important;
}
.operate {
  margin-top: 50px;
}
.preview-content{
  line-height: 30px;
  margin-top: 12px;
    /* height: 70%; */
    /* padding-bottom: 100px; */
    overflow: scroll;
    text-align: justify;
    font-size: 18px;
}
.preview-content::-webkit-scrollbar { width: 0 !important }
.preview-mobile {
  /* background: red; */
  /* background-image: url('../assets/mobile.png'); */
  background-image: url('../assets/mobile.png');
  background-size: 100% 100%;
  background-position: center;
  width: 280px;
  height: 568px;
  text-align: inherit;
  margin: 0 auto;
  margin-top: 60px;
  padding: 76px 14px 40px 14px;
}
.close {
  /* text-align: initial; */
  position: absolute;
  right: 40px;
  top: 30px;
  width: auto;
}
.preview-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  background-color: rgba(0,0,0,0.62);
  /* color: white; */
  z-index: 1000;
}
.btn-primary {
    background-color: #FF5E5E;
    border-color: #FF5E5E;
}
.operate {
  margin-top: 50px;
}
.uploaded-area img {
  width: 150px;
  height: 105px;
}
@media screen and (max-width:414px){
.uploaded-area img{
    width:100px!important;
    height: 105px;
  }
}
@media screen and (width:414px){
.uploaded-area img{
    width:110px!important;
    height: 105px;
  }
}
@media screen and (width:320px){
.uploaded-area img{
    width:88px!important;
    height: 105px;
  }
}
.tips {
  text-align: left;
  color: #999;
  font-size: 12px;
}
.preview{
  margin-right: 59px;
}
.iphone6s{
    width: 375px;
    height: 667px;
    }
.content{
    position:static;
    background-color:#ffffff;
    }
.btn-light{
    border: 1px solid rgb(228, 228, 228);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-top: -66px;
    margin-left: -50px;
    }
.btn-light:hover{
    border: 1px solid #999;
    margin-left: -50px;
    background-color: #f8f9fa;
    margin-top: -66px;
    }
.btn-primary{
    border: 1px solid rgba(10, 31, 228, 0);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-left: -10px;
    margin-top: -66px;
    }
.btn-primary:hover{
    border: 1px solid rgba(10, 31, 228, 0);
    margin-left: -10px;
    margin-top: -66px;
    }
@media screen and (max-width:414px){
.btn-1{
    padding:40px 0 0 64px!important;
    }
#news-title{
    margin: 0;
    padding: 0;
    }
.pl-2{
    width:150px;
    height: 60px;
    margin-top: 32px;
    display: block;
    padding-left:0!important ;
    }
    }
</style>
<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive } from 'vue'
import { beforeUploadCheck } from '../helper'
import { GlobalDataProps, OssResponse, NewsOneDto, ResponseType } from '../store'
import createMessage from '../components/createMessage'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
import Uploader from '../components/FormUploader.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'

export default defineComponent({
  name: 'PostVideo',
  components: {
    FormInput,
    FormSelect,
    FormOption,
    Uploader
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    // 文章标题
    const newsTitle = ref('')
    // 文章封面
    const imageUrl = ref('')
    // 视频地址
    const videoUrl = ref('')
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const router = useRouter()
    const route = useRoute()
    const visible = ref(false)
    const previewVisible = ref(false)
    const isEditMode = !!route.query.id
    // 是修改还是添加
    const actionName = isEditMode ? 'updateNews' : 'createPostNews'

    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)
    const uploadedData = ref()
    //预览内容
    const previewData : NewsOneDto = reactive({
      // 资讯栏目id
        columnId: 0,
        // 资讯内容
        content: '',
        // 资讯标题
        title: '',
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 1,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: '',
        // 资讯来源id
        sourceId: 1,
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: 0
    })
    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })

    if (route.query.id) {
      store.dispatch('getNewsContentById', { newsId: route.query.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          createMessage('获取文章详情失败', 'error')
          return false
        }

        const currentEditNews = rawData.data
        console.log('currentEditNews', currentEditNews)
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        videoUrl.value = currentEditNews.content
        console.log('newsTitle.value-------', currentEditNews, 'newsTitle.value')
        if (currentEditNews.imageUrl !== undefined) {
          imageUrl.value = currentEditNews.imageUrl[0]
        }
        // uploadedData.value = { url: imageUrl.value }
      })
    }

    const afterVisibleChange=()=>{
      if (visible.value) {
        console.log('出现');  
      } else{
        // console.log('消失');
        const video = document.getElementById('vvv') as HTMLVideoElement
        if (video) {
          video.pause()
        }
      }
    }

    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, { format: ['image/jpeg', 'image/png'], size: 10 })
      const { passed, error } = result
      if (error === 'format') {
        message.error('上传图片只能是 JPG/PNG 格式!')
      }
      if (error === 'size') {
        message.error('上传图片大小不能超过 10Mb')
      }
      return passed
    }

    const uploadVideoCheck = (file: File) => {
      const result = beforeUploadCheck(file, { format: ['video/mp4', 'video/mov'], size: 1000 })
      const { passed, error } = result
      if (error === 'format') {
        message.error('上传视频只能是 video/mp4 格式!')
      }
      if (error === 'size') {
        message.error('上传视频大小不能超过 1000Mb')
      }
      return passed
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      // 封面 上传后的OSS的地址
      imageUrl.value = rawData.url
    }

    const handleFileUploadedVideo = (rawData: OssResponse) => {
      // 视频 上传后的OSS的地址
      videoUrl.value = rawData.url
    }

    const sendData = (isPublish: number) => {
      if (newsTitle.value.trim() === '') {
        message.error('标题不能为空')
        return false
      }
      if (videoUrl.value.trim() === '') {
        message.error('视频不能为空')
        return false
      }
      if (imageUrl.value.trim() === '') {
        message.error('封面必须上传')
        return false
      }
      const NewsData: NewsOneDto = {
      // 资讯栏目id
        columnId: parseInt(newsColumn.value),
        // 资讯内容
        content: videoUrl.value,
        // 资讯标题
        title: newsTitle.value,
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 3,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: imageUrl.value,
        // 资讯来源id
        sourceId: parseInt(newsSource.value),
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: isPublish
      }
      if (isPublish == 3) {
        previewVisible.value = true;
        console.log(NewsData);
        previewData.title = NewsData.title
        previewData.content = NewsData.content
        previewData.type = NewsData.type
        previewData.listType = NewsData.listType
        return;
      }
      const sendData = isEditMode ? {
        id: route.query.id,
        ...NewsData
      } : NewsData

      store.dispatch(actionName, sendData).then((data: ResponseType) => {
        if (data.code === '200') {
          createMessage('添加成功，2秒后跳转到作品管理', 'success', 2000)
          setTimeout(() => {
            router.push({ path: '/DataList', query: { type: '3' } })
          }, 2000)
        } else {
          message.error(data.message)
        }
      })
    }
    //预览
    const preview = () => {
      console.log('预览')
      sendData(3)
      console.log(uploadedData);
    }
    // 保存草稿
    const submitDraft = () => {
      sendData(2)
    }

    // 发布
    const submitRelease = () => {
      sendData(1)
    }

    return {
      uploadCheck,
      newsTitle,
      newsColumn,
      newsSource,
      imageUrl,
      videoUrl,
      preview,
      previewData,
      visible,
      previewVisible,
      // 栏目信息
      columns,
      submitDraft,
      submitRelease,
      // 来源
      listNewsSource,
      uploadedData,
      uploadVideoCheck,
      handleFileUploaded,
      handleFileUploadedVideo,
      afterVisibleChange
    }
  }
})
</script>
