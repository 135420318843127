<template>
<span class="mr-4 list-btn" :class="{'disabled':disabled}">
  <slot></slot>
</span>
</template>
<style scoped>
.list-btn{
    font-size: 13px;
    color: #333;
    margin: 0px;
    }
.mr-4{
    margin: 0 50 0 30px !important;
    cursor:pointer !important;
    }
.disabled{
    color: #999;
    }
@media screen  and (max-width: 1280px){
.mr-4{
    margin: 0 0 0 4px !important;
    cursor:pointer !important;
    }
    }
@media screen and (max-width: 360px){
.mr-4{
    margin: 0 0 0 4px !important;
    cursor:pointer !important;
    font-size: 12px;
    }
}
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ButtonItem',
  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  }
})
</script>
