
import { defineComponent, isRef, PropType, ref } from 'vue'
import ButtonItem from './ButtonItem.vue'
import DateTime from './DateTime.vue'
import store, { AuthorCommentDto } from '../store'
import createMessage from './createMessage'
export default defineComponent({
  name: 'CommentItem',
  components: {
    ButtonItem,
    DateTime
  },
  props: {
    // 是否点赞
    isFavour: {
      type: Boolean,
      required: false
    },
    // 是否是粉丝
    isFans: {
      type: Boolean,
      required: false
    },
    // 昵称
    nickname: {
      type: String,
      required: true
    },
    // 是否是详情（是否要展开回复框）
    isDetail: {
      type: Boolean,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    },
    sendData: {
      type: Object as PropType<AuthorCommentDto>,
      required: true
    },
    // 发布时间
    dateTime: {
      type: String,
      required: true
    },
    // 评论内容
    content: {
      type: String,
      required: true
    },
    // 用户头像
    avatar: {
      type: String,
      required: true
    },
    // 新闻标题
    newsTitle: {
      type: String,
      required: true
    }
  },
  emits: ['list-review', 'update-right-list'],
  setup (props, context) {
    // 对没有头像的给予默认头像
    const userAvatar = (props.avatar && props.avatar !== '../img/avatar.jpg') ? props.avatar : require('@/assets/avatar_user@2x.png')

    // 是否展示文本框
    const displayTextArea = ref(false)
    const toggleTextArea = () => {
      console.log('sssfff');
      
      if (props.isDetail) {
        displayTextArea.value = !displayTextArea.value
      } else {
        context.emit('list-review', props.sendData)
      }
    }

    const del = () => {
      let id: string
      if (isRef(props.sendData.id)) {
        id = props.sendData.id.value as string
      } else {
        id = props.sendData.id
      }

      store.dispatch('adminCommentDelNews', { id: id }).then((data) => {
        if (data.code === '200') {
          context.emit('update-right-list')
        }
      })
    }

    const favour = () => {
      console.log('favour')
      let id: string
      if (isRef(props.sendData.id)) {
        id = props.sendData.id.value as string
      } else {
        id = props.sendData.id
      }
      if (props.isFavour) {
        store.dispatch('newsCommentFavourCancel', { targetId: id }).then((data) => {
          if (data.code === '200') {
            context.emit('update-right-list')
          }
        })
      } else {
        store.dispatch('newsCommentFavourInsert', { targetId: id }).then((data) => {
          if (data.code === '200') {
            context.emit('update-right-list')
          }
        })
      }
    }

    const content2 = ref('')

    // 回复评论
    const sendComment = () => {
      console.log('点击了确定')
      if (content2.value.trim() === '') {
        createMessage('内容不能为空', 'error')
        return false
      }

      if (isRef(props.sendData.id)) {
        store.dispatch('commentSendNews', { content: content2.value, targetId: props.sendData.id.value })
          .then((data) => {
            if (data.code === '200') {
              context.emit('update-right-list')
              content2.value = ''
            }
          })
      }
    }
    return {
      userAvatar,
      content2,
      sendComment,
      del,
      favour,
      displayTextArea,
      toggleTextArea
    }
  }
})
