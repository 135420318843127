
import { defineComponent } from 'vue'
import SelectItem from './SelectItem.vue'
import PCheckBox from './PCheckBox.vue'
export default defineComponent({
  name: 'CommentFilterBar',
  components: {
    SelectItem,
    PCheckBox
  }
})
