<template>
  <div class="accordion" :id="menuParentId">
    <menu-parent :active="$route.path==='/?au=1'" class="left"  title="主页" :dataParent="menuParentId" menuItemId="z1">
      <menu-item>
        <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'"  to="/?au=1" class="p-3 pl-4 nav-link link-dark">主页</router-link>
      </menu-item>
    </menu-parent>
    <menu-parent  :active="$route.path==='/PostImageText' || $route.path==='/PostVideo' || $route.path==='/PostImageArr' || $route.path==='/PostProject'" class="left"   title="创作" :dataParent="menuParentId" menuItemId="z2">
      <menu-item>
        <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/PostImageText" class="p-3 pl-4 nav-link link-dark">图文</router-link>
        <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/PostVideo" class="p-3 pl-4 nav-link link-dark">视频</router-link>
        <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/PostImageArr" class="p-3 pl-4 nav-link link-dark">图集</router-link>
        <!-- <router-link :active-class="'text-primary bg-light'" to="/PostProject" class="p-3 pl-4 nav-link link-dark">项目</router-link> -->
      </menu-item>
    </menu-parent>
    <menu-parent  :active="$route.path==='/DataList' || $route.path==='/CommentListAll'" class="left"   title="管理" :dataParent="menuParentId" menuItemId="z3">
      <menu-item>
        <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/DataList" class="p-3 pl-4 nav-link link-dark">作品管理</router-link>
        <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/CommentListAll" class="p-3 pl-4 nav-link link-dark">评论管理</router-link>
      </menu-item>
    </menu-parent>
    <menu-parent  class="left"   title="数据" :active="$route.path==='/DataFaceAll'||$route.path==='/FansData'" :dataParent="menuParentId" menuItemId="z4">
        <menu-item>
          <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/DataFaceAll" class="p-3 pl-4 nav-link link-dark">作品数据</router-link>
          <router-link @click="store.state.showMenu=false" :active-class="'text-primary bg-light'" to="/FansData" class="p-3 pl-4 nav-link link-dark">粉丝数据</router-link>
        </menu-item>
    </menu-parent>
    <menu-parent title="设置" :active="$route.path==='/Settings'" :dataParent="menuParentId" menuItemId="z5">
        <menu-item>
          <router-link :active-class="'text-primary bg-light'" to="/Settings" class="p-3 pl-4 nav-link link-dark">设置</router-link>
        </menu-item>
    </menu-parent>
  </div>
</template>
<style scoped>
.bg-light{
  background-color: #fff !important;
}
.text-primary{
  color: #CF000D !important;
}
#accordionExample{
    width: 240px;
    height: 930px;
    background:#fff;
    margin:0;
    margin-top: 70px;
    position: fixed;
    top:0;
    left:0;
    z-index: 1000;
    border-right: 1px solid rgb(247, 247, 247);
    }
.left{
    border: 0px solid rgb(247, 247, 247);
    }
.pl-4{
    padding-left: 16px !important;
    }
@media screen  and (max-width: 1280px){
.accordion{
    z-index: 0;
    }
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
import MenuParent from './MenuParent.vue'
import MenuItem from './MenuItem.vue'

export default defineComponent({
  name: 'GlobalMenu',
  components: {
    MenuParent,
    MenuItem
  },
  setup () {
    const menuParentId = 'accordionExample'
    return {
      menuParentId
    }
  }
})
</script>
