
import { defineComponent, ref, onMounted, computed, watch, reactive, toRefs } from 'vue'
import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import TabNavItem from '../components/TabNavItem.vue'
import TabNav from '../components/TabNav.vue'
import MediaList from '../components/MediaList.vue'
import MediaItem from '../components/MediaItem.vue'
import CountItem from '../components/CountItem.vue'
import ButtonItem from '../components/ButtonItem.vue'
import ModalWindow from '../components/ModalWindow.vue'
import SearchBar from '../components/SearchBar.vue'
import ChartHeader from '../components/ChartHeader.vue'
import ChartHeaderItem from '../components/ChartHeaderItem.vue'
import echarts from 'echarts'
import store, { NewsOneDto, NewsInteractionReq, NewsInteraction, ProjectDto, ProjectDataVo, ProjectDataOneVo } from '../store'
import { useRouter, useRoute } from 'vue-router'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default defineComponent({
  name: 'DataList',
  components: {
    TabNav,
    TabNavItem,
    SearchBar,
    MediaList,
    MediaItem,
    CountItem,
    ButtonItem,
    ChartHeader,
    ChartHeaderItem,
    ModalWindow
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const currentData = ref('flow')
    const total = ref(0)
    const modalVar = toRefs(reactive({
      showNum: 0,
      viewsNum: 0,
      commentNum: 0,
      clickNum: 0,
      collectNum: 0,
      allShowNum: 0,
      allViewNum: 0,
      allClickNum: 0,
      fanceShowNum: 0,
      fanceViewNum: 0,
      favourNum: 0,
      viewNum: 0,
      cover: '',
      title: '',
      status: 12,
      createTime: '',
      id: ''
    }))

    const reqParam = reactive(
      {
      // 请求的参数
        actionName: route.query.actionName ? route.query.actionName : 'authorNewsList',
        // 选择的文章类型,图文,图集等
        type: Number(route.query.type) || 1,
        // 每一页显示的数量
        limit: Number(route.query.limit) || 10,
        // 第几页
        page: 0,
        // 审核状态的筛选
        examineStatusOne: Number(route.query.examineStatusOne) || 101,
        // 开始时间
        startGmtCreate: route.query.startGmtCreate || '2000-11-16 00:00:00',
        // 结束时间
        endGmtCreate: route.query.endGmtCreate || '3020-11-16 00:00:00',
        // 搜索关键字
        title: route.query.title || ''
      }
    )

    // 展示新闻还是展示 项目
    const displayList = ref('news')

    watch(reqParam, (data) => {
      store.dispatch(data.actionName as string, data).then((data) => {
        if (data.code === '200') {
          total.value = data.data.total
          document.body.scrollTop = 0
        }
      })

      if (data.type === 6) {
        displayList.value = 'project'
      } else {
        displayList.value = 'news'
      }

      router.push({
        path: route.path,
        query: reqParam
      })
    })

    // 初始化
    reqParam.page = 1
    reqParam.type = Number(route.query.type) || 1

    // 设置当前文章
    const setCurrent = (news: any) => {
      console.log('sss',news);
      
      modalVar.title.value = news.title
      modalVar.status.value = parseInt(news.examineStatusOne)
      modalVar.cover.value = news.imageUrl[0]
      modalVar.createTime.value = news.gmtCreate
      modalVar.showNum.value = news.showNum
      modalVar.collectNum.value = news.collectNum ?? 0
      modalVar.viewsNum.value = news.viewsNum
      modalVar.commentNum.value = news.commentNum
      modalVar.id.value = news.id
      modalVar.allViewNum.value = news.allViewNum
      modalVar.allShowNum.value = news.allShowNum
      modalVar.allClickNum.value = news.allClickNum ?? 0
      modalVar.fanceShowNum.value = news.fanceShowNum
      modalVar.fanceViewNum.value = news.fanceViewNum
    }

    // 设置当前文章
    const setCurrentProject = (project: any) => {
      modalVar.title.value = project.projectName
      modalVar.status.value = 12
      modalVar.cover.value = project.projectImageUrl
      modalVar.createTime.value = project.createTime
      modalVar.showNum.value = project.showNum
      modalVar.collectNum.value = project.collectNum
      modalVar.viewsNum.value = project.viewsNum
      modalVar.commentNum.value = 0
      modalVar.id.value = project.projectId
    }

    const dateFormat = 'YYYY-MM-DD'
    const timeData: any = reactive({
      startTime: moment().subtract(30, 'days').format(dateFormat),
      endTime: moment().format(dateFormat),
      xAxisData: computed(() => {
        const xAxisData = []
        // 图标x轴的数据
        let tempTime: string = timeData.startTime
        while (tempTime !== timeData.endTime) {
          // 从弟凌天开始  一天一天的增加日期,从而动态控制时间轴, 一直到等于最后一天
          xAxisData.push(tempTime)
          tempTime = moment(tempTime).add(1, 'days').format(dateFormat)
        }

        return xAxisData
      })
    })

    const timeDataRef = toRefs(timeData)

    const modal = [timeDataRef.startTime.value, timeDataRef.endTime.value]

    const getNewsInteractionData = computed(() => store.state.getNewsInteraction)
    const listNewsDataFlow = computed(() => store.state.listNewsDataFlow)

    const getProjectInteractionData = computed(() => store.state.getProjectInteractionData)
    const getProjectFlowData = computed(() => store.state.getProjectFlowData)

    // 文章数据面板
    const getNewsInteraction = (data: any) => {
      console.log('data', data)
      const queryData: NewsInteractionReq = { newsId: modalVar.id.value, startTime: timeData.startTime + ' 00:00:00', endTime: timeData.endTime + ' 00:00:00' }
      store.dispatch('getNewsInteraction', queryData)
      store.dispatch('listNewsDataFlow', queryData)
    }

    // 文章数据面板
    const getProjectInteraction = (data: any) => {
      const queryData: ProjectDataOneVo = { projectId: data.projectId, startTime: timeData.startTime + ' 00:00:00', endTime: timeData.endTime + ' 00:00:00' }

      store.dispatch('getProjectInteractionData', queryData)
      store.dispatch('getProjectFlowData', queryData)
    }

    const getInteraction = (data: any) => {
      if (displayList.value === 'news') {
        getNewsInteraction(data)
      } else {
        getProjectInteraction(data)
      }
    }

    // 数据面板修改时间的时候展示流程图
    const changeData = (value: any, dateString: any) => {
      timeDataRef.startTime.value = dateString[0]
      timeDataRef.endTime.value = dateString[1]
      if (displayList.value === 'news') {
        const queryData: NewsInteractionReq = { newsId: modalVar.id.value, startTime: dateString[0] + ' 00:00:00', endTime: dateString[1] + ' 00:00:00' }
        store.dispatch('getNewsInteraction', queryData)
        store.dispatch('listNewsDataFlow', queryData)
      } else {
        const queryData: ProjectDataOneVo = { projectId: modalVar.id.value, startTime: dateString[0] + ' 00:00:00', endTime: dateString[1] + ' 00:00:00' }
        store.dispatch('getProjectInteraction', queryData)
        store.dispatch('getProjectFlowData', queryData)
      }
    }

    // 搜索功能
    const goSearch = (timeArr: Array<string>, title: string) => {
      if (timeArr) {
        if (timeArr[0] == '') {
          timeArr[0]='2000-11-16'
        }
        if (timeArr[1] == '') {
          timeArr[1]='3020-11-16'
        }
        reqParam.startGmtCreate = timeArr[0] + ' 00:00:00'
        reqParam.endGmtCreate = timeArr[1] + ' 00:00:00'
      }
      reqParam.title = title
    }

    /**
     * 文章列表切换
     * status 文章状态
     * action 请求文章的地址
     */
    const changeStatus = (status: number, action: string) => {
      reqParam.examineStatusOne = status
      reqParam.actionName = action
    }

    const chartRef = ref<null | HTMLElement>(null)
    const listNewsDataFlowRef = ref<null | HTMLElement>(null)

    const authorNewsList = computed(() => store.state.authorNewsList)
    const authorProjectList = computed(() => store.state.authorProjectList)

    onMounted(() => {
      if (chartRef.value) {
        chartRef.value.setAttribute('style', 'width:' + (document.body.clientWidth / 2 - 30) + 'px')
      }

      if (listNewsDataFlowRef.value) {
        listNewsDataFlowRef.value.setAttribute('style', 'width:' + (document.body.clientWidth / 2 - 30) + 'px')
      }

      // 互动分析 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(chartRef.value as unknown as HTMLCanvasElement, { opts: { width: '600' } })
      watch(getNewsInteractionData, (data: any) => {
        if (data === null) {
          return false
        } else {

        }

        // modalVar.createTime = data.

        myChart.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: false,
            data: ['收藏量', '评论量', '点赞量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeDataRef.xAxisData.value
          },
          yAxis: {
            type: 'value'
          },
          series: data.series
        })
      })

      watch(getProjectInteractionData, (data: any) => {
        if (data === null) {
          return false
        } else {

        }

        console.log('datadata getProjectInteractionData', data)

        myChart.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: false,
            data: ['收藏量', '浏览量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeDataRef.xAxisData.value
          },
          yAxis: {
            type: 'value'
          },
          series: data.series
        })
      })
      // /watch
      // end 互动分析
      // 流量分析 start
      const myChart2 = echarts.init(listNewsDataFlowRef.value as unknown as HTMLCanvasElement, { opts: { width: '600' } })
      watch(listNewsDataFlow, (data: any) => {
        if (data === null) {
          return false
        } else {
        }

        myChart2.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: false,
            data: ['展现量', '阅读量', '粉丝阅读量', '粉丝展现量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeDataRef.xAxisData.value
          },
          yAxis: {
            type: 'value'
          },
          series: data.series
        })
      })
      // /watch
      watch(getProjectFlowData, (data: any) => {
        if (data === null) {
          return false
        } else {
        }

        myChart2.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: false,
            data: ['展现量', '浏览量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeDataRef.xAxisData.value
          },
          yAxis: {
            type: 'value'
          },
          series: data.series
        })
      })
      // /watch
      // 流量分析 end
    })

    // 编辑
    const editProject = (project: ProjectDto) => {
      console.log('editProject', project)
    }

    // 编辑
    const edit = (news: NewsOneDto) => {
      switch (route.query.type) {
        case '1':
          // 图文
          router.push({ path: '/PostImageText', query: { id: news.id } })
          break
        case '2':
          // 图集
          router.push({ path: '/PostImageArr', query: { id: news.id } })
          break
        case '3':
          // 视频
          router.push({ path: '/PostVideo', query: { id: news.id } })
          break
      }
    }

    const deleteNews = (news: NewsOneDto) => {
      store.dispatch('deleteNews', { id: news.id })
      store.dispatch(reqParam.actionName as string, reqParam).then((data) => {
        if (data.code === '200') {
          total.value = data.data.total
        }
      })
    }
    const cancel = (e: any) => {
      console.log(e)
    }
    return {
      deleteNews,
      edit,
      ...modalVar,
      editProject,
      setCurrentProject,
      displayList,
      getProjectInteractionData,
      getProjectFlowData,
      authorProjectList,
      total,
      reqParam,
      moment,
      changeStatus,
      chartRef,
      listNewsDataFlowRef,
      getNewsInteractionData,
      goSearch,
      authorNewsList,
      dateFormat: dateFormat,
      modal,
      getInteraction,
      currentData,
      listNewsDataFlow,
      locale,
      changeData,
      setCurrent
    }
  }
})
