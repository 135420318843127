
import { defineComponent, ref, PropType, watch, computed, reactive, toRefs, isRef } from 'vue'
import { AtlasNewsDto } from '../store'
import FormGlobal from './FormGlobal.vue'
import OSS from 'ali-oss'
type UploadStatus = 'ready' | 'loading' | 'success' | 'error'
type CheckFunction = (file: File) => boolean;
export default defineComponent({
  props: {
    beforeUpload: {
      type: Function as PropType<CheckFunction>
    },
    imageKey: {
      type: Number,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    hasText: {
      type: Boolean,
      required: false
    },
    tips: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    uploaded: {
      type: Object
    },
    defaultImage: String
  },
  components: {
    FormGlobal
  },
  inheritAttrs: false,
  emits: ['file-uploaded', 'file-uploaded-error'],
  setup (props, context) {
    const fileInput = ref<null | HTMLInputElement>(null)
    const fileStatus = ref<UploadStatus>(props.uploaded ? 'success' : 'ready')
    const uploadedData = ref(props.uploaded)

    // 设置fileStatus的初始值
    if (props.defaultImage) {
      fileStatus.value = 'success'
      uploadedData.value = { url: props.defaultImage }
    }

    watch(() => props.uploaded, (newValue) => {
      if (newValue) {
        fileStatus.value = 'success'
        uploadedData.value = newValue
      }
    })

    watch(() => props.defaultImage, (imageUrl) => {
      // 默认的图片
      if (imageUrl !== undefined) {
        fileStatus.value = 'success'
        uploadedData.value = { url: imageUrl }
        console.log('defaultImage success')
      } else {
        console.log('defaultImage', imageUrl)
      }
    })

    const triggerUpload = () => {
      if (fileInput.value) {
        fileInput.value.click()
      }
    }
    const handleFileChange = (e: Event) => {
      const currentTarget = e.target as HTMLInputElement
      if (currentTarget.files) {
        const files = Array.from(currentTarget.files)
        if (props.beforeUpload) {
          const result = props.beforeUpload(files[0])
          if (!result) {
            return false
          }
        }

        fileStatus.value = 'loading'

        const client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: 'LTAIn0x2gz1Wwggx',
          accessKeySecret: 'rCsNfiGkEdGT8ROTL1Qim7UbWTLcuE',
          bucket: 'jrcm'
        })

        const fileName = new Date().getTime() + (Math.random() * 100000) + files[0].name.substr(-6, 6)

        // 'object'表示从OSS下载的object名称，'localfile'表示本地文件或者文件路径。
        client.put(fileName, files[0]).then(function (r1) {
          fileStatus.value = 'success'
          uploadedData.value = r1
          console.log('imageKey:', props.imageKey)

          context.emit('file-uploaded', { ...r1, imageKey: props.imageKey })
        }).catch(function (err) {
          context.emit('file-uploaded-error', { err })
        })
      }
    }
    return {
      fileInput,
      triggerUpload,
      fileStatus,
      uploadedData,
      handleFileChange
    }
  }
})
