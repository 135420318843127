<template>
  <div class="col">
    <span>{{title}}</span>
    <h1>{{total}}</h1>
    <span>{{addString}}  {{addNum}}</span>
    <span>
      <slot></slot>
    </span>
  </div>
</template>
<style scoped>
span{
    font-size: 13px;
    color: #999999;
    }
h1{
    font-weight: 550;
    }
@media screen and (max-width: 414px){
.col span{
    font-size: 12px!important;
    }
.col{
    margin: 0;
    padding: 0;
    }
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChartHeaderItem',
  props: {
    title: {
      type: String,
      required: true
    },
    total: {
      type: String,
      required: true
    },
    addString: {
      type: String,
      required: true
    },
    addNum: {
      type: String,
      required: true
    }
  }
})
</script>
