
import store, { AuthorCommentDto } from '@/store'
import { defineComponent, isRef, PropType, ref } from 'vue'
import ButtonItem from './ButtonItem.vue'
import DateTime from './DateTime.vue'
export default defineComponent({
  name: 'CommentContent',
  components: {
    ButtonItem,
    DateTime
  },
  props: {
    // 是否点赞
    isFavour: {
      type: Boolean,
      required: false
    },
    // 是否是粉丝
    isFans: {
      type: Boolean,
      required: false
    },
    // 是否有底边
    hasBottomBorder: {
      type: Boolean,
      required: false
    },

    // 是否是详情
    isDetail: {
      type: Boolean,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    },

    // 发布时间
    dateTime: {
      type: String,
      required: true
    },
    // 评论内容
    content: {
      type: String,
      required: true
    },
    // 用户头像
    avatar: {
      type: String,
      required: true
    },
    // 昵称
    nickname: {
      type: String,
      required: true
    },
    // 昵称
    id: {
      type: String,
      required: true
    },
    sendData: {
      type: Object,
      required: true
    },
    // 新闻标题
    newsTitle: {
      type: String,
      required: false
    }
  },
  emits: ['send-after'],
  setup (props, context) {
    // 是否展示文本框
    const displayTextArea = ref(false)
    const toggleTextArea = () => {
      if (props.isDetail) {
        displayTextArea.value = !displayTextArea.value
      }
    }

    const content2 = ref('')

    // 回复评论
    const sendComment = () => {
      store.dispatch('commentSendNews', { content: content2.value, targetId: props.id })
        .then((data) => {
          if (data.code === '200') {
            context.emit('send-after')
            content2.value = ''
          }
        })
    }

    const del = () => {
      console.log('delete')
      store.dispatch('adminCommentDelNews', { id: props.id }).then((data) => {
        if (data.code === '200') {
          context.emit('send-after')
        }
      })
    }

    const favour = () => {
      // newsCommentFavourInsert
      console.log('favour')
      if (props.isFavour) {
        store.dispatch('newsCommentFavourCancel', { targetId: props.id }).then((data) => {
          if (data.code === '200') {
            context.emit('send-after')
          }
        })
      } else {
        store.dispatch('newsCommentFavourInsert', { targetId: props.id }).then((data) => {
          if (data.code === '200') {
            context.emit('send-after')
          }
        })
      }
    }
    return {
      displayTextArea,
      del,
      favour,
      content2,
      sendComment,
      toggleTextArea
    }
  }
})
