<template>
  <div>
    <form class="form-inline my-2 my-l row" @submit.prevent="onSearch">
      <a-range-picker class="col" :locale="locale" v-model:value="time2" @change="onChange" style="color:#999;font-size: 10px;"/>
      <div class="all">
        <input v-model="txt" class="control form-control mr-sm-2 col ml-4" type="search" placeholder="请输入关键词" aria-label="请输入关键词" style="height:32px">
        <div class="bdiv">
        <button class="scontrol col-2 btn my-sm-0" type="submit"></button>
        </div>
      </div>
    </form>
  </div>
</template>
<style scoped>

/* .col {
  width: 200px !important;
} */
input::placeholder
{ color:#999;/* 设置颜色为灰色 */ }
.form-control{
  border: 1px solid #d9d9d9 !important;
}
.btn{
  border-radius: 0px !important;
}
.bdiv{
  height: 28px;
}
.scontrol {
  margin-left: -90px;
  background: url("../assets/搜索.png") no-repeat center;
  border-left: 1px solid #d9d9d9;
  height: 100% !important;
  width: 50px;
  margin-top: 2px !important;
}
.scontrol {
  box-shadow: none !important;
}
.control {
  padding-right: 60px;
}
.all {
  display:contents
}
.col{
    height: 32px !important;
    font-size: 14px;
    }
.ml-4{
    margin-left: 20px !important;
    }
.mr-sm-2{
    margin-right: 20px !important;
    }
.form-control {
    font-size: 12px;
    color:#999 !important;
    }
.row .btn{
    font-size: 14px;
    height: 32px;
    }
.row{
    margin-right: 24px;
    }
.my-2{
    margin-top:0 !important;
    margin-right: 0!important;
    }
@media screen and (max-width: 414px){
.col{
    margin:0 12px 0 0!important;
    font-size:14px;
    }
.col:first-child{
    display:none;
    }
.btn-outline-success{
    width: 56px;
}
    }
</style>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default defineComponent({
  name: 'SearchBar',
  props: {
    hasDateBar: {
      type: Boolean,
      required: false
    }
  },
  emits: ['go-search'],
  setup (props, context) {
    const time2 = ref()
    let time: Array<string>
    const txt = ref('')
    const onChange = (date: Array<object> | string, dateString: Array<string>) => {
      console.log('onChange date', date)
      console.log('onChange dateString', dateString)
      time = dateString
    }

    const onSearch = () => {
      console.log('time', time)
      console.log('txt', txt.value)
      context.emit('go-search', time, txt.value)
    }

    return { time2, onChange, onSearch, locale, txt }
  }
})
</script>
