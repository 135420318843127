
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TabNavItem',
  props: {
    active: {
      type: Boolean,
      require: false
    }
  }
})
