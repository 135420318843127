
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DateTime',
  props: {
    dateTime: {
      type: String,
      required: true
    }
  }
})
