<!-- 评论管新闻 -->
<template>
  <div class="row">
    <tab-nav>
      <tab-nav-item>全部</tab-nav-item>
      <tab-nav-item>图文</tab-nav-item>
      <tab-nav-item>视频</tab-nav-item>
      <tab-nav-item>图集</tab-nav-item>
    </tab-nav>
    <media-list :hasBar="true" class="col-6">
      <media-item
        coverPath="https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=1812517192,1264801167&fm=85&app=92&f=PNG?w=121&h=75&s=BFFAEF16210A554D00ECF57E03005070"
        title="测试标题很长很长的测试标题"
        status=2
        dateTime="08-01 10:22"
        :active="true"
      >
        <template #countGroup>
          <count-item name="展现" count=10></count-item>
          <count-item name="阅读" count=20></count-item>
          <count-item name="评论" count=100></count-item>
        </template>
        <template #buttonGroup>
          <button-item data-toggle="modal" data-target="#exampleModal"
            >查看数据</button-item
          >
          <button-item disabled>编辑</button-item>
          <button-item>删除</button-item>
        </template>
      </media-item>
      <media-item
        coverPath="https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=1812517192,1264801167&fm=85&app=92&f=PNG?w=121&h=75&s=BFFAEF16210A554D00ECF57E03005070"
        title="测试标题很长很长的测试标题"
        status=1
        dateTime="08-01 10:22"
      >
        <template #countGroup>
          <count-item name="展现" count=10></count-item>
          <count-item name="阅读" count=20></count-item>
          <count-item name="评论" count=100></count-item>
        </template>
        <template #buttonGroup>
          <button-item>查看数据</button-item>
          <button-item disabled>编辑</button-item>
          <button-item>删除</button-item>
        </template>
      </media-item>
    </media-list>
    <div class="col">
      <comment-filter-bar class="mb-3"></comment-filter-bar>
      <comment-item
        isFans
        dateTime="08-01 11:59"
        content="评论内容数据测试....."
        newsTitle="深化医保制度改革意见发布建立健全医保信用管理体系"
        :isDetail="true"
      >
      </comment-item>
      <comment-content
        isFans
        dateTime="08-01 11:59"
        content="评论内容数据测试....."
        newsTitle="深化医保制度改革意见发布建立健全医保信用管理体系"
        :isDetail="true"
      >
      </comment-content>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import MediaList from '../components/MediaList.vue'
import CommentItem from '../components/CommentItem.vue'
import CommentContent from '../components/CommentContent.vue'
import CommentFilterBar from '../components/CommentFilterBar.vue'
import MediaItem from '../components/MediaItem.vue'
import CountItem from '../components/CountItem.vue'
import ButtonItem from '../components/ButtonItem.vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
export default defineComponent({
  name: 'CommentListNews',
  components: {
    CommentItem,
    MediaList,
    CommentFilterBar,
    MediaItem,
    CommentContent,
    CountItem,
    TabNav,
    TabNavItem,
    ButtonItem
  }
})
</script>
