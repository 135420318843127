<template>
<ul class="nav nav-pills mb-3">
<slot></slot>
</ul>
</template>
<style scoped>
/* .nav-pills{
    font-size: 16px;
    margin: 20px 0 40px !important;
} */
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TabNav'
})
</script>
