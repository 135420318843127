
import { defineComponent, ref, reactive, toRefs, onMounted, watch } from 'vue'
import E from 'wangeditor'
import FormInput from '../components/FormInput.vue'
import store, { ProjectDto, OssResponse, ResponseType } from '../store'
import Uploader from '../components/FormUploader.vue'
import { beforeUploadCheck } from '../helper'
import createMessage from '../components/createMessage'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'PostProject',
  components: {
    FormInput,
    Uploader
  },
  setup () {
    const projectData: ProjectDto = reactive({
    //  代理商
      agent: '',

      //  代理商联系人
      agentContact: '',

      //  代理商电话
      agentPhone: '',

      //  地点
      area: '',

      //  地区id
      areaId: 0,

      //  投标截止时间
      bidTime: '',

      //  投标保证金账号
      bondAccount: '',

      //  投标保证金银行
      bondBank: '',

      //  投标保证金金额
      bondMoney: '',

      //  投标保证金备注
      bondRemark: '',

      //  投标保证金单位
      bondUnit: '',

      //  正副本数量
      bookNum: '',

      //  采购商
      buyer: '',

      //  采购商联系人
      buyerContact: '',

      //  采购商电话
      buyerPhone: '',

      //  现金购买标书
      cashPurchase: '',

      //  公社id
      communeId: '',

      //  内容
      content: '',

      //  创建时间
      createTime: '',

      //  附件地址
      fileJson: '',

      //  行业id
      industryId: 0,

      //  意向人用户id
      intentId: '',

      //  是否收藏（1收藏 0未收藏）
      isCollect: 0,

      //  是否是意向项目的字段（0: 不是意向 1: 是意向）
      isOk: 0,

      //  项目示范级别/批次
      level: 0,

      //  准确的金额
      moneyDecimal: 0,

      //  省份
      nationalArea: '',

      //  项目编号
      number: '',

      //  开标日期
      openTime: '',

      //  项目发布者(程序内部获取，不用传)
      personId: '',

      //  项目id
      projectId: 0,

      //  项目封面url
      projectImageUrl: '',

      //  项目金额
      projectMoney: '',

      //  项目名称
      projectName: '',

      //  网址
      projectWebsite: '',

      //  购买标书金额
      purchaseMoney: '',

      //  购买标书起止时间
      purchaseTime: '',

      //  发布日期
      releaseTime: '',

      //  电汇账号
      remitAccount: '',

      //  电汇银行
      remitBank: '',

      //  电汇备注
      remitRemark: '',

      //  电汇单位
      remitUnit: '',

      //  回报机制id
      rewardId: 0,

      //  废标人员要求
      scrapPersonRequest: '',

      //  废标资质要求
      scrapQuaRequest: '',

      //  废标封标要求
      scrapSealedRequest: '',

      //  标书当前阶段的字段（1: 买标阶段 2: 写标阶段 3: 封标阶段 4: 投标阶段 5已完成）
      step: 0,

      //  投标地点
      tenderArea: '',

      //  投标文件电子标份数
      tenderNum: '',

      //  第三方网站
      thirdWebsite: ''
    })

    const projectDataRef = toRefs(projectData)
    // 保存草稿
    const submitDraft = () => {
      console.log('草稿项目信息', projectDataRef)

      return false
    }

    const router = useRouter()
    const route = useRoute()
    // 发布
    const submitRelease = () => {
      console.log('发布项目信息', projectDataRef)
      const isEditMode = !!route.query.id
      // 是修改还是添加
      const actionName = isEditMode ? 'updateProject' : 'insertProject'
      const sendProjectData = {
        //  代理商
        agent: projectDataRef.agent.value,

        //  代理商联系人
        agentContact: projectDataRef.agentContact.value,

        //  代理商电话
        agentPhone: projectDataRef.agentPhone.value,

        //  地点
        area: projectDataRef.area.value,

        //  地区id
        areaId: projectDataRef.areaId.value,

        //  投标截止时间
        bidTime: projectDataRef.bidTime.value,

        //  投标保证金账号
        bondAccount: projectDataRef.bondAccount.value,

        //  投标保证金银行
        bondBank: projectDataRef.bondBank.value,

        //  投标保证金金额
        bondMoney: projectDataRef.bondMoney.value,

        //  投标保证金备注
        bondRemark: projectDataRef.bondRemark.value,

        //  投标保证金单位
        bondUnit: projectDataRef.bondUnit.value,

        //  正副本数量
        bookNum: projectDataRef.bookNum.value,

        //  采购商
        buyer: projectDataRef.buyer.value,

        //  采购商联系人
        buyerContact: projectDataRef.buyerContact.value,

        //  采购商电话
        buyerPhone: projectDataRef.buyerPhone.value,

        //  现金购买标书
        cashPurchase: projectDataRef.cashPurchase.value,

        //  公社id
        communeId: projectDataRef.communeId.value,

        //  内容
        content: projectDataRef.content.value,

        //  创建时间
        createTime: projectDataRef.createTime.value,

        //  附件地址
        fileJson: projectDataRef.fileJson.value,

        //  行业id
        industryId: projectDataRef.industryId.value,

        //  意向人用户id
        intentId: projectDataRef.intentId.value,

        //  是否收藏（1收藏 0未收藏）
        isCollect: projectDataRef.isCollect.value,

        //  是否是意向项目的字段（0: 不是意向 1: 是意向）
        isOk: projectDataRef.isOk.value,

        //  项目示范级别/批次
        level: projectDataRef.level.value,

        //  准确的金额
        moneyDecimal: projectDataRef.moneyDecimal.value,

        //  省份
        nationalArea: projectDataRef.nationalArea.value,

        //  项目编号
        number: projectDataRef.number.value,

        //  开标日期
        openTime: projectDataRef.openTime.value,

        //  项目发布者(程序内部获取，不用传)
        personId: projectDataRef.personId.value,

        //  项目id
        projectId: projectDataRef.projectId.value,

        //  项目封面url
        projectImageUrl: projectDataRef.projectImageUrl.value,

        //  项目金额
        projectMoney: projectDataRef.projectMoney.value,

        //  项目名称
        projectName: projectDataRef.projectName.value,

        //  网址
        projectWebsite: projectDataRef.projectWebsite.value,

        //  购买标书金额
        purchaseMoney: projectDataRef.purchaseMoney.value,

        //  购买标书起止时间
        purchaseTime: projectDataRef.purchaseTime.value,

        //  发布日期
        releaseTime: projectDataRef.releaseTime.value,

        //  电汇账号
        remitAccount: projectDataRef.remitAccount.value,

        //  电汇银行
        remitBank: projectDataRef.remitBank.value,

        //  电汇备注
        remitRemark: projectDataRef.remitRemark.value,

        //  电汇单位
        remitUnit: projectDataRef.remitUnit.value,

        //  回报机制id
        rewardId: projectDataRef.rewardId.value,

        //  废标人员要求
        scrapPersonRequest: projectDataRef.scrapPersonRequest.value,

        //  废标资质要求
        scrapQuaRequest: projectDataRef.scrapQuaRequest.value,

        //  废标封标要求
        scrapSealedRequest: projectDataRef.scrapSealedRequest.value,

        //  标书当前阶段的字段（1: 买标阶段 2: 写标阶段 3: 封标阶段 4: 投标阶段 5已完成）
        step: projectDataRef.step.value,

        //  投标地点
        tenderArea: projectDataRef.tenderArea.value,

        //  投标文件电子标份数
        tenderNum: projectDataRef.tenderNum.value,

        //  第三方网站
        thirdWebsite: projectDataRef.thirdWebsite.value

      }
      const sendData = isEditMode
        ? {
          id: route.query.id,
          ...sendProjectData
        }
        : sendProjectData
      store.dispatch(actionName, sendData).then((data) => {
        if (data.code === '200') {
          createMessage('成功，2秒后跳转到作品管理', 'success', 2000)
          setTimeout(() => {
            router.push('/DataList')
          }, 2000)
        } else {
          createMessage('失败', 'error', 2000)
        }
      })
      return false
    }

    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, {
        format: ['image/jpeg', 'image/png'],
        size: 10
      })
      const { passed, error } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 10Mb', 'error')
      }
      return passed
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      projectDataRef.projectImageUrl.value = rawData.url
    }

    const uploadedData = ref()

    // 如果是编辑,就设置初始值
    if (route.query.id) {
      store.dispatch('getWebOneProjectByProjecyId', { projectId: route.query.id }).then((rawData: ResponseType<any>) => {
        console.log(rawData, 'rawData getWebOneProjectByProjecyId')
        if (rawData.code !== '200') {
          createMessage('获取项目详情失败', 'error')
          return false
        }

        projectDataRef.content.value = rawData.data.content
        projectDataRef.agent.value = rawData.data.agent
        projectDataRef.agentContact.value = rawData.data.agentContact
        projectDataRef.agentPhone.value = rawData.data.agentPhone
        projectDataRef.area.value = rawData.data.area
        projectDataRef.areaId.value = rawData.data.areaId
        projectDataRef.bidTime.value = rawData.data.bidTime
        projectDataRef.bondAccount.value = rawData.data.bondAccount
        projectDataRef.bondBank.value = rawData.data.bondBank
        projectDataRef.bondMoney.value = rawData.data.bondMoney
        projectDataRef.bondRemark.value = rawData.data.bondRemark
        projectDataRef.bondUnit.value = rawData.data.bondUnit
        projectDataRef.bookNum.value = rawData.data.bookNum
        projectDataRef.buyer.value = rawData.data.buyer
        projectDataRef.buyerContact.value = rawData.data.buyerContact
        projectDataRef.buyerPhone.value = rawData.data.buyerPhone
        projectDataRef.cashPurchase.value = rawData.data.cashPurchase
        projectDataRef.communeId.value = rawData.data.communeId
        projectDataRef.createTime.value = rawData.data.createTime
        projectDataRef.fileJson.value = rawData.data.fileJson
        projectDataRef.industryId.value = rawData.data.industryId
        projectDataRef.intentId.value = rawData.data.intentId
        projectDataRef.isCollect.value = rawData.data.isCollect
        projectDataRef.isOk.value = rawData.data.isOk
        projectDataRef.level.value = rawData.data.level
        projectDataRef.moneyDecimal.value = rawData.data.moneyDecimal
        projectDataRef.nationalArea.value = rawData.data.nationalArea
        projectDataRef.number.value = rawData.data.number
        projectDataRef.openTime.value = rawData.data.openTime
        projectDataRef.personId.value = rawData.data.personId
        projectDataRef.projectId.value = rawData.data.projectId
        projectDataRef.projectImageUrl.value = rawData.data.projectImageUrl
        projectDataRef.projectMoney.value = rawData.data.projectMoney
        projectDataRef.projectName.value = rawData.data.projectName
        projectDataRef.projectWebsite.value = rawData.data.projectWebsite
        projectDataRef.purchaseMoney.value = rawData.data.purchaseMoney
        projectDataRef.purchaseTime.value = rawData.data.purchaseTime
        projectDataRef.releaseTime.value = rawData.data.releaseTime
        projectDataRef.remitAccount.value = rawData.data.remitAccount
        projectDataRef.remitBank.value = rawData.data.remitBank
        projectDataRef.remitRemark.value = rawData.data.remitRemark
        projectDataRef.remitUnit.value = rawData.data.remitUnit
        projectDataRef.rewardId.value = rawData.data.rewardId
        projectDataRef.scrapPersonRequest.value = rawData.data.scrapPersonRequest
        projectDataRef.scrapQuaRequest.value = rawData.data.scrapQuaRequest
        projectDataRef.scrapSealedRequest.value = rawData.data.scrapSealedRequest
        projectDataRef.step.value = rawData.data.step
        projectDataRef.tenderArea.value = rawData.data.tenderArea
        projectDataRef.tenderNum.value = rawData.data.tenderNum
        projectDataRef.thirdWebsite.value = rawData.data.thirdWebsite
      })
    }

    onMounted(() => {
      console.log(document.querySelector('#project-content')?.innerHTML === '')
      const editor = new E('#project-content')

      if (document.querySelector('#project-content')?.innerHTML === '') {
        // 配置 onchange 回调函数
        editor.config.onchange = function (newHtml: string) {
          console.log('change 之后最新的 html', newHtml)
          projectDataRef.content.value = newHtml
        }
        editor.create()
      }

      console.log(document.querySelector('#project-content')?.innerHTML === '')
      watch(projectDataRef.content, (con) => {
        editor.txt.html(con)
      })
    })
    return {
      uploadCheck,
      uploadedData,
      handleFileUploaded,

      submitRelease,
      ...projectDataRef,
      submitDraft
    }
  }
})
