<template>
  <!-- 评论过筛选组件 -->
   <div class="col-6 d-flex justify-content-between align-items-start filter-bar">
      <select-item
        :optionArr="[
          { text: '最新', value: '1' },
          { text: '最热', value: '2' },
        ]"
      ></select-item>
      <p-check-box id="is-fans">仅看粉丝</p-check-box>
    </div>
</template>
<style scoped>
  .filter-bar{
    height: 26px;
  }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
import SelectItem from './SelectItem.vue'
import PCheckBox from './PCheckBox.vue'
export default defineComponent({
  name: 'CommentFilterBar',
  components: {
    SelectItem,
    PCheckBox
  }
})
</script>
