<template>
  <option :selected="selected" :value="value">
    <slot></slot>
  </option>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormgOption',
  props: {
    selected: {
      type: Boolean,
      required: false
    },
    value: {
      type: String,
      required: true
    }
  }
})
</script>
