
import { defineComponent, toRefs, computed, watch, ref, onMounted } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import { ColumnProps } from './components/CoumnList.vue'
import GlboalHeader from './components/GlobalHeader.vue'
import GlobalMenu from './components/GlobalMenu.vue'
import store from './store'
import Loader from './components/Loader.vue'
import createMessage from './components/createMessage'

const testData: ColumnProps[] = [
  {
    // id
    id: '188907889541235622',

    // 标题
    title: '宁波市海曙国有资本经营集团有限公司下属公司基本账户竞争性存放项目',

    // 文章审核状态
    status: 12,

    // 封面
    // cover: 'http://jrcm.oss-cn-beijing.aliyuncs.com/4417234765343625217.jpg',

    // 日期
    date: '08-01 10:22',

    // 展现数量
    displayCount: 1564,

    // 阅读数量
    viewCount: 12
  },
  {
    // id
    id: '188907889541235623',

    // 标题
    title: '边境口岸超短波监测站新建及升级改造项目招标公告',

    // 文章审核状态
    status: 12,

    // 封面
    cover: 'http://jrcm.oss-cn-beijing.aliyuncs.com/4417234888408698881.jpg',

    // 日期
    date: '07-22 10:22',

    // 展现数量
    displayCount: 332,

    // 阅读数量
    viewCount: 1
  }
]
export default defineComponent({
  name: 'App',
  components: {
    // ColumnList,
    GlobalMenu,
    Loader,
    GlboalHeader
  },
  setup () {
    const screenWidth = document.body.clientWidth
    // 点击控制显示
    const showMenu = computed(() => store.state.showMenu)
    // 三道杠是否显示
    const has3 = ref(false)
    if (screenWidth < 1280) {
      has3.value = true
      store.state.showMenu = false
    } else {
      store.state.showMenu = true
      has3.value = false
    }
    const widthVar = ref(0)
    const heightVar = ref(0)
    const header = ref(null)
    window.onresize = () => {
      store.state.showMenu = (document.body.clientWidth > 1280)
      has3.value = !(document.body.clientWidth > 1280)
    }
    const isLoading = computed(() => store.state.loading)
    const error = computed(() => store.state.error)

    const { user } = toRefs(store.state)
    watch(() => error.value.status, () => {
      const {
        status,
        message
      } = error.value
      if (status && message) {
        createMessage(message, 'error')
      }
    })
    return {
      store,
      header,
      has3,
      showMenu,
      widthVar,
      heightVar,
      user,
      isLoading,
      list: testData
    }
  }
})
