<template>
<div>
  <!-- <div class="row btn-1">
    <button class="col-2 btn btn-light preview" @click.prevent="previewClick">预览</button>
    <button class="col-2 btn btn-light mr-4" @click.prevent="submitDraft">
      存草稿
    </button>
    <button class="col-2 btn btn-primary" @click.prevent="submitRelease">
      发布
    </button>
  </div> -->
  <!-- /button -->
  <div class="content">
  <!-- title -->
      <form-input v-model="newsTitle" label="标题" id="news-title"></form-input>
  <!-- title -->
  <!-- 一键排版 -->
      <button class="col-2 btn btn-primary m-2" @click.prevent="oneKey()">
        一键排版
      </button>
      <tinymce v-model="content" />
      <FormCheck label="封面个数" id="new-tag">
        <form-check-item type="radio" :checked="imageNum===0"
          checkValue="0"
          checkLabel="无图"
          checkId="cover0"
          checkName="type"
          @click="imageNum=0"
        ></form-check-item>
        <form-check-item
          type="radio"
          :checked="imageNum===1"
          checkValue="1"
          checkLabel="单图"
          checkId="cover1"
          checkName="type"
          @click="imageNum=1"
        ></form-check-item>
        <form-check-item
          type="radio"
          checkValue="3"
          :checked="imageNum===3"
          checkLabel="三图"
          checkId="cover3"
          @click="imageNum=3"
          checkName="type"
        ></form-check-item>
        <div class="clear"></div>
      </FormCheck>
    <div v-if="imageNum===1">
        <uploader
          label=""
          id="news-cover0"
          tips="点击上传"
          :imageKey="0"
          :beforeUpload="uploadCheck"
          @file-uploaded="handleFileUploaded"
          :uploaded="uploadedData"
          class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
          style="height: 105px; width: 150px;margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;"
        >
          <span class="pl-2">点击上传</span>
          <template #loading>
            <div>
              <div class="spinner-border text-secondary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </template>
          <template #uploaded="dataProps">
            <div class="uploaded-area">
              <img
                :src="dataProps.uploadedData.url"
                class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
              />
            </div>
          </template>
        </uploader>
      </div>
      <div v-if="imageNum===3" class="num3">
        <uploader
          v-for="(img, key) in [0,1,2]"
          :key="key"
          :defaultImage="coverImageArr[img]"
          :imageKey="key"
          label=""
          :id="'news-cover'+key"
          tips="点击上传"
          :beforeUpload="uploadCheck"
          @file-uploaded="handleFileUploaded"
          :uploaded="uploadedData"
          class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
          style="margin-left:12px;cursor:pointer;border: 1px dashed #DDDDDD;border-radius: 4px;height:105px;width:150px"
        >
          <span class="pl-2">点击上传</span>
          <template #loading>
            <div>
              <div class="spinner-border text-secondary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </template>
          <template #uploaded="dataProps">
            <div class="uploaded-area">
              <img
                :src="dataProps.uploadedData.url"
                class="avatar bg-light text-secondary my-4 d-flex align-items-center justify-content-center"
              />
            </div>
          </template>
        </uploader>
      </div>
      <form-select label="类型" v-model="newsColumn" id="news-type">
        <FormOption v-for="col in columns" :key="col.id" :value="col.id + ''">{{
          col.name
        }}</FormOption>
      </form-select>
      <form-select v-model="newsSource" label="来源网址" id="news-source">
        <FormOption
          v-for="col2 in listNewsSource"
          :key="col2.id"
          :value="col2.id + ''"
          >{{ col2.source }}</FormOption
        >
      </form-select>
      <!-- <button class="col-2 btn btn-primary m-h-1 mr-4" @click.prevent="submitRelease">
      发布
    </button> -->
    <div class="operate">
      <button class="col-2 btn btn-light preview" @click.prevent="previewClick">预览</button>
      <button class="col-2 btn btn-light mr-4" @click.prevent="submitDraft">
        存草稿
      </button>
      <button class="col-2 btn btn-primary" @click.prevent="submitRelease">
        发布
      </button>
    </div>
  </div>
  <a-drawer
    title="文章详情"
    placement="right"
    :closable="false"
    v-model:visible="visible"
    :after-visible-change="afterVisibleChange"
  >
    <h3 class="title1">{{previewData.title}}</h3>
    <p>
      来源:
      <span v-if="newsSource=='0'">其他来源</span>
      <span v-for="col2 in listNewsSource"
          :key="col2.id">
        <span v-if="col2.id+''==newsSource">
          {{col2.source}}
        </span>
      </span>
    </p>
    <div v-if="previewData!==undefined && previewData.type==1" v-html="content+'<style>img{ width: 100%; }</style>'" id="content"></div>
    <!-- <el-row v-if="previewData!==undefined && previewData.type==2">
      <el-col v-for="(i,index) in previewData.atlasNewsDtos" :key="i">
          <el-card :body-style="{ padding: '0px' }">
              <img style="width:100%" :src="i.imageUrl" class="image">
              <div style="padding: 14px;">
                  <span>{{i.describe}}</span>
                  <div class="bottom clearfix">
                      <time class="time">{{index+1}}/{{previewData.atlasNewsDtos.length}}</time>
                  </div>
              </div>
          </el-card>
      </el-col>
    </el-row> -->
    <el-row v-if="previewData!==undefined && previewData.type==3">
        <el-col>
            <el-card>
                <div class="imgbox">
                    <video
                            style="width:100%"
                            :src="previewData.content"
                            autoplay="autoplay"
                            controls="controls"
                            loop
                    ></video>
                </div>
            </el-card>
        </el-col>
    </el-row>
  </a-drawer>
  <div v-if="previewVisible" class="preview-container">
    <img class="close" @click="previewVisible=false" src="../assets/X.png" alt="">
    <div class="preview-mobile">
      <!-- <span class="preview-title">标题在哪里</span>   -->

      <div class="con">
        <h3 class="title1">{{previewData.title}}</h3>
        <div class="source">
          来源:
          <span v-if="newsSource=='0'">其他来源</span>
          <span v-for="col2 in listNewsSource"
              :key="col2.id">
            <span v-if="col2.id+''==newsSource">
              {{col2.source}}
            </span>
          </span>
        </div>
        <div class="preview-content" v-if="previewData!==undefined && previewData.type==1" v-html="content+'<style>img{ width: 100%; }</style>'" id="content"></div>
      </div>
      <!-- <el-row v-if="previewData!==undefined && previewData.type==2">
        <el-col v-for="(i,index) in previewData.atlasNewsDtos" :key="i">
            <el-card :body-style="{ padding: '0px' }">
                <img style="width:100%" :src="i.imageUrl" class="image">
                <div style="padding: 14px;">
                    <span>{{i.describe}}</span>
                    <div class="bottom clearfix">
                        <time class="time">{{index+1}}/{{previewData.atlasNewsDtos.length}}</time>
                    </div>
                </div>
            </el-card>
        </el-col>
      </el-row> -->
      <el-row v-if="previewData!==undefined && previewData.type==3">
          <el-col>
              <el-card>
                  <div class="imgbox">
                      <video
                              style="width:100%"
                              :src="previewData.content"
                              autoplay="autoplay"
                              controls="controls"
                              loop
                      ></video>
                  </div>
              </el-card>
          </el-col>
      </el-row>


    </div>
    <!-- <span>标题在哪里</span> -->
  </div>
</div>
</template>
<style scoped>
.con {
  height: 100%;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: scroll;
}
.con::-webkit-scrollbar { width: 0 !important }
.con { -ms-overflow-style: none; }
.con { overflow: -moz-scrollbars-none; }
.title1{
  text-align: left;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
}
.source {
  text-align: left;
  margin-top: 10px;
}
.btn-primary {
  background-color: #FF5E5E !important;
}
.operate {
  margin-top: 50px;
}
.preview-content{
  line-height: 30px;
  margin-top: 12px;
    /* height: 70%; */
    /* padding-bottom: 100px; */
    overflow: scroll;
    text-align: justify;
    font-size: 18px;
}
.preview-content::-webkit-scrollbar { width: 0 !important }
.preview-mobile {
  /* background: red; */
  /* background-image: url('../assets/mobile.png'); */
  background-image: url('../assets/mobile.png');
  background-size: 100% 100%;
  background-position: center;
  width: 280px;
  height: 568px;
  text-align: inherit;
  margin: 0 auto;
  margin-top: 60px;
  padding: 76px 14px 40px 14px;
}
.close {
  /* text-align: initial; */
  position: absolute;
  right: 40px;
  top: 30px;
  width: auto;
}
.preview-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  background-color: rgba(0,0,0,0.62);
  /* color: white; */
  z-index: 1000;
}
.uploaded-area img {
  width: 150px;
  height: 105px;
}
.mr-5{
  margin-left: 10px!important;
}
@media screen and (max-width:414px){
.uploaded-area img{
    width:100px!important;
    height: 105px;
  }
}
@media screen and (width:414px){
.uploaded-area img{
    width:110px!important;
    height: 105px;
  }
}
@media screen and (width:320px){
.uploaded-area img{
    width:88px!important;
    height: 105px;
  }
}
.preview{
  margin-right: 59px;
}
.preview-view {
  background: #999;
  height: 800px;
}
#div1{
    margin: 10px 0px 20px !important;
    width:100%;
    }
#news-title{
    margin-top: -20px;
    }
.content{
    position:static;
    background-color:#ffffff;
    }
.clear{
    clear:both;
    }
#news-cover0{
    margin-left: 300px;
    padding-top: 200px;
    }
#new-tag{
    margin-left: 200px;
    background-color: aqua;
    z-index: 50000000000;
    }
.btn-light{
    border: 1px solid rgb(228, 228, 228);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-top: -66px;
    margin-left: -50px;
    }
.btn-light:hover{
    border: 1px solid #999;
    margin-left: -50px;
    background-color: #f8f9fa;
    margin-top: -66px;
    }
.btn-primary{
    border: 1px solid rgba(10, 31, 228, 0);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-left: -10px;
    margin-top: -66px;
    }
.btn-primary:hover{
    border: 1px solid rgba(10, 31, 228, 0);
    margin-left: -10px;
    margin-top: -66px;
    }
.m-h-1{
  margin-top: 0px!important;
}
.btn-primary{
    border: 1px solid rgba(10, 31, 228, 0);
    width: 100px;
    height: 36px;
    font-size: 14px;
    margin-left: -10px;
    margin-top: -66px;
    }
.btn-primary:hover{
    border: 1px solid rgba(10, 31, 228, 0);
    margin-left: -10px;
    margin-top: -66px;
    }
.num3{
    display: flex;
    flex-direction:row;
    margin-left:184px;
    }
@media screen  and (max-width: 1400px){
    .num3{
    display: flex;
    flex-direction:row;
    margin-left:132px !important;
    }
    }
@media screen  and (max-width: 1280px){
    .num3{
    display: flex;
    flex-direction:row;
    margin-left:104px !important;
    }
    }
@media screen and (max-width:414px){
    .btn-1{
    padding:40px 0 0 64px!important;
    }
    #news-title{
    margin: 0;
    padding: 0;
    }
    .num3{
    display: flex;
    flex-direction:row;
    margin-left:0px !important;
    }
    .pl-2{
    width:150px;
    height: 60px;
    margin-top: 32px;
    display: block;
    padding-left:0!important;
    }
    .my-4{
    margin-top:0px!important;
    }
    }
</style>
<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, reactive } from 'vue'
import { beforeUploadCheck } from '../helper'
import store, {
  GlobalDataProps,
  OssResponse,
  NewsOneDto,
  ResponseType,
  AtlasNewsDto,
  newsType,
  ListType
} from '../store'
import createMessage from '../components/createMessage'
import tinymce from '../components/TinymceEditor.vue'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
import FormCheck from '../components/FormCheck.vue'
import FormCheckItem from '../components/FormCheckItem.vue'
import Uploader from '../components/FormUploader.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
// import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'PostImageText',
  components: {
    tinymce,
    FormInput,
    FormCheck,
    FormCheckItem,
    FormSelect,
    FormOption,
    Uploader
  },
  setup () {
    const editor = ref()
    const newsTitle = ref('')
    // 封面数量
    const imageNum = ref(1)
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const router = useRouter()
    const route = useRoute()
    const visible = ref(false)
    const previewVisible = ref(false)
    const isEditMode = !!route.query.id
    // 是修改还是添加
    const actionName = isEditMode ? 'updateNews' : 'createPostNews'
    // 文章封面
    const coverImageArr: string[] = []

    const content = ref('')
    //预览内容
    const previewData : NewsOneDto = reactive({
      // 资讯栏目id
        columnId: 0,
        // 资讯内容
        content: '',
        // 资讯标题
        title: '',
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 1,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: '',
        // 资讯来源id
        sourceId: 1,
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: 0
    })
    // visible.value = false;

    // 一键排版的样式
    const oneKey = () => {
      // 允许的标签项
      const allowTags = ['p', 'img', 'a', 'h3', 'ul', 'li', 'dt', 'dd']
      // 匹配标签的正则
      const tagPatrn = /<\s*([/]?)\s*([\w]+)[^>]*>/ig
      // withTag 前面的匹配到的标签
      // isClose 如果匹配到了就是"/"
      // htmlTag 匹配到标签名字
      content.value = content.value.replace(tagPatrn, function (withTag, isClose, htmlTag) {
        let htmlReturn = ''
        htmlTag = htmlTag.toLowerCase()
        for (let i = 0; i < allowTags.length; i++) {
          if (allowTags[i] !== htmlTag) {
            continue
          }
          if (isClose === '') {
            switch (htmlTag) {
              case 'p':
                htmlReturn = '<p>'
                break
              case 'a':
                htmlReturn = withTag
                break
              case 'img':
                htmlReturn = '<p>' + withTag + '</p>'
                break
              case 'br':
                htmlReturn = '</p><p>'
                break
              default:
                htmlReturn = withTag
                break
            }
          } else { htmlReturn = withTag }
          break
        }
        return htmlReturn
      })
      // 去掉 a的链接
      content.value = content.value.replace(/<a\s[^>]*>(.*?)<\/a>/img, '$1')

      // 去掉标签的所有class
      content.value = content.value.replace(/class\s*?=\s*?([‘"])[\s\S]*?\1/g, '')
      content.value = content.value.replace(/class\s*?=\s*?([‘"])[\s\S]*?\1/g, '')

      // 去掉所有的行内样式
      content.value = content.value.replace(/style\s*?=\s*?(['"])[\s\S]*?\1/g, '')
      content.value = content.value.replace(/style\s*?=\s*?(['"])[\s\S]*?\1/g, '')

      // 添加样式
      // 图片居中,并且加边距,增加其他样式
      // content.value = content.value.replace(new RegExp('<img', 'g'), '<img style="text-align:center; text-indent:0; margin:10px auto;" ')
      content.value = content.value.replace(/<p>(<img[^>]+\/>)<\/p>/img, '<p style="text-align:center;text-indent:0">$1</p>')

      content.value = content.value.replace(new RegExp('<h3', 'g'), '<h3 style="margin-bottom: 6px;" ')
      content.value = content.value.replace(new RegExp('<ul', 'g'), '<ul style="margin-top: 0px;" ')
      content.value = content.value.replace(new RegExp('<li', 'g'), '<li style="text-indent:0;" ')
      content.value = content.value.replace(new RegExp('<dt', 'g'), '<dt style="font-weight:bold; line-height: 26px;" ')
      content.value = content.value.replace(new RegExp('<dd', 'g'), '<li style="margin-left: 4px; line-height: 28px;" ')

      // 匹配不含有标签的p标签
      // html 匹配到的p标签
      // content p标签中的内容
      content.value = content.value.replace(/<p>\s*([^<]*)<\/p>/ig, function (html, content) {
        console.log(content)
        let htmlReturn = ''
        // htmlReturn = '<p style="text-indent:2em;font-size: 14px;color:#333;white-space:normal; word-break:break-all; word-wrap:break-word;">' + content + '</p>'
        htmlReturn = '<p style="text-indent:2em;color:#333;white-space:normal; word-break:break-all; word-wrap:break-word;">' + content + '</p>'
        return htmlReturn
      })

      // 最后再去掉空格
      content.value = content.value.replace(/<p>(\s|&nbsp;|)*(.*)<\/p>/img, function (a, b, c) {
        if (c === '') return ''
        else return '<p>' + c + '</p>'
      })
    }

    const sendData = (isPublish: number) => {
      if (newsTitle.value.trim() === '') {
        message.error('标题不能为空')
        return false
      }
      if (content.value.trim() === '') {
        message.error('内容不能为空')
        return false
      }

      // 设置listType的值
      let listType: ListType = 4
      if (imageNum.value === 1) {
        listType = 3
      }

      if (imageNum.value === 3) {
        listType = 3
      }

      if (imageNum.value === 0) {
        listType = 4
      }

      const data: NewsOneDto = {
        // 资讯栏目id
        columnId: parseInt(newsColumn.value),
        // 资讯内容
        content: content.value,
        // 资讯标题
        title: newsTitle.value,
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 1,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: listType,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: coverImageArr.join(','),
        // 资讯来源id
        sourceId: parseInt(newsSource.value),
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: isPublish
      }
      if (isPublish == 3) {
        previewVisible.value = true;
        console.log(data);
        previewData.title = data.title
        previewData.content = data.content
        previewData.type = data.type
        previewData.listType = data.listType
        return;
      }
      let covers = coverImageArr;
      if (covers.length != imageNum.value) {
        message.error('封面数量不对')
        return false;
      }
      if (imageNum.value == 3 && (covers[0].length == 0 || covers[1].length == 0 || covers[2].length == 0)) {
        message.error('封面数量不对')
        return false;
      }
      
      else {
        const sendData2 = isEditMode
          ? {
            id: route.query.id,
            ...data
          }
          : data

        store.dispatch(actionName, sendData2).then((data) => {
          if (data.code !== '200') {
            // message.error(data.message)
            return false
          }
          createMessage('添加成功，2秒后跳转到作品管理', 'success', 2000)
          setTimeout(() => {
            router.push({ path: '/DataList', query: { type: '1' } })
          }, 2000)
        })
      }
    }
    const afterVisibleChange=()=>{
      if (visible.value) {
        console.log('出现');  
      } else{
        console.log('消失');
      }
      
    }

    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, {
        format: ['image/jpeg', 'image/png'],
        size: 10
      })
      const { passed, error } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 10Mb', 'error')
      }
      return passed
    }

    const uploadedData = ref()
    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)
    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })
    const handleFileUploaded = (rawData: OssResponse) => {
      coverImageArr[rawData.imageKey as number] = rawData.url
    }

    // 如果是编辑,就设置初始值
    if (route.query.id) {
      store.dispatch('getNewsContentById', { newsId: route.query.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          createMessage('获取文章详情失败', 'error')
          return false
        }

        const currentEditNews = rawData.data
        console.log('currentEditNews', currentEditNews)
        content.value = currentEditNews.content
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        // Array.prototype.push.apply(coverImageArr, [
        //   'http://jrcm.oss-cn-beijing.aliyuncs.com/5.jpg',
        //   'http://jrcm.oss-cn-beijing.aliyuncs.com/11.jpg',
        //   'http://jrcm.oss-cn-beijing.aliyuncs.com/12.jpg'
        // ])

        console.log('newsTitle.value-------', currentEditNews, 'newsTitle.value')

        // if (currentEditNews.imageUrl && currentEditNews.imageUrl?.length < 1) {
        if (currentEditNews.listType == 4) {
          imageNum.value = 0
          console.log('imageNum', imageNum)
        } else if (currentEditNews.imageUrl) {
          imageNum.value = currentEditNews.imageUrl.length
          Array.prototype.push.apply(coverImageArr, currentEditNews.imageUrl as string[])
          uploadedData.value = { url: coverImageArr[0] }
        }
      })
    }
    //预览
    const previewClick = () => {
      console.log('预览')
      // previewVisible.value = true;
      // return;
      sendData(3)
      console.log(uploadedData);
    }
    const handleClose = () =>{
      console.log('handleClose');
      
    }
    // 保存草稿
    const submitDraft = () => {
      sendData(2)
    }

    // 发布
    const submitRelease = () => {
      sendData(1)
    }

    return {
      oneKey,
      editor,
      content,
      previewClick,
      handleClose,
      submitDraft,
      submitRelease,
      handleFileUploaded,
      uploadedData,
      columns,
      listNewsSource,
      uploadCheck,
      afterVisibleChange,
      newsTitle,
      newsColumn,
      newsSource,
      coverImageArr,
      imageNum,
      visible,
      previewVisible,
      previewData
    }
  }
})
</script>
