<template>
<li class="ml-0 row mb-2 pb-2 border-bottom pl-3" :class="{'active':active}">
    <div class="users">
  <div class="row text-nowrap text-left avatar0">
    <img  class="col-1 p-0 m-0 avatar" :src="userAvatar" alt="头像">
    <span class="col-9 user-name">{{nickname}}</span>
  </div>
  <div class="row text-nowrap text-left text-left1">
    <span class="col-1 tag m-0 p-0 text-center text-justify" v-if="isFans">粉丝</span>
    <span class="description col-2" style="width:52px">评论了</span>
    <span class="news-title col">《{{newsTitle}}》</span>
  </div>
  </div>
  <div class="row mb-4 mt-3">
    {{content}}
  </div>
  <div class="d-flex bd-highlight pl-0">
    <date-time :dateTime="dateTime" class="flex-fill bd-highlight text-left ml-0 col"></date-time>
    <span class="flex-fill bd-highlight text-right col">
      <img class="icon" src="../assets/回复.png" alt="">
      <button-item @click.prevent="toggleTextArea" style="cursor: pointer;">{{isDetail && displayTextArea?'取消回复':'回复'}}</button-item>
      <img class="icon" v-if="!isFavour" src="../assets/点赞.png" alt="">
      <img class="icon" v-if="isFavour" src="../assets/点赞红.png" alt="">
      <button-item @click.prevent="favour" style="cursor: pointer;" :style="isFavour?'color:#CF000D':''">{{isFavour?'取消点赞':'点赞'}}</button-item>
      <img class="icon" src="../assets/删除.png" alt="">
      <button-item @click.prevent="del" style="cursor: pointer;">删除</button-item>
    </span>
  </div>
  <div class="form-group" v-if="displayTextArea">
    <textarea v-model="content2" class="form-control" id="exampleFormControlTextarea1" rows="2" placeholder="请输入您的评论"></textarea>
    <a-button @click="sendComment" class="sure mt-3" style="cursor: pointer;">发布</a-button>
  </div>
</li>
</template>
<style scoped>
.ml-0{
  margin-left:0px !important;
}
.sure{
    position: relative;
    right: -160px;
    bottom: 60px;
}
.form-control {
  height: 150px !important;
  resize: none;
}
.icon {
  margin-right: 10px;
}
.d-flex{
    padding-right: 0!important;
    }
.active{
    background-color: #FAFAFA;
    border-right: 1px solid #CF000D!important;
    }
.text-center{
    align-items: center;
    }
.user-name{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    overflow: hidden;
    color: #666666;
    line-height: 24px;
    padding-right: 20px !important;
    display:block;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    padding-left: 0!important;
    }
.col-9{
    /* width: 100%; */
    text-align: center !important;
    }
.description{
    color: #999999;
    font-size: 14px;
    }
.news-title{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666666;
    display:block;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-left: 5px;
    padding: 0!important;
    width: calc(100% - 120px)!important;
    }
.avatar{
    width:28px;
    height: 28px;
    border-radius: 20px;
    }
.text-nowrap{
    margin-top: 20px;
    }
.tag{
    background-color: #DDE9FF;
    color: #5090FF;
    font-size: 12px;
    width: 40px;
    height: 20px;
    background: #DDE9FF;
    border-radius: 2px;
    margin-left:10px !important;
    }
.mb-4{
    text-align: left;
    padding-right: 0;
    word-break: break-all;
    }
.mb-2{
    margin-bottom: 0 !important;
    }
.form-group{
    padding-left: 0;
    }
#exampleFormControlTextarea1{
    font-size: 14px;
    margin-top: 10px;
    }
.users{
    display: flex;
    flex-direction: row;
    }
.text-left1{
    /* padding-top: 16px;
    width: calc(100% - 60px); */
    }
.avatar0{
    width: 120px;
    }
@media screen and (max-width: 1400px){
.user-name{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    overflow: hidden;
    color: #666666;
    line-height: 24px;
    }
    }
@media screen  and (min-width:960px) and (max-width: 1280px){
.mr-4{
    margin-left: 4px !important;
    }
    }
@media screen  and (max-width:960px){
.mr-4{
    margin-left: 4px !important;
    }
    }
@media screen and (max-width: 414px){
.border-bottom{
    margin: 0!important;
    padding: 0!important;
    }
.active{
    border-right:none!important;
    }
.avatar0{
    width: 80px;
    }
.text-left1{
    width: calc(100% - 32px);
    }
.mb-4{
    padding: 0!important;
    margin-left:2px
}
.form-group{
    padding: 0 8px!important
}
    }
</style>
<script lang="ts">
import { defineComponent, isRef, PropType, ref } from 'vue'
import ButtonItem from './ButtonItem.vue'
import DateTime from './DateTime.vue'
import store, { AuthorCommentDto } from '../store'
import createMessage from './createMessage'
export default defineComponent({
  name: 'CommentItem',
  components: {
    ButtonItem,
    DateTime
  },
  props: {
    // 是否点赞
    isFavour: {
      type: Boolean,
      required: false
    },
    // 是否是粉丝
    isFans: {
      type: Boolean,
      required: false
    },
    // 昵称
    nickname: {
      type: String,
      required: true
    },
    // 是否是详情（是否要展开回复框）
    isDetail: {
      type: Boolean,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    },
    sendData: {
      type: Object as PropType<AuthorCommentDto>,
      required: true
    },
    // 发布时间
    dateTime: {
      type: String,
      required: true
    },
    // 评论内容
    content: {
      type: String,
      required: true
    },
    // 用户头像
    avatar: {
      type: String,
      required: true
    },
    // 新闻标题
    newsTitle: {
      type: String,
      required: true
    }
  },
  emits: ['list-review', 'update-right-list'],
  setup (props, context) {
    // 对没有头像的给予默认头像
    const userAvatar = (props.avatar && props.avatar !== '../img/avatar.jpg') ? props.avatar : require('@/assets/avatar_user@2x.png')

    // 是否展示文本框
    const displayTextArea = ref(false)
    const toggleTextArea = () => {
      console.log('sssfff');
      
      if (props.isDetail) {
        displayTextArea.value = !displayTextArea.value
      } else {
        context.emit('list-review', props.sendData)
      }
    }

    const del = () => {
      let id: string
      if (isRef(props.sendData.id)) {
        id = props.sendData.id.value as string
      } else {
        id = props.sendData.id
      }

      store.dispatch('adminCommentDelNews', { id: id }).then((data) => {
        if (data.code === '200') {
          context.emit('update-right-list')
        }
      })
    }

    const favour = () => {
      console.log('favour')
      let id: string
      if (isRef(props.sendData.id)) {
        id = props.sendData.id.value as string
      } else {
        id = props.sendData.id
      }
      if (props.isFavour) {
        store.dispatch('newsCommentFavourCancel', { targetId: id }).then((data) => {
          if (data.code === '200') {
            context.emit('update-right-list')
          }
        })
      } else {
        store.dispatch('newsCommentFavourInsert', { targetId: id }).then((data) => {
          if (data.code === '200') {
            context.emit('update-right-list')
          }
        })
      }
    }

    const content2 = ref('')

    // 回复评论
    const sendComment = () => {
      console.log('点击了确定')
      if (content2.value.trim() === '') {
        createMessage('内容不能为空', 'error')
        return false
      }

      if (isRef(props.sendData.id)) {
        store.dispatch('commentSendNews', { content: content2.value, targetId: props.sendData.id.value })
          .then((data) => {
            if (data.code === '200') {
              context.emit('update-right-list')
              content2.value = ''
            }
          })
      }
    }
    return {
      userAvatar,
      content2,
      sendComment,
      del,
      favour,
      displayTextArea,
      toggleTextArea
    }
  }
})
</script>
