
import { defineComponent, ref, onMounted, computed, toRefs, reactive, watch } from 'vue'
import echarts from 'echarts'
import ChartHeaderItem from '../components/ChartHeaderItem.vue'
import ChartHeader from '../components/ChartHeader.vue'
import moment from 'moment'
import store from '@/store'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default defineComponent({
  name: 'DataFaceHome',
  components: {
    ChartHeader,
    ChartHeaderItem
  },
  setup () {
    const chartRef = ref<null | HTMLElement>(null)
    const ageRef = ref<null | HTMLElement>(null)
    const sexRef = ref<null | HTMLElement>(null)
    const areaRef = ref<null | HTMLElement>(null)

    // 地区数据
    const getFansAddressData = computed(() => store.state.getFansAddressData)
    const getFansSexData = computed(() => store.state.getFansSexData)
    const getFansData = computed(() => store.state.getFansData)
    const dateFormat = 'YYYY-MM-DD'
    const timeData: any = reactive({
      startTime: moment().subtract(30, 'days').format(dateFormat),
      endTime: moment().format(dateFormat),
      xAxisData: computed(() => {
        const xAxisData = []
        // 图标x轴的数据
        let tempTime: string = timeData.startTime
        while (tempTime !== timeData.endTime) {
          // 从弟凌天开始  一天一天的增加日期,从而动态控制时间轴, 一直到等于最后一天
          xAxisData.push(tempTime)
          tempTime = moment(tempTime).add(1, 'days').format(dateFormat)
        }

        return xAxisData
      })
    })
    const timeDataRef = toRefs(timeData)

    const modal = [timeDataRef.startTime.value, timeDataRef.endTime.value]
    onMounted(() => {
      // 地区分布
      const areaChart = echarts.init(areaRef.value as unknown as HTMLCanvasElement)

      store.dispatch('getFansAddressData').then(() => {
        areaChart.setOption(
          {
            dataset: {
              source: [
                ['score', 'amount', 'product'],
                ...getFansAddressData.value
              ]
            },
            grid: { containLabel: true },
            xAxis: {},
            yAxis: { type: 'category' },
            series: [
              {
                type: 'bar',
                encode: {
                // Map the "amount" column to X axis.
                  x: 'amount',
                  // Map the "product" column to Y axis
                  y: 'product'
                }
              }
            ]
          }
        )
      })
      // 完,地区分布

      // 性别分布
      const sexChart = echarts.init(sexRef.value as unknown as HTMLCanvasElement)

      store.dispatch('getFansSexData').then(() => {
        sexChart.setOption(
          {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
              orient: 'vertical',
              left: 10,
              data: getFansSexData.value.legendData
            },
            series: [
              {
                name: '访问来源',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: getFansSexData.value.data
              }
            ]
          }
        )
      })
      // 完, 性别分布
      const ageChart = echarts.init(ageRef.value as unknown as HTMLCanvasElement)

      ageChart.setOption({
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '直接访问',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220]
          }
        ]
      })

      const myChart = echarts.init(chartRef.value as unknown as HTMLCanvasElement)
      watch(getFansData, (data: any) => {
        // 绘制图表
        myChart.setOption({
          title: {
            show: false,
            text: '数据趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#000000',
            '#3D89FF',
            '#00C7C6',
            '#FF8E4F',
            '#8ECC29'
          ],
          legend: {
            show: true,
            data: ['粉丝变化量', '涨粉量', '总粉丝量', '掉粉量', '活跃粉丝量'],
            top: '95%'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeDataRef.xAxisData.value
          },
          yAxis: {
            type: 'value'
          },
          series: data.series
        })
      })
    })
    store.dispatch('getFansData', {
      authorId: JSON.parse(localStorage.getItem('userInfo') as string).id,
      startTime: timeData.startTime + ' 00:00:00',
      endTime: timeData.endTime + ' 00:00:00'
    })
    const changeData = (value: any, dateString: any) => {
      if (dateString[0] === '') {
        timeDataRef.startTime.value = ''
        return false
      }

      if (dateString[1] === '') {
        timeDataRef.endTime.value = ''
        return false
      }

      timeDataRef.startTime.value = dateString[0]

      timeDataRef.endTime.value = dateString[1]

      store.dispatch('getFansData', {
        authorId: JSON.parse(localStorage.getItem('userInfo') as string).id,
        startTime: dateString[0] + ' 00:00:00',
        endTime: dateString[1] + ' 00:00:00'
      })
    }
    return {
      chartRef,
      modal,
      changeData,
      getFansData,
      sexRef,
      areaRef,
      ageRef,
      locale,
      dateFormat
    }
  }
})
