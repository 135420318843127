<template>
  <div class="login-page mx-auto p-3">
    <h5 class="my-4 text-center">登录</h5>
    <form @submit.prevent="onFormSubmit">
      <div class="mb-3">
        <form-input
          label="手机号"
          id="mobile"
          placeholder="请输入手机号"
          inputType="tel"
          v-model="mobileVal"
        />
      </div>
      <div class="mb-3">
        <form-input
          label="密码"
          id="password"
          inputType="password"
          placeholder="请输入密码"
          v-model="passwordVal"
        />
      </div>
      <button type="submit" class="btn btn-primary btn-block btn-large">登录</button>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import FormInput from '../components/FormInput.vue'
import createMessage from '../components/createMessage'
import axios from 'axios'

export default defineComponent({
  name: 'Login',
  components: {
    FormInput
  },
  setup () {
    const mobileVal = ref('')
    const router = useRouter()
    const store = useStore()
    const passwordVal = ref('')
    // // 在路由中获取token, 网站跳转过来的逻辑
    // const token = useRoute().query.token
    // if (token) {
    //   store.dispatch('pcTokenLogin', token).then((data) => {
    //     if (data.code !== '200') {
    //       store.commit('setError', { status: true, message: data.message })
    //       return false
    //     } else {
    //       data.data.token = token
    //       store.commit('login', data)
    //     }
    //     createMessage('登录成功,跳转首页', 'success')
    //     router.push('/')
    //   })
    // }
    const onFormSubmit = (result: boolean) => {
      store.commit('setError', { status: false })

      if (result) {
        const payload = {
          mobile: mobileVal.value,
          password: passwordVal.value
        }
        store.dispatch('loginAccount', payload).then((data) => {
          console.log('data:', data)
          if (data.code !== '200') {
            store.commit('setError', { status: true, message: data.message })
            return false
          }
          createMessage('登录成功,跳转首页', 'success')
          router.push('/')
        }).catch(e => {
          console.log(e)
        })
      }
    }
    return {
      mobileVal,
      passwordVal,
      onFormSubmit
    }
  }
})
</script>
