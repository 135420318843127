<template>
<span class="date-time">{{dateTime}}</span>
</template>
<style scoped>
.date-time{
    font-size: 14px;
    color:#999999;
    padding: 0;
    margin-right: 20px !important;
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DateTime',
  props: {
    dateTime: {
      type: String,
      required: true
    }
  }
})
</script>
