<template>
<div>
  <!-- 粉丝列表 -->
</div>
<media-list>
  <fans-item v-for="(fans, key) in listVipFans" :key="key" :nickname="fans.nickname" :coverPath="fans.avatar+'?x-oss-process=image/quality,q_60'" :status="fans.status"></fans-item>
</media-list>
<a-pagination v-model:current="reqParam.page" :total="total" show-less-items />
</template>
<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import MediaList from '../components/MediaList.vue'
import FansItem from '../components/FansItem.vue'
import store from '../store'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  name: 'FansList',
  components: {
    MediaList,
    FansItem
  },
  setup () {
    const listVipFans = computed(() => store.state.listVipFans)
    const reqParam = reactive({
      limit: 10,
      page: 0
    })

    const route = useRoute()
    const router = useRouter()
    const total = ref(0)
    watch(reqParam, (data) => {
      store.dispatch('listVipFans', data).then((data) => {
        if (data.code === '200') {
          total.value = data.data.total
        }
      })
      router.push({
        path: route.path,
        query: { ...route.query, ...reqParam, currentTab: 'FansList' }
      })
    })

    reqParam.page = 1

    return {
      listVipFans,
      reqParam,
      total
    }
  }
})
</script>
