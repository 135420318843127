
import { defineComponent, PropType } from 'vue'
interface OptionValue {
  text: string;
  value: string;
}
export default defineComponent({
  name: 'SelectItem',
  props: {
    optionArr: {
      type: Array as PropType<OptionValue[]>,
      required: true
    }
  }
})
