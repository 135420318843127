<template>
<!-- 作品数据 -->
<tab-nav>
  <tab-nav-item
  v-for="(tab,key) in tabs"
  :key="key"
  :active="currentTab === tab"
  @click="currentTab = tab"
  >{{TabText[tab]}}</tab-nav-item>
</tab-nav>
<component :is="currentTabComponent" class="tab"></component>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import DataFaceAll from './DataFaceAll.vue'
import DataFaceOne from './DataFaceOne.vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
export default defineComponent({
  name: 'NewsData',
  components: {
    DataFaceAll,
    TabNav,
    TabNavItem,
    DataFaceOne
  },
  setup () {
   enum TabText {
      DataFaceAll = '整体',
      DataFaceOne = '单篇'
    }
   const currentTab = ref('DataFaceAll')
   const tabs = ['DataFaceAll', 'DataFaceOne']
   const currentTabComponent = computed<string>(() => {
     return currentTab.value
   })
   return {
     currentTabComponent,
     tabs,
     TabText,
     currentTab
   }
  }
})
</script>
