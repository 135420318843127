
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  name: 'MenuParent',
  props: {
    // 父菜单的标题
    title: {
      type: String,
      required: true
    },

    active: {
      type: Boolean,
      required: false
    },

    // 父菜单属于哪个菜单集
    dataParent: {
      type: String,
      required: true
    },

    // 菜单开关控制
    menuItemId: {
      type: String,
      required: true
    }
  },
  setup () {
    const ParentMenuRef = ref<null | HTMLElement>(null)
    onMounted(() => {
      console.log('abcdefg', ParentMenuRef)
    })
    return {
      ParentMenuRef
    }
  }
})
