
import { defineComponent } from 'vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
import MediaList from '../components/MediaList.vue'
import MediaItemSimple from '../components/MediaItemSimple.vue'
export default defineComponent({
  name: 'DataFaceOne',
  components: {
    TabNav,
    MediaList,
    MediaItemSimple,
    TabNavItem
  }
})
