
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    type: {
      type: String,
      required: false
    },
    checked: {
      type: Boolean,
      required: false
    },
    checkValue: {
      type: String,
      required: true
    },
    checkName: {
      type: String,
      required: true
    },
    checkLabel: {
      type: String,
      required: false
    },
    checkId: {
      type: String,
      required: true
    }
  }
})
