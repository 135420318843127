<!-- 全局表单组件 -->

<template>
  <div class="row" :class="isRow ? 'row1':''">
    <div class="d-flex align-items-center col-2 pl-5">
        <label :for="id" class="col-form-label " :class="isRow ? 'col-form-label1':''">{{label}}</label>
    </div>
    <div class="col pt-4 " :class="isRow ? 'input1':''">
      <slot></slot>
    </div>
    <div class="col-2 d-flex align-items-center">
      <!-- <label :for="id" class="tips-btn">{{tips}}</label> -->
    </div>
  </div>
</template>
<style scoped>
.tips-btn{
    color: #5090FF;
    font-size: 14px;
    }
.pl-5{
    padding: 0 !important;
    }
.row1{
    display:flex;
    flex-direction:column;
    }
.pt-4{
    padding-top: 1rem!important;
    }
.col-form-label{
    margin-top:0px;
    margin-left: 12px;
    }
.col-form-label1{
    margin-left: 12px;
    white-space:nowrap;
    }
.col,.pt-4{
    margin-top:-10px;
    }
.input1{
    margin: -38px 40px 0 0px;
    width:100%;
    }
@media screen and (max-width:414px){
.col-2:first-child{
    width: 72px;
    }
.col-2:last-child{
    width: 0;
    padding: 0;
    }
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormGlobal',
  props: {
    label: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    tips: {
      type: String,
      required: false
    },
    isRow: {
      type: Boolean,
      default: false
    }
  }
})
</script>
