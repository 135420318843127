
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormGlobal',
  props: {
    label: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    tips: {
      type: String,
      required: false
    },
    isRow: {
      type: Boolean,
      default: false
    }
  }
})
