
import { defineComponent, ref, onMounted, computed, reactive } from 'vue'
import { beforeUploadCheck } from '../helper'
import {
  GlobalDataProps,
  OssResponse,
  NewsOneDto,
  AtlasNewsDto,
  ResponseType
} from '../store'
import createMessage from '../components/createMessage'
import FormInput from '../components/FormInput.vue'
import FormSelect from '../components/FormSelect.vue'
import FormOption from '../components/FormOption.vue'
import FormCheck from '../components/FormCheck.vue'
import FormCheckItem from '../components/FormCheckItem.vue'
import Uploader from '../components/FormUploader.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    FormInput,
    FormCheck,
    FormCheckItem,
    FormSelect,
    FormOption,
    Uploader
  },
  name: 'PostImageArr',
  setup () {
    const store = useStore<GlobalDataProps>()
    // 文章标题
    const newsTitle = ref('')
    // 封面数量
    const imageNum = ref(3)
    const newsColumn = ref('1')
    const newsSource = ref('102')
    const visible = ref(false)
    const previewVisible = ref(false);
    // 文章封面
    const coverImageArr = ref(reactive(['']))
    const imageArrRef: AtlasNewsDto[] = reactive([
      { describe: '', imageUrl: '' }
    ])
    //预览内容
    const previewData : NewsOneDto = reactive({
      // 资讯栏目id
        columnId: 0,
        // 资讯内容
        content: '',
        //图片内容
        atlasNewsDtos:[],
        // 资讯标题
        title: '',
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 1,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: '',
        // 资讯来源id
        sourceId: 1,
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: 0
    })
    // visible.value = false;

    // 添加图片
    const addImage = () => {
      console.log(imageArrRef);
      imageArrRef.push({ describe: '', imageUrl: '' })
    }

    // 删除图片
    const delImage = (key: number) => {
      console.log(imageArrRef,key);
      console.log('sd d ');
      imageArrRef.splice(key, 1)
      console.log(imageArrRef);
    }

    onMounted(() => {
      store.dispatch('fetchColumns')
      store.dispatch('listNewsSource')
    })

    const columns = computed(() => store.state.columns)
    const listNewsSource = computed(() => store.state.listNewsSource)

    const uploadedData = ref()

    const uploadCheck = (file: File) => {
      const result = beforeUploadCheck(file, {
        format: ['image/jpeg', 'image/png'],
        size: 10
      })
      const { passed, error } = result
      if (error === 'format') {
        createMessage('上传图片只能是 JPG/PNG 格式!', 'error')
      }
      if (error === 'size') {
        createMessage('上传图片大小不能超过 10Mb', 'error')
      }
      return passed
    }

    const handleFileUploadedContent = (rawData: OssResponse) => {
      imageArrRef[rawData.imageKey as number].imageUrl = rawData.url
    }

    const handleFileUploaded = (rawData: OssResponse) => {
      coverImageArr.value[rawData.imageKey as number] = rawData.url
    }

    const router = useRouter()
    const route = useRoute()

    const isEditMode = !!route.query.id
    // 是修改还是添加
    const actionName = isEditMode ? 'updateAtlasNewsContent' : 'createPostNews'

    const sendData = (isPublish: number) => {
      if (newsTitle.value.trim() === '') {
        message.error('标题不能为空')
        return false
      }
      // if (coverImageArr.value.length !== imageNum.value) {
      //   message.error('封面数量不对')
      //   return false
      // }
      let covers = coverImageArr.value;
      if (covers.length != 3) {
        message.error('封面数量不对')
        return false;
      }
      if (covers[0].length == 0 || covers[1].length == 0 || covers[2].length == 0) {
        message.error('封面数量不对')
        return false;
      }
      
      const data: NewsOneDto = {
        // 资讯栏目id
        columnId: parseInt(newsColumn.value),
        // 资讯内容
        content: '',
        atlasNewsDtos: imageArrRef,
        // 资讯标题
        title: newsTitle.value,
        // 资讯类型（1图文，2图集，3视频，4直播）
        type: 2,
        // 显示列表样式（1视频，2三个小图，3一张小图，4无图）
        listType: 1,
        // todo 资讯发布者id
        publisherId: '',
        imageUrl: coverImageArr.value.join(','),
        // 资讯来源id
        sourceId: parseInt(newsSource.value),
        // 传2代表是大v发布的
        isAuthorNews: 2,
        // 1:发布, 2:草稿
        isPublish: isPublish
      }
      if (isPublish == 3) {
        previewVisible.value = true;
        console.log(data);
        previewData.title = data.title
        previewData.content = data.content
        previewData.type = data.type
        previewData.listType = data.listType
        previewData.atlasNewsDtos = data.atlasNewsDtos
        return;
      }
      let infos = imageArrRef;
      console.log(infos.length,infos[0].imageUrl);
      
      if (infos.length == 0) {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      
      if (infos.length == 1 && infos[0].imageUrl == '') {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      if (infos.length == 2 && (infos[0].imageUrl == '' || infos[1].imageUrl == '')) {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      if (infos.length == 3 && ((infos[0] && infos[0].imageUrl == '') || (infos[1] && infos[1].imageUrl == '') || (infos[2] && infos[2].imageUrl == ''))) {
        createMessage('请上传图片和文字', 'error', 2000)
        return;
      }
      if (coverImageArr.value.length < 1) {
        createMessage('请上传封面', 'error', 2000)
        return false
      } else {
        const sendData = isEditMode
          ? {
            id: route.query.id,
            ...data
          }
          : data
        console.log(sendData);
        store.dispatch(actionName, sendData).then((data: ResponseType) => {
          if (data.code === '200') {
            createMessage('添加成功，2秒后跳转到作品管理', 'success', 2000)
            setTimeout(() => {
              router.push({ path: '/DataList', query: { type: '2' } })
            }, 2000)
          } else {
            message.error(data.message)
          }
        })
      }
    }

    // 如果是编辑,就设置初始值
    if (route.query.id) {
      store.dispatch('getNewsContentById', { newsId: route.query.id }).then((rawData: ResponseType<NewsOneDto>) => {
        console.log(rawData, 'rawData getNewsContentById PostImageText')
        if (rawData.code !== '200') {
          createMessage('获取文章详情失败', 'error')
          return false
        }

        const currentEditNews = rawData.data
        console.log('currentEditNews', currentEditNews)
        newsTitle.value = currentEditNews.title
        newsColumn.value = currentEditNews.columnId + ''
        newsSource.value = currentEditNews.sourceId + ''
        if (currentEditNews.atlasNewsDtos !== undefined) {
          // 去掉默认的空值
          imageArrRef.pop()
          Array.prototype.push.apply(imageArrRef, currentEditNews.atlasNewsDtos)
        }
        console.log('newsTitle.value-------', currentEditNews, 'newsTitle.value')

        if (currentEditNews.imageUrl && currentEditNews.imageUrl?.length < 1) {
          imageNum.value = 0
        } else if (currentEditNews.imageUrl) {
          imageNum.value = currentEditNews.imageUrl.length
          coverImageArr.value = currentEditNews.imageUrl as string[]
        }
      })
    }
    //预览
    const preview = () => {
      console.log('预览')
      sendData(3)
      console.log(uploadedData);
    }
    // 保存草稿
    const submitDraft = () => {
      sendData(2)
    }

    // 发布
    const submitRelease = () => {
      sendData(1)
    }

    return {
      imageArrRef,
      coverImageArr,
      uploadCheck,
      preview,
      visible,
      previewVisible,
      previewData,
      newsTitle,
      newsColumn,
      newsSource,
      imageNum,
      // 栏目信息
      columns,
      submitDraft,
      submitRelease,
      // 来源
      listNewsSource,
      uploadedData,
      handleFileUploaded,
      addImage,
      delImage,
      handleFileUploadedContent
    }
  }
})
