<template>
  <div class="card">
    <div class="card-header bg-transparent"  :ref="ParentMenuRef">
      <h2 class="mb-0" :class="{'mt-20':title=='主页'}">
        <div
          class="btn btn-block  d-flex justify-content-between"
          :class="{'text-primary':active}"
          data-toggle="collapse"
          :data-target="'#' + menuItemId"
          aria-expanded="true"
          :aria-controls="menuItemId"
        >
        <img v-if="title == '主页'" class="icon" src="../assets/首页背景.png" alt="">
        <img v-if="title == '创作'" class="icon" src="../assets/创作背景.png" alt="">
        <img v-if="title == '管理'" class="icon" src="../assets/管理背景.png" alt="">
        <img v-if="title == '数据'" class="icon" src="../assets/数据背景.png" alt="">
        <img v-if="title == '设置'" class="icon" src="../assets/设置背景.png" alt="">
         <div  class="title1"> {{ title }}</div>
          <span className="arrow-down"></span>
        </div>
      </h2>
    </div>

    <div
      :id="menuItemId"
      class="collapse"
      :class="{'show':active}"
      aria-labelledby="headingOne"
      data-parent="#accordionExample"
    >
      <div class="card-body p-0">
        <ul class="nav flex-column">
          <slot></slot>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title1{
  font-size: 16px;
}
.mt-20{
  margin-top: 20px;
}
.card {
  /* margin-top: 20px; */
  border-width: 0px !important;
}
.btn{
    padding-left: 0rem;
}
.icon {
  width: 20px;
  height: 20px;
  position: relative;
  left: 40px;
}
.text-primary{
  color: #212529 !important;
}
</style>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  name: 'MenuParent',
  props: {
    // 父菜单的标题
    title: {
      type: String,
      required: true
    },

    active: {
      type: Boolean,
      required: false
    },

    // 父菜单属于哪个菜单集
    dataParent: {
      type: String,
      required: true
    },

    // 菜单开关控制
    menuItemId: {
      type: String,
      required: true
    }
  },
  setup () {
    const ParentMenuRef = ref<null | HTMLElement>(null)
    onMounted(() => {
      console.log('abcdefg', ParentMenuRef)
    })
    return {
      ParentMenuRef
    }
  }
})
</script>
<style scoped>
  span.arrow-down {
    border-left: 2px solid #D8D8D8;;
    border-bottom: 2px solid #D8D8D8;
    height: 13px;
    width: 13px;
    transform: translate(2px, -2px) rotate(-45deg);
    -webkit-transform: translate(2px, -2px) rotate(-45deg);
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    display: inline-block;
    -moz-transform: translate(2px, -2px) rotate(-45deg);
    -ms-transform: translate(2px, -2px) rotate(-45deg);
    -o-transform: translate(2px, -2px) rotate(-45deg);
  }
.card .card-header,.bg-transparent{border: 0 red solid;}
</style>
