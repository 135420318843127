
import { defineComponent, ref } from 'vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default defineComponent({
  name: 'SearchBar',
  props: {
    hasDateBar: {
      type: Boolean,
      required: false
    }
  },
  emits: ['go-search'],
  setup (props, context) {
    const time2 = ref()
    let time: Array<string>
    const txt = ref('')
    const onChange = (date: Array<object> | string, dateString: Array<string>) => {
      console.log('onChange date', date)
      console.log('onChange dateString', dateString)
      time = dateString
    }

    const onSearch = () => {
      console.log('time', time)
      console.log('txt', txt.value)
      context.emit('go-search', time, txt.value)
    }

    return { time2, onChange, onSearch, locale, txt }
  }
})
