
import { defineComponent, ref, onMounted } from 'vue'
export default defineComponent({
  name: 'ModalWindow',
  emits: ['get-news-interaction'],
  setup (props, context) {
    const modalRef = ref<HTMLLIElement|null>(null)
    onMounted(() => {
      console.log(modalRef)
      console.log('get-news-interaction..............')

      if (modalRef.value !== null && modalRef.value !== undefined) {
        modalRef.value.addEventListener('show.bs.modal', function (event: any) {
          const button = event.relatedTarget
          const recipient = button.getAttribute('newsData')
          console.log('recipient', recipient)
          const newsData = JSON.parse(recipient)
          context.emit('get-news-interaction', newsData)
        })
      }
    })

    return {
      modalRef
    }
  }
})
