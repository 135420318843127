<template>
<!-- 模态框 -->
<div class="modal fade" id="exampleModal" ref="modalRef" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog mt-0 d-flex flex-nowrap bd-highlight rounded-0">
    <div class="modal-content order-1 p-2 bd-highlight rounded-0">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close border-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
    </div>
  </div>
</div>
</template>
<style scoped>
.modal-content{
    height: 1080px;
    }
.modal-dialog{
    max-width : 50%;
    margin-left : 50%;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
    }
@media screen and (max-width: 414px){
.modal-dialog{
      max-width : 100%;
      margin-left : 0;
      margin-top: 0px;
      margin-bottom: 0px;
      height: 100%;
    }
.modal-content{
    border-top-left-radius: 8px!important;
    border-top-right-radius: 8px!important;
    width: calc(100% + 0px)!important;
    height: 450px;
    position: fixed;
    top: 222px;
    }
.modal-body{
    height: 200px;
    overflow: scroll;
    }
    }
.fade{
    padding-right: 0px!important;
    }
</style>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
export default defineComponent({
  name: 'ModalWindow',
  emits: ['get-news-interaction'],
  setup (props, context) {
    const modalRef = ref<HTMLLIElement|null>(null)
    onMounted(() => {
      console.log(modalRef)
      console.log('get-news-interaction..............')

      if (modalRef.value !== null && modalRef.value !== undefined) {
        modalRef.value.addEventListener('show.bs.modal', function (event: any) {
          const button = event.relatedTarget
          const recipient = button.getAttribute('newsData')
          console.log('recipient', recipient)
          const newsData = JSON.parse(recipient)
          context.emit('get-news-interaction', newsData)
        })
      }
    })

    return {
      modalRef
    }
  }
})
</script>
