
import { defineComponent, reactive } from 'vue'
import FormGlobal from './FormGlobal.vue'
export default defineComponent({
  name: 'FormSelect',
  components: {
    FormGlobal
  },
  props: {
    modelValue: String,
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const inputRef = reactive({
      val: props.modelValue || 1,
      error: false,
      message: ''
    })
    const updateValue = (e: { target: HTMLSelectElement }) => {
      const targetValue = (e.target as HTMLSelectElement).value
      inputRef.val = targetValue
      context.emit('update:modelValue', targetValue)
    }
    return {
      inputRef,
      updateValue
    }
  }
})
