
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ButtonItem',
  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  }
})
