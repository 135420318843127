<template>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" :id="id" />
      <label class="custom-control-label small align-text-bottom " :for="id">
        <slot></slot>
      </label>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PCheckBox',
  props: {
    id: {
      type: String,
      required: true
    }
  }
})
</script>
