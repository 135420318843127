
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import MediaList from '../components/MediaList.vue'
import MediaItem from '../components/MediaItem.vue'
import CommentItem from '../components/CommentItem.vue'
import CommentContent from '../components/CommentContent.vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
import store, { AuthorCommentDto } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import SearchBar from '../components/SearchBar.vue'
import CountItem from '../components/CountItem.vue'

export default defineComponent({
  name: 'CommentListAll',
  components: {
    CommentItem,
    MediaItem,
    SearchBar,
    CommentContent,
    TabNav,
    CountItem,
    TabNavItem,
    MediaList
  },
  setup () {
    const authorListAuthorComment = computed(() => store.state.authorListAuthorComment)
    const authorNewsList = computed(() => store.state.authorNewsList)

    // 二级评论
    const commentListTwoNews = computed(() => store.state.commentListTwoNews)
    const route = useRoute()

    const activeComment = toRefs(reactive({
      avatarUrl: 'string',
      content: 'string',
      gmtCreate: 'string',
      id: 'string',
      isFans: 1,
      isFavour: 1,
      newsId: 'string',
      nickname: 'string',
      title: 'string',
      userId: 'string'
    }))

    const reqParam2 = reactive({
      // 二级评论页数
      page: Number(route.query.page2) || 1,
      // 二级评论的id
      id: route.query.id || '',
      isLevel: route.query.isLevel || '1',
      // 二级评论数量
      length: Number(route.query.length) || 5,
      // 当前新闻id
      newsId: route.query.newsId || '1'
    })

    // 点击回复
    const clickReview = (data: AuthorCommentDto) => {
      console.log('sss');
      
      activeComment.avatarUrl.value = data.avatarUrl
      activeComment.content.value = data.content
      activeComment.gmtCreate.value = data.gmtCreate
      activeComment.id.value = data.id
      activeComment.newsId.value = data.newsId
      activeComment.nickname.value = data.nickname
      activeComment.title.value = data.title
      activeComment.userId.value = data.userId
      activeComment.isFavour.value = data.isFavour
      activeComment.isFans.value = data.isFans
      reqParam2.id = data.id
    }

    const reqParam = reactive(
      {
        // 每一页显示的数量
        limit: Number(route.query.limit) || 5,
        // 第几页
        page: 0,
        type: 0,
        // 开始时间
        startTime: route.query.startTime || '2000-11-16 00:00:00',
        // 结束时间
        endTime: route.query.endTime || '3020-11-16 00:00:00',
        // 搜索关键字
        content: route.query.content || ''
      }
    )

    // 设置当前文章
    const setCurrent = (news: any) => {
      reqParam2.newsId = news.id
      reqParam2.id = '0'
    }

    const total = ref(0)
    const total2 = ref(0)

    const router = useRouter()

    // 重新获取二级评论
    const updateRighList = () => {
      store.dispatch('authorListAuthorComment', reqParam).then((data) => {
        if (data.code === '200') {
          // 默认第一个激活
          total.value = data.data.total
          activeComment.avatarUrl.value = data.data.list[0].avatarUrl
          activeComment.content.value = data.data.list[0].content
          activeComment.gmtCreate.value = data.data.list[0].gmtCreate
          activeComment.id.value = data.data.list[0].id
          activeComment.newsId.value = data.data.list[0].newsId
          activeComment.nickname.value = data.data.list[0].nickname
          activeComment.title.value = data.data.list[0].title
          activeComment.userId.value = data.data.list[0].userId
          activeComment.isFavour.value = data.data.list[0].isFavour
          activeComment.isFans.value = data.data.list[0].isFans
          reqParam2.id = data.data.list[0].id
        }
      })
      store.dispatch('commentListTwoNews', reqParam2)
    }

    watch(reqParam2, (data) => {
      if (data.id === '0') {
        store.dispatch('authorListAuthorComment', { ...data, limit: data.length }).then((data) => {
          total2.value = data.data.total
        })
      } else {
        store.dispatch('commentListTwoNews', data).then((data) => {
          total2.value = data.data.total
        })
      }

      router.push({
        path: route.path,
        query: { ...route.query, page2: data.page, id: data.id, isLevel: data.isLevel, length: data.length }
      })
    })

    watch(reqParam, (data) => {
      // 全部
      if (data.type === 0) {
        store.dispatch('authorListAuthorComment', data).then((data) => {
          if (data.code === '200') {
            // 默认第一个激活
            total.value = data.data.total
            activeComment.avatarUrl.value = data.data.list[0].avatarUrl
            activeComment.content.value = data.data.list[0].content
            activeComment.gmtCreate.value = data.data.list[0].gmtCreate
            activeComment.id.value = data.data.list[0].id
            activeComment.newsId.value = data.data.list[0].newsId
            activeComment.nickname.value = data.data.list[0].nickname
            activeComment.title.value = data.data.list[0].title
            activeComment.userId.value = data.data.list[0].userId
            activeComment.isFavour.value = data.data.list[0].isFavour
            activeComment.isFans.value = data.data.list[0].isFans
            reqParam2.id = data.data.list[0].id
          }
        })
      } else {
        // 根据文章
        store.dispatch('authorNewsList', { ...reqParam, examineStatusOne: 12 }).then((data) => {
          if (data.data.total === 0) {
            total.value = data.data.total
            reqParam2.newsId = '0'
            reqParam2.id = '0'
          } else {
            total.value = data.data.total
            reqParam2.newsId = data.data.list[0].id
            reqParam2.id = '0'
          }
        })
      }

      router.push({
        path: route.path,
        query: { ...route.query, ...data }
      })
    })

    reqParam.page = 1

    // 搜索功能
    const goSearch = (timeArr: Array<string>, content: string) => {
      if (timeArr === undefined || timeArr[0] === '') {
        reqParam.startTime = '2000-11-16 00:00:00'
        reqParam.endTime = '3020-11-16 00:00:00'
      } else {
        reqParam.startTime = timeArr[0] + ' 00:00:00'
        reqParam.endTime = timeArr[1] + ' 00:00:00'
      }
      reqParam.content = content
    }
    return {
      authorListAuthorComment,
      commentListTwoNews,
      clickReview,
      ...activeComment,
      activeComment,
      goSearch,
      total,
      setCurrent,
      total2,
      reqParam2,
      reqParam,
      authorNewsList,
      updateRighList
    }
  }
})
