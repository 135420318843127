<template>
<li class="nav-item mr-3 cursor" :class="{'border-bottom border-primary':active}">
  <slot></slot>
</li>
</template>
<style scoped>
.border-primary{
  padding-bottom: 10px;
  border-color: #FF5E5E !important;
}
.cursor{
    cursor:pointer;
    margin-right: 30px!important;
    }
.cursor:last-child{
    margin-right: 10px!important;
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TabNavItem',
  props: {
    active: {
      type: Boolean,
      require: false
    }
  }
})
</script>
