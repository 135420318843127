<template>
  <span class="mr-2 count-font border-right pr-2">
    <span>{{name}}:</span>
    <span>{{count}}</span>
  </span>
</template>
<style  scoped>
.count-font{
    font-size: 13px;
    color: #999;
    padding: 0;
    margin: 0;
    }
span:last-child{
    border: none !important;
    }
@media screen and (max-width: 350px){
.count-font{
    font-size: 12px;
    padding-right: 2px!important;
    margin-right: 2px!important;
    }
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CountItem',
  props: {
    name: {
      type: String,
      require: true
    },
    count: {
      type: Number,
      require: true
    }
  }
})
</script>
