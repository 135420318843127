<template>
<!-- 概览 -->
  <div>
    <div class="row">
      <!-- 标题 -->
      <slot name="title"></slot>
    </div>
    <div class="row">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ChartHeader'
})
</script>
