
import { defineComponent } from 'vue'
import MediaList from '../components/MediaList.vue'
import CommentItem from '../components/CommentItem.vue'
import CommentContent from '../components/CommentContent.vue'
import CommentFilterBar from '../components/CommentFilterBar.vue'
import MediaItem from '../components/MediaItem.vue'
import CountItem from '../components/CountItem.vue'
import ButtonItem from '../components/ButtonItem.vue'
import TabNav from '../components/TabNav.vue'
import TabNavItem from '../components/TabNavItem.vue'
export default defineComponent({
  name: 'CommentListNews',
  components: {
    CommentItem,
    MediaList,
    CommentFilterBar,
    MediaItem,
    CommentContent,
    CountItem,
    TabNav,
    TabNavItem,
    ButtonItem
  }
})
