<template>
  <li class="fans-item text-center col-2 border m-4 p-4">
    <img
      class="rounded-circle fans-cover"
      :src="coverPath?coverPath:require('@/assets/avatar_user@2x.png')"
      alt="logo"
    />
    <div class="m-3">{{nickname}}</div>
    <div class="container">
      <button class="focus btn ">{{ status == 1?'已关注':'关注'}}</button>
      <button @click="alert('敬请期待')" class="btn btn-primary">私信</button>
    </div>
  </li>
</template>
<style scoped>
.focus {
  color: #333;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
}
.container {
  display: flex;
}
.btn-primary{
    color: #FF0000;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #FF0000;
}
.fans-item{
    height: 230px;
    }
.fans-cover{
    height: 80px;
    width: 80px;
    }
.m-3{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    }
.m-4{
    width: calc(25% - 20px);
    margin: 10px !important;
    border-radius: 4px;
    }
.btn{
    width: 60%;
    margin: 0px 5px;
    }
@media screen and (max-width:1400px) {
.m-4{
    width: calc(100% / 4 - 20px);
    height: auto;
    }
    }
.m-4:hover{
    box-shadow: 0px 1px 30px 0px rgba(173, 194, 230, 0.2);
    }
@media screen and (max-width: 414px){
.m-4{
    width: calc(100% / 2 - 12px);
    height: 220px;
    padding-top: 20px!important;
    margin: 10px 6px !important;
    }
    }
</style>
<script lang="ts">
import { defineComponent } from 'vue'
import createMessage from '../components/createMessage'
export default defineComponent({
  name: 'FansItem',
  props: {
    nickname: { type: String, require: true },
    coverPath: { type: String, require: false },
    status: {type: Boolean, require: true}
  },
  setup () {
    const alert = (msg: string) => {
      createMessage(msg, 'success')
    }
    return {
      alert
    }
  }
})
</script>
