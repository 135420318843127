<!-- 评论的内容 -->
<template>
<li class="row mb-2 pb-2 border-bottom pl-3" :class="{'active':active}">
  <div class="row text-nowrap text-left">
    <img v-if="avatar" class="col-1 p-0 m-0 avatar" :src="avatar" alt="头像" >
    <span class="col-1 user-name">{{nickname}}</span>
    <span class="col-1 tag m-0 p-0 text-center text-justify" v-if="isFans">粉丝</span>
  </div>
  <div class="row mb-4 mt-3">
    {{content}}
  </div>
  <div class="d-flex bd-highlight pl-0">
    <date-time :dateTime="dateTime" class="flex-fill bd-highlight text-left ml-0 col"></date-time>
    <span class="flex-fill bd-highlight text-right col">
      <button-item @click.prevent="toggleTextArea" style="cursor: pointer;">{{isDetail && displayTextArea?'取消回复':'回复'}}</button-item>
      <button-item @click.prevent="favour" style="cursor: pointer;">{{isFavour?'取消点赞':'点赞'}}</button-item>
      <button-item @click.prevent="del" style="cursor: pointer;">删除</button-item>
    </span>
  </div>
   <div class="form-group" v-if="displayTextArea">
    <textarea  v-model="content2"  class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="请输入您的评论"></textarea>
    <a-button @click="sendComment" class="sure mt-3" style="cursor: pointer;">发布</a-button>

  </div>
</li>
</template>
<style scoped>
.sure{
    position: relative;
    right: -160px;
    bottom: 60px;
}
.form-control {
  height: 150px !important;
  resize: none;
}
.active{
    background-color: #FAFAFA;
    border-right: 1px solid #5090FF!important;
    }
.user-name{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666666;
    padding-right: 30px !important;
    }
.description{
    color: #999999;
    font-size: 14px;
    }
.news-title{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666666;
    }
.avatar{
    width:28px;
    height: 28px;
    border-radius: 30px;
    }
.pl-3{
    margin-top: 20px !important;
    }
.text-right{
    cursor: pointer;
    }
.tag{
    background-color: #DDE9FF;
    color: #5090FF;
    font-size: 12px;
    width: 40px;
    height: 20px;
    background: #DDE9FF;
    border-radius: 2px;
    }
.form-group{
    padding-left: 0;
    }
.mb-2{
    margin-bottom: 0;
    }
#exampleFormControlTextarea1{
    font-size: 14px;
    margin-top: 10px;
    }
.mb-4{
    text-align: left;
    padding-right: 0;
    }
@media screen  and (max-width: 414px){
.mb-4{
    margin-left: 0!important;
    padding-left: 0!important;
    }
.form-group{
    padding: 0 8px!important
}
}
</style>
<script lang="ts">
import store, { AuthorCommentDto } from '@/store'
import { defineComponent, isRef, PropType, ref } from 'vue'
import ButtonItem from './ButtonItem.vue'
import DateTime from './DateTime.vue'
export default defineComponent({
  name: 'CommentContent',
  components: {
    ButtonItem,
    DateTime
  },
  props: {
    // 是否点赞
    isFavour: {
      type: Boolean,
      required: false
    },
    // 是否是粉丝
    isFans: {
      type: Boolean,
      required: false
    },
    // 是否有底边
    hasBottomBorder: {
      type: Boolean,
      required: false
    },

    // 是否是详情
    isDetail: {
      type: Boolean,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    },

    // 发布时间
    dateTime: {
      type: String,
      required: true
    },
    // 评论内容
    content: {
      type: String,
      required: true
    },
    // 用户头像
    avatar: {
      type: String,
      required: true
    },
    // 昵称
    nickname: {
      type: String,
      required: true
    },
    // 昵称
    id: {
      type: String,
      required: true
    },
    sendData: {
      type: Object,
      required: true
    },
    // 新闻标题
    newsTitle: {
      type: String,
      required: false
    }
  },
  emits: ['send-after'],
  setup (props, context) {
    // 是否展示文本框
    const displayTextArea = ref(false)
    const toggleTextArea = () => {
      if (props.isDetail) {
        displayTextArea.value = !displayTextArea.value
      }
    }

    const content2 = ref('')

    // 回复评论
    const sendComment = () => {
      store.dispatch('commentSendNews', { content: content2.value, targetId: props.id })
        .then((data) => {
          if (data.code === '200') {
            context.emit('send-after')
            content2.value = ''
          }
        })
    }

    const del = () => {
      console.log('delete')
      store.dispatch('adminCommentDelNews', { id: props.id }).then((data) => {
        if (data.code === '200') {
          context.emit('send-after')
        }
      })
    }

    const favour = () => {
      // newsCommentFavourInsert
      console.log('favour')
      if (props.isFavour) {
        store.dispatch('newsCommentFavourCancel', { targetId: props.id }).then((data) => {
          if (data.code === '200') {
            context.emit('send-after')
          }
        })
      } else {
        store.dispatch('newsCommentFavourInsert', { targetId: props.id }).then((data) => {
          if (data.code === '200') {
            context.emit('send-after')
          }
        })
      }
    }
    return {
      displayTextArea,
      del,
      favour,
      content2,
      sendComment,
      toggleTextArea
    }
  }
})
</script>
