<template>
  <form-global :label="label" :id="id" :tips="tips" :isRow="isRow">
    <div class="form-group mb-4">
      <input @input="updateValue" :type="inputType" :disabled="disabled" :value="inputRef.val" class="form-control" :id="id">
    </div>
  </form-global>
</template>
<style scoped>
.form-control{
    margin-top: 20px;
    }
</style>
<script lang="ts">
import { defineComponent, reactive, computed } from 'vue'
import FormGlobal from './FormGlobal.vue'
export default defineComponent({
  name: 'FormInput',
  components: {
    FormGlobal
  },
  props: {
    label: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      required: false
    },
    disabled: Boolean,
    modelValue: String,
    tips: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    // 控制创建-项目的上下排列
    isRow: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const inputRef = reactive({
      val: computed({
        get: () => props.modelValue || '',
        set: val => {
          context.emit('update:modelValue', val)
        }
      }),
      error: false,
      message: ''
    })
    const updateValue = (e: KeyboardEvent) => {
      const targetValue = (e.target as HTMLInputElement).value
      inputRef.val = targetValue
      context.emit('update:modelValue', targetValue)
    }
    return {
      updateValue,
      inputRef
    }
  }
})
</script>
